import ApiService from "@/common/api.service";

export default {
  fetchCourses(data) {
    return ApiService.query("courses", data);
  },
  fetchCourse(id) {
    return ApiService.query("courses/" + id);
  },
  fetchCourseByPageUrl(pageUrl) {
    return ApiService.query(`courses/intro/${pageUrl}`);
  },
  fetchCoursesByTeacher(teacherUserId) {
    return ApiService.query(`/session-classes/teacher/${teacherUserId}`);
  },
  createCourse(data) {
    return ApiService.post("courses", data);
  },
  postCourse(id, data) {
    return ApiService.post("courses/" + id, data);
  },
  updateCoursesDisplayOrders(data) {
    return ApiService.patch("courses/batch-display-orders", data);
  },
  deleteCourse(id) {
    return ApiService.delete("courses/" + id);
  },
  fetchSessions(data) {
    return ApiService.query("course-sessions", data);
  },
  fetchSession(id) {
    return ApiService.query("course-sessions/" + id);
  },
  postSession(id, data) {
    return ApiService.post("course-sessions/" + id, data);
  },
  createSession(data) {
    return ApiService.post("course-sessions", data);
  },
  deleteSession(id) {
    return ApiService.delete("course-sessions/" + id);
  },
  fetchClassTickets(data) {
    return ApiService.query("class-tickets", data);
  },
  fetchSessionClassesForList(data) {
    return ApiService.query("session-classes-for-list", data);
  },
  createClassTicket(data) {
    return ApiService.post("class-tickets", data);
  },
  fetchSessionClasses(data) {
    return ApiService.query("session-classes", data);
  },
  fetchMyClasses(data) {
    return ApiService.query("session-classes/my-classes", data);
  },
  fetchAllLessonRequests(data) {
    return ApiService.query("session-classes/all-lesson-requests", data);
  },
  fetchSessionClassesForSelector() {
    return ApiService.get("session-classes-for-selector");
  },
  fetchSessionClass(id, data) {
    return ApiService.query(
      "session-classes/" + id,
      data
        ? data
        : {
          test_type: "weekly"
        }
    );
  },
  fetchSessionClassAndPrivate() {
    return ApiService.query("session-classes/default-and-custom");
  },
  postSessionClass(id, data) {
    return ApiService.post("session-classes/" + id, data);
  },
  updateSessionClass(id, data) {
    return ApiService.put("session-classes/" + id + "/look-for-teacher", data);
  },
  createSessionClass(data) {
    return ApiService.post("session-classes", data);
  },
  deleteSessionClass(id) {
    return ApiService.delete("session-classes/" + id);
  },
  restartSessionClass(id) {
    return ApiService.post("session-classes/restart/" + id);
  },
  endSessionClass(id, end_reason) {
    return ApiService.post(`session-classes/end/${id}`, { end_reason });
  },
  fetchEnrollmentSessionTags() {
    return ApiService.query("enrollment-session-tags");
  },
  deleteClassTicket(id) {
    return ApiService.delete("class-tickets/" + id);
  },
  studentsOfTeacher(teacher_user_id) {
    return ApiService.query("students-of-teacher", { teacher_user_id });
  },
  getExams() {
    return ApiService.query("assign-tests-for-courses");
  },
  getTemplates() {
    return ApiService.query("assign-test-templates");
  },
  getClasses(data) {
    return ApiService.query("course-session-detail/", data);
  },
  getStudentExams(id) {
    return ApiService.query("student/" + id + "/assign-test");
  },
  setStudentExams(id, data) {
    return ApiService.patch("student-ticket/" + id + "/student-test", data);
  },
  getClassExams(id) {
    return ApiService.query("session-class/" + id + "/assign-test");
  },
  getClassAnalysis(id, data) {
    return ApiService.query("session-class/" + id + "/test-analysis", data);
  },
  getAllExams(data) {
    return ApiService.query("exams", data);
  },
  getTestAdmit(data) {
    return ApiService.query("/test-admit/exams", data);
  },
  getTestAdmitToken(data) {
    return ApiService.query("/test-admit/me", data);
  },
  pathHide(id, data) {
    return ApiService.patch(
      `session-class-assign-tests/${id}/hide-solution`,
      data
    );
  },
  getOnlineSATExams(data) {
    return ApiService.query("sat-mocks/exams", data);
  },
  saveStudentExams(studentClassTicketId, data) {
    return ApiService.post(
      `student-ticket/${studentClassTicketId}/assign-tests`,
      data
    );
  },
  resetEnrolledCourse(enrolledId, target_id = null) {
    return ApiService.patch("reset-enrolled-course-session", {
      id: enrolledId,
      course_session_id: target_id
    });
  },
  getCourseStudentsInfo(id) {
    return ApiService.query(`session-classes/${id}/students-info`);
  }
};
