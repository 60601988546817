import ApiService from "@/common/api.service";

export default {
  getInstructors() {
    return ApiService.get("teachers");
  },
  getInstructorsForBio() {
    return ApiService.get("teacher/show-bio");
  },
  getInstructorsFaq() {
    return ApiService.get("instructor-faq");
  },
  postInstructorsFaq(payload) {
    return ApiService.post("instructor-faq", payload);
  }
};
