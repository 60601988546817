import ApiService from "@/common/api.service";

export default {
  // Faq
  getFaqList(params) {
    return ApiService.get("faq", "", params);
  },
  createFaq(payload) {
    return ApiService.post("faq", payload);
  },
  getFaq(faqId) {
    return ApiService.get("faq", faqId);
  },
  updateFaq(faqId, payload) {
    return ApiService.patch("faq", payload, faqId);
  },
  removeFaq(faqId) {
    return ApiService.delete("faq", "", faqId);
  },

  // Label
  getLabelList() {
    return ApiService.get("faq-label");
  },
  createLabel(payload) {
    return ApiService.post("faq-label", payload);
  },
  getLabel(labelId) {
    return ApiService.get("faq-label", labelId);
  },
  updateLabel(labelId, payload) {
    return ApiService.patch("faq-label", payload, labelId);
  },
  removeLabel(labelId) {
    return ApiService.delete("faq-label", "", labelId);
  },

  // Topic
  getTopicList(params) {
    return ApiService.get("faq-topic", "", params);
  },
  createTopic(payload) {
    return ApiService.post("faq-topic", payload);
  },
  getTopic(topicId) {
    return ApiService.get("faq-topic", topicId);
  },
  updateTopic(topicId, payload) {
    return ApiService.patch("faq-topic", payload, topicId);
  },
  removetopic(topicId) {
    return ApiService.delete("faq-topic", "", topicId);
  },
  // for FAQ About page
  getFaqs() {
    return ApiService.get("faqs");
  }
};
