<template>
  <div>
    <div
      :class="{
        'todo-item': true,
        'todo-item-active': todoActiveIndex === index,
        'is-late': isLate(item.from_date, is_all_day, item.from_time),
        'is-finished': item.status == 1
      }"
      @click="$emit('setActiveIndex')"
    >
      <div
        class="d-flex"
        style=" align-items:center;
                justify-content:center;"
      >
        <div class="todo-check">
          <!-- <el-checkbox
            v-model="item.status"
            @change="setTodo(item.id, item.status)"
            :true-label="1"
            :false-label="0"
          ></el-checkbox> -->
          <span v-show="item.status == 0" @click="setTodo(item.id, 1)">
            <i class="far fa-circle"></i>
          </span>
          <span v-show="item.status == 1" @click="setTodo(item.id, 0)">
            <i class="far fa-check-circle"></i>
          </span>
        </div>
        <div style="flex: 1">
          <div class="todo-item-col">
            <div class="todo-date">
              <span class="mr-2">
                <i class="fa fa-clock"></i>
              </span>
              <span style="line-height: 1.1;">
                {{ item.from_date }}
                <span v-show="!item.is_all_day">
                  {{ item.from_time }}
                </span>
              </span>
            </div>
            <div class="todo-title">
              <h6 class="m-0">
                <span :class="item.status ? 'completed' : ''">
                  {{ item.title }}
                </span>
              </h6>
            </div>
            <!-- <div class="todo-users">
              <el-avatar>
                {{
                  userMethods.displayName(item.user.first_name, item.user.last_name)
                }}
              </el-avatar>
            </div> -->
            <div class="todo-actions">
              <el-button
                type="primary"
                size="mini"
                @click.stop="editTodo(item)"
              >
                <i class="fa fa-edit" />
              </el-button>
              <el-button
                type="danger"
                size="mini"
                @click.stop="removeTodo(item.id)"
              >
                <i class="fa fa-trash-alt" />
              </el-button>
            </div>
          </div>
          <div
            class="mt-2"
            v-if="
              item.responsible_members && item.responsible_members.length > 0
            "
          >
            <el-tag
              v-for="user in item.responsible_members"
              :key="user.id"
              type="success"
              effect="light"
              class="mb-1 mr-2 mt-1"
            >
              <span v-if="user.user_id">
                <!-- {{
                  userMethods.displayName(user.user.first_name, user.user.last_name)
                }} -->
                <span v-if="user.user.first_name">{{
                  user.user.first_name
                }}</span>
                <span v-else>{{
                  userMethods.displayName(
                    user.user.first_name,
                    user.user.last_name
                  )
                }}</span>
              </span>
              <span v-else>
                {{ user.full_name }}
              </span>
            </el-tag>
          </div>
          <div v-show="todoActiveIndex === index">
            <hr />
            <el-form label-width="40px" :label-position="`right`">
              <el-form-item prop="title">
                <h6 class="mb-3">{{ item.title }}</h6>
              </el-form-item>
              <!-- <el-form-item :label="`Staff`">
                <template slot="label">
                  <el-tooltip effect="dark" placement="top">
                    <div div slot="content">
                      可以把這件事安排給相關行政人員，<br />讓這件事同步出現在他們的待辦事項
                    </div>
                    <span>
                      <i class="fas fa-user-tie"></i>
                    </span>
                  </el-tooltip>
                </template>
                <div>
                  <el-tag
                    v-for="user in item.responsible_members"
                    :key="user.id"
                    type="success"
                    effect="light"
                    class="mb-1 mr-2 mt-1"
                  >
                    <span v-if="user.user_id">
                      {{
                        userMethods.displayName(
                          user.user.first_name,
                          user.user.last_name
                        )
                      }}
                    </span>
                    <span v-else>
                      {{ user.full_name }}
                    </span>
                  </el-tag>
                </div>
              </el-form-item> -->
              <el-form-item prop="from_date">
                <template slot="label">
                  <i class="fas fa-calendar-week"></i>
                </template>
                <div class="d-flex">
                  <span>{{ item.from_date }}</span>
                  <span class="ml-2">
                    <span v-show="item.is_all_day">
                      All Day
                    </span>
                    <span v-show="!item.is_all_day">
                      {{ item.from_time }}
                    </span>
                  </span>
                </div>
              </el-form-item>
              <el-form-item>
                <template slot="label">
                  <el-tooltip effect="dark" content="提醒通知" placement="top">
                    <span>
                      <i class="fas fa-bell"></i>
                    </span>
                  </el-tooltip>
                </template>
                <div class="d-flex" v-if="item.need_reminder">
                  <span>{{ item.reminder_number }}</span>
                  <span class="ml-2">{{ item.reminder_unit }}</span>
                </div>
                <div v-else>
                  不需要提醒
                </div>
              </el-form-item>
              <el-form-item>
                <template slot="label">
                  <el-tooltip effect="dark" placement="top">
                    <div slot="content">
                      點進通知時會連結到這個頁面，<br />方便後續作業
                    </div>
                    <span>
                      <i class="fas fa-window-maximize"></i>
                    </span>
                  </el-tooltip>
                </template>
                <a :href="item.url" target="_blank">
                  {{ item.url }}
                </a>
              </el-form-item>
              <el-form-item :label="`Location`">
                <template slot="label">
                  <el-tooltip effect="dark" placement="top">
                    <div div slot="content">
                      選填地址/地點，<br />也可以輸入線上會議連結
                    </div>
                    <span>
                      <i class="fas fa-map-marker-alt"></i>
                    </span>
                  </el-tooltip>
                </template>
                {{ item.location }}
              </el-form-item>
              <el-form-item :label="`Text`">
                <template slot="label">
                  <el-tooltip effect="dark" content="說明" placement="top">
                    <span>
                      <i class="fas fa-align-left"></i>
                    </span>
                  </el-tooltip>
                </template>
                <div v-html="item.notes"></div>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
import { user } from "@ivy-way/material";
/*eslint-enable */
import moment from "moment";

export default {
  components: {},

  mixins: [],

  props: ["item", "todoActiveIndex", "index"],
  data() {
    return {
      moment() {
        return moment;
      }
    };
  },
  computed: {
    userMethods() {
      return user;
    }
  },
  watch: {},

  mounted() {},

  methods: {
    removeTodo(id) {
      this.$emit("removeTodo", id);
    },
    editTodo(item) {
      this.$emit("editTodo", item);
    },
    setTodo(id, value) {
      this.$emit("setTodo", id, value);
    },
    isLate(date, isAll, time) {
      let now = moment(new Date()).format("yyyy-MM-DD");
      if (moment(date).isBefore(now, "day")) {
        return true;
      } else {
        return false;
      }
    }
  }
};
</script>

<style scoped>
.todo-item {
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  border: 2px solid #ebeef5;

  /* justify-content:center; */
  cursor: pointer;
  background-color: white;
}
.todo-item:hover,
.todo-item-active {
  border: 2px solid var(--themeColor);
}
.todo-item-col {
  display: flex;
  align-items: center;
}
::v-deep .todo-item .el-form-item {
  margin: 0;
}
.todo-date {
  width: 120px;
  color: #989898;
  display: flex;
  align-items: center;
}
.todo-title {
  flex: 1;
  overflow: hidden;
  margin-right: 1rem;
  /* white-space: nowrap;
  text-overflow: ellipsis; */
}
.todo-title .m-0 {
  overflow: hidden; /* 确保超出容器的内容被裁剪 */
  white-space: nowrap; /* 确保文本在一行内显示 */
  text-overflow: ellipsis;
}
.completed {
  text-decoration: line-through;
  opacity: 0.5;
}
.todo-users {
  width: 50px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.todo-actions {
  width: 110px;
}
.todo-check {
  width: 40px;
  font-size: 1.5rem;
}
.todo-check span:hover {
  color: var(--themeColor);
}
.is-late {
  background-color: #fef0f0;
}
.is-late .todo-date {
  color: #f56c6c;
}
.is-finished {
  background-color: white;
  opacity: 0.5;
  text-decoration: line-through;
}
.is-finished .todo-date {
  color: #989898;
  
}
</style>
