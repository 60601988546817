/* eslint-disable no-empty-pattern */
import GreetMeeting from "@/class/GreetMeeting";
import privateApi from "@/apis/private";
import arrangeApi from "@/apis/arrange";
import { Message } from "element-ui";
import i18n from "@/i18n.js";

const initState = {
  privateLesson: {
    id: null,
    title: "",
    subject_type: "",
    subject: "",
    lesson_requests: [],
    teachers: [],
    class_teacher_invitations: [],
    is_online: 0,
    request_time_info: "",
    request_content: "",
    contact_tool_preference: 1,
    started_date: null,
    started_time: null,
    timezone: "Asia/Taipei",
    duration_hours: 2,
    duration_minutes: 0,
    address: "",
    look_for_teacher: 0,
    admin_note: "",
    teacher_note: "",
    has_meeting: true,
    they_will_be_meetings: [new GreetMeeting()],
    mail_content: "",
    session_class_timeslots: [
      {
        from: null,
        to: null,
        times: 2,
        per: "M"
      }
    ]
  },
  mail_to_teachers: 0,
  mail_to_students: 0,
  mail_to_parents: 0,
  intro_new_teacher_mail_content: ""
};

const state = { ...initState };

const getters = {
  privateLesson(state) {
    return state.privateLesson;
  },
  validStudents(state) {
    return state.privateLesson.lesson_requests.filter(
      student => student.action_mode !== "D"
    );
  },
  validTeachers(state) {
    return state.privateLesson.teachers.filter(
      teacher => teacher.action_mode !== "D"
    );
  },
  needToIntroTeachers(state) {
    return state.privateLesson.teachers.filter(
      teacher => teacher.need_to_intro && !teacher.isEditing
    );
  },
  haveNoTeacher(state) {
    return state.privateLesson.teachers
      .filter(teacher => teacher.action_mode !== "D").length === 0;
  },
  notYetJoinedClassTeachers(state) {
    const joinedTeachersId = state.privateLesson.teachers
      .filter(({ action_mode }) => action_mode !== "D")
      .map(({ new_teacher_user_id }) => new_teacher_user_id);

    return state.privateLesson.class_teacher_invitations
      .filter(teacherInvitation => !joinedTeachersId.includes(teacherInvitation.teacher_user_id));
  },
  hasEditingTeachers(state) {
    return state.privateLesson.teachers
      .filter(teacher => teacher.isEditing).length > 0;
  },
  hasEditingStudents(state) {
    return state.privateLesson.lesson_requests
      .filter(student => student.isEditing).length > 0;
  },
  hasEditingTeacherInvitations(state) {
    return state.privateLesson.class_teacher_invitations.filter(
      teacherInvitation => teacherInvitation.isEditing
    ).length > 0;
  },
  introNewTeacherMailContent(state) {
    return state.intro_new_teacher_mail_content;
  },
  mailToTeachers(state) {
    return state.mail_to_teachers;
  },
  mailToStudents(state) {
    return state.mail_to_students;
  },
  mailToParents(state) {
    return state.mail_to_parents;
  }
};

const mutations = {
  initState(state) {
    state.privateLesson = { ...initState.privateLesson };
    state.intro_new_teacher_mail_content =
      initState.intro_new_teacher_mail_content;
  },
  setPrivateLesson(state, privateLesson) {
    state.privateLesson = {
      ...state.privateLesson,
      ...privateLesson
    };
  },
  setIntroNewTeacherMailContent(state, mailContent) {
    console.log(mailContent);
    state.intro_new_teacher_mail_content = mailContent;
  },
  setMailToTeachers(state, mailToTeacher) {
    state.mail_to_teachers = mailToTeacher;
  },
  setMailToStudents(state, mailToStudent) {
    state.mail_to_students = mailToStudent;
  },
  setMailToParents(state, mailToParents) {
    state.mail_to_parents = mailToParents;
  }
};

const actions = {
  setIsUpdateTeacherBio({ state, commit }, payload) {
    const { teacherId, is_update_teacher_bio } = payload;
    const teachers = state.privateLesson.teachers.map(teacher => {
      if (teacher.new_teacher.id === teacherId) {
        return {
          ...teacher,
          is_update_teacher_bio
        };
      }
      return teacher;
    });
    commit("setPrivateLesson", { teachers });
  },
  setTeacherBio({ state, commit }, payload) {
    const { teacherId, bioType, bio } = payload;
    const teachers = state.privateLesson.teachers.map(teacher => {
      if (teacher.new_teacher.id === teacherId) {
        let originBio = {};
        if (typeof teacher.new_teacher[bioType] === "string") {
          originBio = { ...JSON.parse(teacher.new_teacher[bioType]) };
        } else {
          originBio = { ...teacher.new_teacher[bioType] };
        }
        const newBio = { ...originBio, en: bio };

        return {
          ...teacher,
          new_teacher: {
            ...teacher.new_teacher,
            [bioType]: JSON.stringify(newBio)
          }
        };
      }
      return teacher;
    });
    commit("setPrivateLesson", { teachers });
  },
  setStudents({ state, commit }, students) {
    const markAddLessonRequest = students =>
      students.map(student => {
        if (student.student_class_ticket_id) return student;
        return { ...student, action_mode: "A" };
      });

    const markDeleteLessonRequest = students => {
      const markedStudents = [...students];
      state.privateLesson.lesson_requests.forEach(originalStudent => {
        const isStudentExist = () => {
          const targetStudent = students.find(
            student =>
              student.student_class_ticket_id ===
              originalStudent.student_class_ticket_id
          );
          return targetStudent;
        };

        if (!isStudentExist()) {
          markedStudents.push({
            action_mode: "D",
            student_user_id: originalStudent.student_user_id,
            student_class_ticket_id: originalStudent.student_class_ticket_id
          });
        }
      });
      return markedStudents;
    };

    commit("setPrivateLesson", {
      lesson_requests: markDeleteLessonRequest(markAddLessonRequest(students))
    });
  },
  setTeachers({ state, commit }, teachers) {
    const markAddTeachers = teachers =>
      teachers.map(teacher => {
        const isExistTeacher = state.privateLesson.teachers.some(
          existTeacher =>
            existTeacher.teacher_user_id === teacher.teacher_user_id
        );
        if (isExistTeacher) return teacher;
        return {
          ...teacher,
          need_to_intro: true,
          is_update_teacher_bio: true,
          action_mode: "A",
          mail_content: "",
          mail_to_teacher: 0,
          mail_to_students: 0,
          mail_to_parents: 0
        };
      });

    const markDeleteTeachers = teachers => {
      const markedTeachers = teachers;
      state.privateLesson.teachers.forEach(existTeacher => {
        const isNewTeachersExist = () =>
          teachers.find(
            teacher => teacher.teacher_user_id === existTeacher.teacher_user_id
          );
        if (!isNewTeachersExist() && existTeacher.teacher_user_id) {
          markedTeachers.push({
            ...existTeacher,
            action_mode: "D",
            need_to_intro: false
          });
        }
      });
      return markedTeachers;
    };

    const newTeachers = markDeleteTeachers(markAddTeachers(teachers));
    const effectiveTeachers = newTeachers.filter(
      ({ action_mode }) => action_mode !== "D"
    );

    commit("setPrivateLesson", {
      teachers: newTeachers,
      teacher_user_id: effectiveTeachers[0]?.teacher_user_id || null,
      salary: effectiveTeachers[0]?.salary || 0,
      salary_currency: effectiveTeachers[0]?.salary_currency || "TWD"
    });
  },
  markIsNewTeacher({ state, dispatch }, payload) {
    const { isNewTeacher, teacherId } = payload;
    const workedTeachers = state.privateLesson.teachers.map(teacher => {
      if (teacher.new_teacher_user_id === teacherId) {
        return { ...teacher, need_to_intro: isNewTeacher };
      }
      return { ...teacher };
    });
    dispatch("setTeachers", workedTeachers);
  },
  async changeToCounseling({}, classId) {
    await privateApi.changeToCounseling(classId);
    Message.success(i18n.t("message.update_success"));
  },
  async sendInvitationToTeacher({}, invitationId) {
    try {
      await arrangeApi.sendInvitationToTeacher(invitationId);
      Message.success("信件寄送成功！");
    } catch (e) {
      Message.error(i18n.t("message.something_went_wrong"));
    }
  },
  switchInvitationEdit({ commit, state }, payload) {
    const { invitationId, status } = payload;

    const updatedInvitationTeachers = state.privateLesson.class_teacher_invitations
      .map(teacherInvitation => {
        if (teacherInvitation.id === invitationId) {
          return {
            ...teacherInvitation,
            isEditing: status,
            editNotes: teacherInvitation.notes,
            editInviationStatus: teacherInvitation.replied_status,
            editSalary: state.privateLesson.is_online ? teacherInvitation.online_salary : teacherInvitation.salary,
            editCurrency: state.privateLesson.is_online ? teacherInvitation.online_salary_currency : teacherInvitation.currency
          };
        }
        return { ...teacherInvitation };
      });

    commit("setPrivateLesson", {
      class_teacher_invitations: updatedInvitationTeachers
    });
  },
  setInvitationTeacher({ commit }, payload) {
    const { invitationId, data } = payload;

    const updatedInvitationTeachers = state.privateLesson.class_teacher_invitations
      .map(teacherInvitation => {
        if (teacherInvitation.id === invitationId) {
          return { ...teacherInvitation, ...data };
        }
        return { ...teacherInvitation };
      });

    commit("setPrivateLesson", {
      class_teacher_invitations: updatedInvitationTeachers
    });
  },
  async saveInvitationTeacher({ commit, state, dispatch }, invitationId) {
    let updateSalaryKey = "salary";
    let updateCurrencyKey = "currency";
    if (state.privateLesson.is_online) {
      updateSalaryKey = "online_salary";
      updateCurrencyKey = "online_salary_currency";
    }
    const updatedInvitationTeachers = state.privateLesson.class_teacher_invitations
      .map(teacherInvitation => {
        if (teacherInvitation.id === invitationId) {
          return {
            ...teacherInvitation,
            replied_status: teacherInvitation.editInviationStatus,
            [updateSalaryKey]: teacherInvitation.editSalary,
            [updateCurrencyKey]: teacherInvitation.editCurrency,
            notes: teacherInvitation.editNotes
          };
        }
        return { ...teacherInvitation };
      });
    try {
      const updatedInvitationTeacher = updatedInvitationTeachers
        .find(({ id }) => id === invitationId);
      await arrangeApi.updateInvitation({
        ...updatedInvitationTeacher,
        id: updatedInvitationTeacher.id,
        notes: updatedInvitationTeacher.notes
      });
      await arrangeApi.replyTeacherInvitation({
        class_teacher_invitation_id: updatedInvitationTeacher.encrypted_id,
        replied_status: updatedInvitationTeacher.replied_status
      });
      commit("setPrivateLesson", {
        class_teacher_invitations: updatedInvitationTeachers
      });
      dispatch("switchInvitationEdit", { status: false, invitationId });
      Message.success(i18n.t("message.update_success"));
    } catch (e) {
      Message.error(i18n.t("message.something_went_wrong"));
    }
  },
  async deleteTeacherInvitation({ state, dispatch }, invitationId) {
    try {
      await arrangeApi.deleteTeacherInvitation(invitationId);
      const class_id = state.privateLesson.id;
      dispatch("getPrivateLesson", class_id);
      Message.success(i18n.t("message.delete_success"));
    } catch (e) {
      Message.error(i18n.t("message.something_went_wrong"));
    }
  },
  addTeacherToClass({ state, commit, dispatch }, data) {
  },
  addInvitationTeacherToClass({ state, commit, dispatch }, data) {
    const invitationId = data;
    const updatedInvitationTeachers = state.privateLesson.class_teacher_invitations
      .map(teacherInvitation => {
        if (teacherInvitation.id === invitationId) {
          return { ...teacherInvitation };
        }
        return { ...teacherInvitation };
      });
    const targetTeacher = state.privateLesson.class_teacher_invitations
      .find(({ id }) => id === invitationId);
    const defaultBio = { cn: "", en: "", tw: "" };
    const addedTeachers = [
      ...state.privateLesson.teachers,
      {
        need_to_intro: true,
        action_mode: "A",
        can_see_others: true,
        isEditing: false,
        is_update_teacher_bio: true,
        mail_content: "",
        mail_to_parents: 0,
        mail_to_students: 0,
        mail_to_teacher: 0,
        new_teacher: {
          ...targetTeacher.teacher_user,
          ...targetTeacher.teacher_user.teacher_profile,
          tutoring_bio: targetTeacher.teacher_user.teacher_profile.tutoring_bio || defaultBio,
          counseling_bio: targetTeacher.teacher_user.teacher_profile.counseling_bio || defaultBio
        },
        new_teacher_user_id: targetTeacher.teacher_user_id,
        salary: targetTeacher.salary,
        salary_currency: targetTeacher.currency,
        online_salary: targetTeacher.online_salary,
        online_salary_currency: targetTeacher.online_salary_currency,
        salary_unit: 3,
        online_salary_unit: 3,
        teacher: {
          ...targetTeacher.teacher_user,
          ...targetTeacher.teacher_user.teacher_profile,
          tutoring_bio: targetTeacher.teacher_user.teacher_profile.tutoring_bio || defaultBio,
          counseling_bio: targetTeacher.teacher_user.teacher_profile.counseling_bio || defaultBio
        },
        teacher_user_id: targetTeacher.teacher_user_id
      }
    ];
    commit("setPrivateLesson", {
      class_teacher_invitations: updatedInvitationTeachers
    });
    dispatch("setTeachers", addedTeachers);
  },
  addTheyWillBeMeeting({ state, commit }) {
    commit("setPrivateLesson", {
      they_will_be_meetings: [
        ...state.privateLesson.they_will_be_meetings,
        new GreetMeeting()
      ]
    });
  },
  removeTheyWillBeMeetings({ state, commit }, removeIndex) {
    commit("setPrivateLesson", {
      they_will_be_meetings: state.privateLesson.they_will_be_meetings.filter(
        (_meeting, index) => index !== removeIndex
      )
    });
  },
  setTheyWillBeMeeting({ state, commit }, payload) {
    const { theyWillBeMeetingIndex, data } = payload;
    commit("setPrivateLesson", {
      they_will_be_meetings: state.privateLesson.they_will_be_meetings
        .map((they_will_be_meeting, index) => {
          if (index === theyWillBeMeetingIndex) {
            return { ...they_will_be_meeting, ...data };
          }
          return { ...they_will_be_meeting };
        })
    });
  },
  async createPrivateLesson({ state, getters }, callback) {
    let started_at =
      state.privateLesson.started_date === null || state.privateLesson.started_time === null ?
        null : `${state.privateLesson.started_date} ${state.privateLesson.started_time}:00`;

    const {
      class_id,
      student_class_tickets
    } = await privateApi.createCustomPrivateLesson({
      ...state.privateLesson,
      started_at,
      teachers: state.privateLesson.teachers.filter(
        teacher => teacher.teacher_user_id !== null
      ),
      lesson_requests: state.privateLesson.lesson_requests.filter(
        student => student.student_user_id !== null
      ),
      look_for_teacher: state.privateLesson.look_for_teacher,
      selected_mailing_teachers: getters.needToIntroTeachers.map(
        ({ new_teacher_user_id }) => new_teacher_user_id
      ),
      mail_content: state.intro_new_teacher_mail_content,
      mail_to_teachers: state.mail_to_teachers,
      mail_to_students: state.mail_to_students,
      mail_to_parents: state.mail_to_parents
    });

    callback(class_id, student_class_tickets);
    Message.success(i18n.t("message.private_class_created_successfully"));
  },
  async updatePrivateLesson({ state, getters }, callback) {
    try {
      let started_at =
        state.privateLesson.started_date === null || state.privateLesson.started_time === null ?
          null : `${state.privateLesson.started_date} ${state.privateLesson.started_time}:00`;

      await privateApi.updateCustomPrivateLesson({
        ...state.privateLesson,
        class_id: state.privateLesson.id,
        started_at,
        teachers: state.privateLesson.teachers.filter(
          teacher => teacher.teacher_user_id !== null
        ),
        lesson_requests: state.privateLesson.lesson_requests.filter(
          student => student.student_user_id !== null
        ),
        look_for_teacher: state.privateLesson.look_for_teacher,
        selected_mailing_teachers: getters.needToIntroTeachers.map(
          ({ new_teacher_user_id }) => new_teacher_user_id
        ),
        mail_content: state.intro_new_teacher_mail_content,
        mail_to_teachers: state.mail_to_teachers,
        mail_to_students: state.mail_to_students,
        mail_to_parents: state.mail_to_parents
      });
      callback(state.privateLesson.id);
      Message.success(i18n.t("message.update_success"));
    } catch (e) {
      Message.error(i18n.t("message.something_went_wrong"));
    }
  },
  async createGreetMeetings({}, { classId, classAddress, theyWillBeMeetings }) {
    const existTeacherMeetings = theyWillBeMeetings.filter(
      theyWillBeMeeting => theyWillBeMeeting.teachersId.length > 0
    );
    existTeacherMeetings.forEach(theyWillBeMeeting => {
      const startedAt = theyWillBeMeeting.isDecidedMeetingTime
        ? `${theyWillBeMeeting.date} ${theyWillBeMeeting.time}:00`
        : null;
      const address = theyWillBeMeeting.isSameOfClassAddress
        ? classAddress
        : theyWillBeMeeting.address;
      privateApi.createGreetMeeting({
        session_class_id: classId,
        teacher_user_ids: theyWillBeMeeting.teachersId,
        timezone: theyWillBeMeeting.timezone,
        is_same_address: Number(theyWillBeMeeting.isSameOfClassAddress),
        address,
        started_at: startedAt
      });
    });
  },
  async updateGreetMeeting(
    {},
    { greetMeetingId, classAddress, theyWillBeMeeting }
  ) {
    const startedAt = theyWillBeMeeting.isDecidedMeetingTime
      ? `${theyWillBeMeeting.date} ${theyWillBeMeeting.time}:00`
      : null;
    const address = theyWillBeMeeting.isSameOfClassAddress
      ? classAddress
      : theyWillBeMeeting.address;

    privateApi.updateGreetMeeting(greetMeetingId, {
      teacher_user_ids: theyWillBeMeeting.teachersId,
      timezone: theyWillBeMeeting.timezone,
      is_same_address: Number(theyWillBeMeeting.isSameOfClassAddress),
      address,
      started_at: startedAt
    });
  },
  async deleteGreetMeeting({}, { greetMeetingId }) {
    await privateApi.deleteGreetMeeting(greetMeetingId);
  },
  async sendGreetMeetingMail({}, { classId, mailToPeople, sendTeachersId, mailContent }) {
    await privateApi.sendGreetMeetingMail(
      classId,
      sendTeachersId,
      mailToPeople,
      mailContent
    );
  },
  async getPrivateLesson({ state, commit }, class_id) {
    const {
      lesson_logs,
      session_class,
      student_class_tickets
    } = await privateApi.getCustomLesson({ class_id });

    commit("setPrivateLesson", {
      id: session_class.id,
      title: session_class.title,
      subject_type: session_class.subject_type,
      subject: session_class.subject,
      lesson_requests: student_class_tickets.map(student_class_ticket => ({
        action_mode: "U",
        isEditing: false,
        default_parents_receivers: student_class_ticket.default_parents_receivers || [],
        fee: student_class_ticket.fee || 0,
        fee_currency: student_class_ticket.fee_currency || "TWD",
        online_fee: student_class_ticket.online_fee || 0,
        online_fee_currency: student_class_ticket.online_fee_currency || "TWD",
        need_pay_first: student_class_ticket.enrolled_session.need_pay_first,
        new_fee: student_class_ticket.fee,
        new_student: student_class_ticket.student,
        new_student_user_id: student_class_ticket.student_user_id,
        student: student_class_ticket.student,
        student_user_id: student_class_ticket.student_user_id,
        student_class_ticket_id: student_class_ticket.id
      })),
      teachers: session_class.session_class_teachers.map(teacher => ({
        ...teacher,
        need_to_intro: false,
        action_mode: "U",
        can_see_others: teacher.can_see_others,
        isEditing: false,
        is_update_teacher_bio: true,
        mail_content: "",
        mail_to_parents: 0,
        mail_to_students: 0,
        mail_to_teacher: 0,
        new_salary: teacher.salary,
        new_teacher: {
          ...teacher.teacher_user,
          ...teacher.teacher_user.teacher_profile
        },
        new_teacher_user_id: teacher.teacher_user_id,
        salary: teacher.salary || 0,
        salary_currency: teacher.salary_currency|| "TWD",
        salary_unit: 3,
        online_salary: teacher.online_salary || 0,
        online_salary_currency: teacher.online_salary_currency|| "TWD",
        online_salary_unit: 3,
        teacher: {
          ...teacher.teacher_user,
          ...teacher.teacher_user.teacher_profile
        },
        teacher_user_id: teacher.teacher_user_id
      })),
      class_teacher_invitations: session_class.class_teacher_invitations.map(class_teacher_invitation => ({
        ...class_teacher_invitation,
        isEditing: false
      })),
      is_online: session_class.is_online,
      request_time_info: session_class.request_time_info,
      request_content: session_class.request_content,
      started_date: session_class.started_on === "0000-00-00" ? null : session_class.started_on,
      started_time: session_class.time_from === "00:00:00" ? null : session_class.time_from,
      timezone: session_class.timezone,
      address: session_class.address,
      admin_note: session_class.admin_note,
      teacher_note: session_class.teacher_note,
      look_for_teacher: session_class.look_for_teacher,
      mail_to_teachers: 0,
      mail_to_students: 0,
      mail_to_parents: 0,
      they_will_be_meetings: [{
        ...state.privateLesson.they_will_be_meetings[0],
        address: session_class.address
      }],
      session_class_timeslots: session_class.session_class_timeslots,
      lesson_logs: lesson_logs,
      session_class: session_class,
      student_class_tickets: student_class_tickets
    });
    commit("setMailToStudents", 0);
    commit("setMailToParents", 0);
    commit("setMailToTeachers", 0);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
