export default class ThirdApp {
  constructor(props) {
    const {
      third_service_type = null,
      account = "",
      password = "",
      counseling_schools = []
    } = props || {};
    this.thirdServiceType = third_service_type;
    this.account = account;
    this.password = password;
    this.schools = counseling_schools;
  }
}

export const thirdAppTypeDict = {
  common_app: "Common App",
  coalition_app: "Coalition App",
  california_app: "UC App"
};
