import schoolApi from "@/apis/school.js";
import CollegeAlias from "@/class/collegeAlias";

const state = {
  collegeAlias: new CollegeAlias(),
  collegeAliases: [],
  college: {}
};

const getters = {
  college() {
    return state.college;
  },
  collegeAlias(state) {
    return state.collegeAlias;
  },
  collegeAliases(state) {
    return state.collegeAliases;
  }
};

const mutations = {
  setCollege(state, college) {
    state.college = college;
  },
  setCollegeAlias(state, collegeAlias) {
    state.collegeAlias = new CollegeAlias({
      ...state.collegeAlias,
      ...collegeAlias
    });
  },
  setCollegeAliases(state, collegeAliases) {
    state.collegeAliases = collegeAliases.map(
      collegeAlias =>
        new CollegeAlias({
          ...collegeAlias,
          schoolAliasId: collegeAlias.id,
          schoolId: collegeAlias.school_id
        })
    );
  }
};

const actions = {
  async getCollegeAliases({ commit }, payload) {
    const { schoolId } = payload;
    const res = await schoolApi.getSchoolAliases(schoolId);
    const { name } = await schoolApi.getSchoolListName(schoolId);
    const defaultNames = Object.keys(name).map(langKey => ({
      lang: langKey,
      name: name[langKey],
      originalName: true,
      school_id: schoolId,
      isDefault: 1
    }));

    const schoolNames = [
      ...defaultNames,
      ...(res?.school_aliases || []).filter(({ name, lang }) => 
        !defaultNames.some(
          defaultName => defaultName.name === name && defaultName.lang === lang
        )
      )
    ];
    commit("setCollegeAliases", schoolNames);
  },
  async checkAliasIsExist({ _ }, payload) {
    const { name } = payload;
    return await schoolApi.checkAliasIsExist(name);
  },
  async createCollegeAlias({ state }) {
    await schoolApi.createCollegeAlias(state.collegeAlias);
  },
  async updateCollegeAlias({ state, commit }) {
    await schoolApi.updateCollegeAlias(state.collegeAlias);
    const collegeName = state.college.name;
    commit("setCollege", {
      ...state.college,
      name: {
        ...collegeName,
        [state.collegeAlias.lang]: state.collegeAlias.name
      }
    });
  },
  async deleteCollegeAlias({ _ }, payload) {
    const { id } = payload;
    await schoolApi.deleteCollegeAlias(id);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
