import ApiService from "@/common/api.service";

export default {
  getUsersUnpaid(data) {
    return ApiService.query("order/users/unpaid", data);
  },
  getReceivables() {
    return ApiService.query("receivable-order");
  },
  getTutoringPayments() {
    return ApiService.query("tutoring-payments");
  },
  getUnpaidBills() {
    return ApiService.query("order/unpaid");
  },
  getUnpaidChildrens(parentId) {
    return ApiService.query(`order/${parentId}/children/unpaid`);
  },
  getParentUnpaidEnrollments(parentId) {
    return ApiService.query(`receivable-order/enrollments/${parentId}`);
  },
  getUnpaidListByStudentId(studentId) {
    return ApiService.query(`order/unpaid/${studentId}`);
  },
  getUnpaidUserWithoutTag() {
    return ApiService.query("order/users/unpaid-without-tag");
  },
  getPaidBills(query) {
    return ApiService.query("orders/paid", query);
  },
  getBill(billId) {
    return ApiService.query(`order/${billId}`);
  },
  getReceivable(receivableId) {
    return ApiService.query(`receivable-order/${receivableId}`);
  },
  createBill(data) {
    return ApiService.post("order", data);
  },
  updateBill(billId, bill) {
    return ApiService.patch(`order/${billId}`, bill);
  },
  deleteBill(billId) {
    return ApiService.delete(`order/${billId}`);
  },
  deletePaidBill(billId) {
    return ApiService.delete(`/order/${billId}/completed`);
  },
  createReceivable(data) {
    return ApiService.post("receivable-order", data);
  },
  updateReceivable(receivableId, receivable) {
    return ApiService.patch(`receivable-order/${receivableId}`, receivable);
  },
  markSessionToFree(enrolled_session_id){
    return ApiService.post(`enrolled-session/${enrolled_session_id}/mark-free`);
  },
  deleteReceivable(receivableId) {
    return ApiService.delete(`receivable-order/${receivableId}`);
  },
  createReceivableOrderItem(receivableId, receivableOrderItem) {
    return ApiService.post(
      `receivable-order-item/${receivableId}`,
      receivableOrderItem
    );
  },
  updateReceivableOrderItem(receivableOrderItemId, receivableOrderItem) {
    return ApiService.patch(
      `receivable-order-item/${receivableOrderItemId}`,
      receivableOrderItem
    );
  },
  deleteReceivableOrderItem(receivableOrderItemId) {
    return ApiService.delete(`receivable-order-item/${receivableOrderItemId}`);
  },
  updateOrderDifferencePrice(
    billId,
    difference_price,
    difference_price_currency
  ) {
    return ApiService.patch(`order/${billId}/difference-price`, {
      difference_price,
      difference_price_currency
    });
  },
  sendBill(billId, usersMail) {
    return ApiService.post(`order/${billId}/send`, usersMail);
  },
  createTransaction(billId, data) {
    return ApiService.post(`order/${billId}/transaction`, data);
  },
  verifyTransaction(transactionId) {
    return ApiService.patch(`order/transaction/${transactionId}`);
  },
  markPaid(billId, data) {
    return ApiService.patch(`order/${billId}/paid`, data);
  },
  submitProblem(billId, data) {
    return ApiService.post(`order/${billId}/problem`, data);
  }
};
