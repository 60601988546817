import Vue from "vue";

import App from "@/App.vue";

import router from "@/router";
import store from "@/store";

import i18n from "@/i18n";

import MathJax from "@/common/mathJax";
Vue.prototype.$mathJax = MathJax;

import ApiService from "@/common/api.service";
ApiService.init();

import VueTelInput from "vue-tel-input";
import "vue-tel-input/dist/vue-tel-input.css";

const TelOptions = {
  autoFormat: false,
  dropdownOptions: {
    disabled: false,
    showDialCodeInList: true,
    showDialCodeInSelection: true,
    showFlags: true,
    showSearchBox: true
  }
};
Vue.use(VueTelInput, TelOptions);

import ElementUI from "element-ui";
import locale from "element-ui/lib/locale/lang/en";

Vue.use(ElementUI, { locale });

/*
  一些 hidden-尺寸 的 class
  https://element.eleme.io/#/en-US/component/layout#utility-classes-for-hiding-elements
*/
import "element-ui/lib/theme-chalk/display.css";
import "@/assets/scss/ivy-element/index.scss";

import satMaterial from "@ivy-way/sat-material";
import "@ivy-way/sat-material/dist/@ivy-way/sat-material.css";
import "@/assets/scss/sat-material/index.css";
Vue.use(satMaterial);

// 加入 bootstrap
import "bootstrap";

// 加入 font awesome
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

// 主要是 helper class，沒有全加
import "@/assets/scss/ivy-bootstrap.scss";

import VueSweetalert2 from "vue-sweetalert2";
const options = {
  confirmButtonColor: "#42A16A"
};
Vue.use(VueSweetalert2, options);

import VueMeta from "vue-meta";
Vue.use(VueMeta);

// 加入 CKEditor4
import CKEditor from "ckeditor4-vue";
Vue.use(CKEditor);

// 加入 CKEditor5
import CKEditor5 from "@ckeditor/ckeditor5-vue2";
Vue.component("ckeditor5", CKEditor5.component);

//全局的通用組件
import commonComponents from "./commonComponents";
Vue.use(commonComponents);

Vue.mixin({
  methods: {
    routerPush(route) {
      this.$router.push(route);
    },
    historyBack() {
      this.$router.go(-1);
    }
  }
});

Vue.config.productionTip = false;

Vue.prototype.getIvywayExamTitle = function(title) {
  return title.replace("SATMocks", "Ivy-Way");
};

// if(JwtService.hasToken()){
//   if(store.state.user.user === null){
//       store.dispatch('user/getUser').then({})
//   }
// }

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
