/* eslint no-empty-pattern: 0 */
import _ from "lodash";
import { user, instant } from "@ivy-way/material";
import { Message } from "element-ui";
import moment from "moment";
import money from "@/mixins/money";
import role from "@/mixins/role";
import billApi from "@/apis/bill";
import enrollmentApi from "@/apis/enrollment";
import privateApi from "@/apis/private";
import profileApi from "@/apis/profile";
import toeflApi from "@/apis/toefl";

const getNextSelectType = selectType => {
  switch (selectType) {
    case "unSelected":
      return "selected";
    case "selected":
      return "unSelected";
    case "notSelectedAll":
      return "unSelected";
    default:
      return "unSelected";
  }
};

const initialState = {
  parentUnpaidFees: {},
  unpaidUserWithoutTag: [],
  unpaidBills: [],
  receivables: [],
  paidBills: [],
  paidBillsCount: 0,
  parentUnpaidEnrollments: [],
  students: [],
  tempStudents: [],
  orderItems: [],
  originalOrderItems: [],
  lessonLogs: [],
  transactions: [],
  paymentInfo: {},
  balanceTransaction: {},
  billParent: {
    id: null,
    name: null,
    balance: 0,
    balanceCurrency: "TWD",
    defaultCurrency: "TWD"
  },
  billTitle: "",
  billNotes: "",
  billReceivableDate: null,
  billReceivableEnrollmentId: null,
  billReceivableEnrollmentTitle: { tw: "", cn: "", en: "" },
  billCurrency: "TWD",
  billTotalPrice: 0,
  billStatus: null,
  billPaymentIndex: 1,
  paymentInfoRemark: "",
  billDifferencePrice: 0,
  billDifferencePriceCurrency: "TWD",
  createAt: null,
  markPaidAt: null,
  userWhoConfirmedTransaction: null,
  emailToUsers: []
};

const state = { ...initialState };

const getters = {
  students(state) {
    return state.students;
  },
  tempStudents(state) {
    return state.tempStudents.map((student, index) => ({
      index: index + 1,
      ...student
    }));
  },
  parentUnpaidEnrollments(state) {
    return state.parentUnpaidEnrollments;
  },
  parentUnpaidFees(state) {
    return Object.keys(state.parentUnpaidFees)
      .map(key => state.parentUnpaidFees[key])
      .map(
        parentUnpaidFee => ({
          ...parentUnpaidFee,
          students: parentUnpaidFee.parent_user_students.map((student) => ({
            userId: student.id,
            userName: student.name
          })),
          latestOrder: {
            ...parentUnpaidFee.latest_order,
            displayTotalPrice: parentUnpaidFee.latest_order ?
              money.methods.addCurrencySymbol(
                parentUnpaidFee.latest_order.total_price,
                parentUnpaidFee.latest_order.price_currency
              ) : "",
            createDate: moment(parentUnpaidFee.latest_order).format("YYYY-MM-DD")
          }
        })
      );
  },
  receivables(state) {
    return state.receivables.map(receivable => ({
      ...receivable,
      displayTotalPrice: money.methods.addCurrencySymbol(
        receivable.price,
        receivable.price_currency
      )
    }));
  },
  unpaidUserWithoutTag(state) {
    return state.unpaidUserWithoutTag;
  },
  unpaidBills(state) {
    return state.unpaidBills;
  },
  paidBills(state) {
    return state.paidBills;
  },
  paidBillsCount(state) {
    return state.paidBillsCount;
  },
  orderItems(state) {
    return state.orderItems;
  },
  lessonLogs(state) {
    return state.lessonLogs;
  },
  transactions(state) {
    return state.transactions;
  },
  paymentInfo(state) {
    return state.paymentInfo;
  },
  balanceTransaction(state) {
    return state.balanceTransaction;
  },
  problems(state) {
    return state.problems;
  },
  billParent(state) {
    return state.billParent;
  },
  billCurrency(state) {
    return state.billCurrency;
  },
  billTotalPrice(state) {
    return state.billTotalPrice;
  },
  billTitle(state) {
    return state.billTitle;
  },
  billNotes(state) {
    return state.billNotes;
  },
  billReceivableDate(state) {
    return state.billReceivableDate;
  },
  billReceivableEnrollmentId(state) {
    return state.billReceivableEnrollmentId;
  },
  billReceivableEnrollmentTitle(state) {
    return state.billReceivableEnrollmentTitle;
  },
  billDifferencePrice(state) {
    return state.billDifferencePrice;
  },
  billDifferencePriceCurrency(state) {
    return state.billDifferencePriceCurrency;
  },
  emailToUsers(state) {
    return state.emailToUsers;
  },
  billStatus(state) {
    return state.billStatus;
  },
  billPaymentIndex(state) {
    return state.billPaymentIndex;
  },
  paymentInfoRemark(state) {
    return state.paymentInfoRemark;
  },
  createAt(state) {
    return state.createAt;
  },
  markPaidAt(state) {
    return state.markPaidAt;
  },
  userWhoConfirmedTransaction(state) {
    return state.userWhoConfirmedTransaction;
  },
  paidBillTotalByBalanceLeft(state) {
    const { balance, balanceCurrency } = state.billParent;
    const balanceByBillCurrency = money.methods.convertPriceByCurrency(
      balance,
      balanceCurrency,
      state.billCurrency
    );
    return money.methods.addCurrencySymbol(
      Number(balanceByBillCurrency) - Number(state.billTotalPrice),
      state.billCurrency
    );
  }
};

const mutations = {
  initializeBill(state) {
    Object.keys(initialState).forEach(key => {
      state[key] = initialState[key];
    });
  },
  setStudent(state, students) {
    state.students = students;
  },
  setTempStudents(state, tempStudents) {
    state.tempStudents = tempStudents;
  },
  setParentUnpaidFees(state, parentUnpaidFees) {
    state.parentUnpaidFees = parentUnpaidFees;
  },
  setParentUnpaidEnrollments(state, parentUnpaidEnrollments) {
    state.parentUnpaidEnrollments = parentUnpaidEnrollments;
  },
  setUnpaidUserWithoutTag(state, unpaidUserWithoutTag) {
    state.unpaidUserWithoutTag = unpaidUserWithoutTag;
  },
  setUnpaidBills(state, unpaidBills) {
    state.unpaidBills = unpaidBills;
  },
  setReceivables(state, receivables) {
    state.receivables = receivables;
  },
  setPaidBills(state, { paidBills, paidBillsCount }) {
    state.paidBills = paidBills;
    state.paidBillsCount = paidBillsCount;
  },
  setOrderItems(state, orderItems) {
    state.orderItems = orderItems.map(orderItem => ({
      ...orderItem,
      displayPrice: money.methods.addCurrencySymbol(
        Number(orderItem.price),
        orderItem.priceCurrency
      ),
      displayPriceByCurrency: money.methods.addCurrencySymbol(
        Number(orderItem.priceByCurrency),
        state.billCurrency
      )
    }));
  },
  setOriginalOrderItems(state, orderItems) {
    state.originalOrderItems = _.cloneDeep(orderItems);
  },
  setLessonLogs(state, lessonLogs) {

    lessonLogs = _.reverse(_.sortBy(lessonLogs, "teaching_log_id"));
    state.lessonLogs = lessonLogs.map(lessonLog => ({
      ...lessonLog,
      displayFee: money.methods.addCurrencySymbol(
        Number(lessonLog.fee),
        lessonLog.feeCurrency
      ),
      displayFeeByCurrency: money.methods.addCurrencySymbol(
        Number(lessonLog.feeByCurrency),
        state.billCurrency
      )
    }));
  },
  setProblems(state, problems) {
    state.problems = problems;
  },
  setMarkPaidAt(state, markPaidAt) {
    state.markPaidAt = markPaidAt;
  },
  setUserWhoConfirmedTransaction(state, user) {
    state.userWhoConfirmedTransaction = user;
  },
  setTransactions(state, transactions) {
    state.transactions = transactions;
  },
  setPaymentInfo(state, paymentInfo) {
    state.paymentInfo = paymentInfo;
  },
  setBalanceTransaction(state, balanceTransaction) {
    state.balanceTransaction = balanceTransaction;
  },
  setBillParent(state, billParent) {
    state.billParent = billParent;
  },
  setBillCurrency(state, billCurrency) {
    state.billCurrency = billCurrency;
  },
  setBillStatus(state, billStatus) {
    state.billStatus = billStatus;
  },
  setBillTotalPrice(state, billTotalPrice) {
    state.billTotalPrice = billTotalPrice;
  },
  setBillTitle(state, billTitle) {
    console.log(billTitle);
    state.billTitle = billTitle;
  },
  setBillNotes(state, billNotes) {
    console.log(billNotes);
    state.billNotes = billNotes;
  },
  setBillReceivableDate(state, billReceivableDate) {
    state.billReceivableDate = billReceivableDate;
  },
  setBillReceivableEnrollmentId(state, billReceivableEnrollmentId) {
    state.billReceivableEnrollmentId = billReceivableEnrollmentId;
  },
  setBillReceivableEnrollmentTitle(state, billReceivableEnrollmentTitle) {
    state.billReceivableEnrollmentTitle = billReceivableEnrollmentTitle;
  },
  setBillDifferencePrice(state, billDifferencePrice) {
    state.billDifferencePrice = Number(billDifferencePrice) || 0;
  },
  setBillDifferencePriceCurrency(state, billDifferencePriceCurrency) {
    state.billDifferencePriceCurrency = billDifferencePriceCurrency || "TWD";
  },
  setBillEmailToUsers(state, emailToUsers) {
    state.emailToUsers = emailToUsers;
  },
  setBillPaymentIndex(state, billPaymentIndex) {
    state.billPaymentIndex = billPaymentIndex;
  },
  setPaymentInfoRemark(state, paymentInfoRemark) {
    state.paymentInfoRemark = paymentInfoRemark;
  },
  setCreateAt(state, createAt) {
    state.createAt = createAt;
  }
};

const actions = {
  initializeBill({ commit }) {
    commit("initializeBill");
  },
  setOrderItems({ commit }, orderItems) {
    commit("setOrderItems", orderItems);
  },
  setLessonLogs({ commit }, lessonLogs) {
    commit("setLessonLogs", lessonLogs);
  },
  async setBillParent({ commit }, parentId) {
    if (!role.methods.isRoleAdmin()) return;
    const { basic_info: parent } = await profileApi.getUserData(parentId);
    commit("setBillParent", {
      id: parent.id,
      name: user.displayName(parent.first_name, parent.last_name),
      balance: Number(parent.account_balance),
      balanceCurrency: parent.account_balance_currency,
      defaultCurrency: parent.default_currency || "TWD"
    });
  },
  setBillCurrency({ commit }, billCurrency) {
    commit("setBillCurrency", billCurrency);
  },
  setBillStatus({ commit }, billStatus) {
    commit("setBillStatus", billStatus);
  },
  setBillTotalPrice({ commit }, billTotalPrice) {
    commit("setBillTotalPrice", billTotalPrice);
  },
  setBillTitle({ commit }, billTitle) {
    console.log(billTitle);
    commit("setBillTitle", billTitle);
  },
  setBillNotes({ commit }, billNotes) {
    console.log(billNotes);
    commit("setBillNotes", billNotes);
  },
  setBillPaymentIndex({ commit }, billPaymentIndex) {
    commit("setBillPaymentIndex", billPaymentIndex);
  },
  addStudent({ commit, state }, { id, name }) {
    const students = [...state.students, { id, name }];
    commit("setStudent", students);
  },
  setTempStudents({ commit }, students) {
    const tempStudents = students.map(student => ({
      ...student,
      edit: false
    }));
    commit("setTempStudents", tempStudents);
  },
  addTempStudent({ commit, state }) {
    const students = [
      ...state.tempStudents,
      { edit: true, user_id: null, name: null }
    ];
    commit("setTempStudents", students);
  },
  setTempStudent({ commit, state }, { index, newStudent }) {
    state.tempStudents[index] = {
      ...state.tempStudents[index],
      ...newStudent
    };
    commit("setTempStudents", [...state.tempStudents]);
  },
  removeStudent({ commit, state }, { targetIndex }) {
    const tempStudent = state.tempStudents[targetIndex];
    const student = state.students.find(
      student => student.id === tempStudent.user_id
    );
    if (student) {
      const studentId = student.id;
      const students = state.students.filter(
        student => student.id !== studentId
      );
      commit("setStudent", students);

      const orderItems = state.orderItems.filter(
        orderItem => orderItem.studentId !== studentId
      );
      commit("setOrderItems", orderItems);

      const lessonLogs = state.lessonLogs.filter(
        lessonLog => lessonLog.studentId !== studentId
      );
      commit("setLessonLogs", lessonLogs);
    }

    const tempStudents = state.tempStudents.filter(
      (_tempStudent, index) => index !== targetIndex
    );
    commit("setTempStudents", tempStudents);
  },
  async getUnpaidUserWithoutTag({ commit }) {
    const response = await billApi.getUnpaidUserWithoutTag();
    commit("setUnpaidUserWithoutTag", response.users || []);
  },
  async getParentUnpaidEnrollments({ commit }, parentId) {
    const { enrollment_sessions } = await billApi.getParentUnpaidEnrollments(
      parentId
    );
    commit(
      "setParentUnpaidEnrollments",
      enrollment_sessions.filter(({ course_session }) => course_session)
    );
  },
  async getUnpaidListByStudentId(
    { commit, state },
    {
      studentId,
      doNotNeedToSelected,
      defaultSelectedOrderId,
      defaultSelectedLessonLogId
    }
  ) {
    const {
      enrolled_sessions: enrolledSessions,
      lesson_logs: lessonLogs,
      receivable_order_items: receivableOrderItems
    } = await billApi.getUnpaidListByStudentId(studentId);
    const getSelectType = (defaultSelectedId, targetId) => {
      if (doNotNeedToSelected) return "unSelected";
      if (defaultSelectedId === undefined) return "selected";
      return Number(defaultSelectedId) === targetId ? "selected" : "unSelected";
    };
    const formattedEnrolledSessions = enrolledSessions.map(enrolledSession => ({
      selectType: getSelectType(defaultSelectedOrderId, enrolledSession.id),
      id: enrolledSession.id,
      courseSessionId: enrolledSession.course_session_id,
      originalPrice: Number(enrolledSession.original_price),
      priceCurrency:
        enrolledSession.price_currency === ""
          ? "TWD"
          : enrolledSession.price_currency,
      price: Number(enrolledSession.price),
      title: enrolledSession.title ? enrolledSession.title.tw : "",
      studentId,
      studentName: user.displayName(
        enrolledSession.first_name,
        enrolledSession.last_name
      ),
      type: enrolledSession.type,
      remark: enrolledSession.discount_info
    }));

    const formattedReceivables = receivableOrderItems.map(
      receivableOrderItem => ({
        selectType: getSelectType(
          defaultSelectedOrderId,
          receivableOrderItem.id
        ),
        id: receivableOrderItem.id,
        originalPrice: Number(receivableOrderItem.price),
        priceCurrency: receivableOrderItem.price_currency || "TWD",
        price: Number(receivableOrderItem.price),
        title: `${receivableOrderItem.title} (${receivableOrderItem.receivable_date})`,
        studentId: receivableOrderItem.user_id,
        studentName: user.displayName(
          receivableOrderItem.user.first_name,
          receivableOrderItem.user.last_name
        ),
        type: "App\\Entities\\ReceivableOrderItem",
        remark: ""
      })
    );

    const formattedLessonLogs = lessonLogs.map(lessonLog => ({
      selectType: getSelectType(defaultSelectedLessonLogId, lessonLog.id),
      id: lessonLog.id,
      classId: lessonLog.class_id,
      enrolledSessionId: lessonLog.enrolled_session_id,
      date: lessonLog.started_at,
      teacherName: user.displayName(
        lessonLog.teacher_first_name,
        lessonLog.teacher_last_name
      ),
      studentName: user.displayName(
        lessonLog.student_first_name,
        lessonLog.student_last_name
      ),
      studentId,
      hour: lessonLog.teaching_log.full_hour,
      fee: Number(lessonLog.fee) * lessonLog.teaching_log.full_hour,
      feeCurrency: lessonLog.fee_currency,
      subject: lessonLog.subject,
      teaching_log_id: lessonLog.teaching_log_id
    }));

    commit("setOrderItems", [
      ...state.orderItems,
      ...formattedEnrolledSessions,
      ...formattedReceivables
    ]);
    commit("setLessonLogs", [...state.lessonLogs, ...formattedLessonLogs]);
  },
  switchOrderItemSelectType(
    { commit, state },
    { orderItems, orderItemId, selectType }
  ) {
    const nextSelectType = getNextSelectType(selectType);
    const newOrderItems = orderItems.map(orderItem => {
      if (String(orderItem.id) !== String(orderItemId)) return { ...orderItem };
      return {
        ...orderItem,
        selectType: nextSelectType
      };
    });

    const targetOrderItem = orderItems.find(
      orderItem => String(orderItem.id) === String(orderItemId)
    );

    const newLessonLogs = state.lessonLogs.map(lessonLog => {
      if (lessonLog.enrolledSessionId === targetOrderItem.id) {
        return {
          ...lessonLog,
          selectType: nextSelectType
        };
      }

      return { ...lessonLog };
    });

    commit("setLessonLogs", newLessonLogs);
    commit("setOrderItems", newOrderItems);
  },
  switchLessonLogSelectType(
    { commit, dispatch },
    { lessonLogs, enrolledSessionId, lessonLogId, selectType }
  ) {
    const nextSelectType = getNextSelectType(selectType);
    const newLessonLogs = lessonLogs.map(lessonLog => {
      if (lessonLog.id !== Number(lessonLogId)) return { ...lessonLog };
      return {
        ...lessonLog,
        selectType: nextSelectType
      };
    });
    commit("setLessonLogs", newLessonLogs);
    dispatch("updateOrderItemSelectTypeByLessonLog", { enrolledSessionId });
  },
  updateOrderItemSelectTypeByLessonLog(
    { commit, state },
    { enrolledSessionId }
  ) {
    const checkSelectTypeIsAll = (lessonLogs, selectType) => {
      return lessonLogs
        .filter(
          lessonLog => lessonLog.enrolledSessionId === Number(enrolledSessionId)
        )
        .every(lessonLog => lessonLog.selectType === selectType);
    };

    let selectTypeOfSameEnrollmentAsLessonLog = "notSelectedAll";
    if (checkSelectTypeIsAll(state.lessonLogs, "selected")) {
      selectTypeOfSameEnrollmentAsLessonLog = "selected";
    }
    if (checkSelectTypeIsAll(state.lessonLogs, "unSelected")) {
      selectTypeOfSameEnrollmentAsLessonLog = "unSelected";
    }

    const newOrderItems = state.orderItems.map(orderItem => {
      if (orderItem.id !== Number(enrolledSessionId)) return { ...orderItem };
      return {
        ...orderItem,
        selectType: selectTypeOfSameEnrollmentAsLessonLog
      };
    });

    commit("setOrderItems", newOrderItems);
  },
  calculationOrderItemTotalByLessonLog({ commit, state }, enrolledSessionId) {
    const selectedLessonLogsFeeByCurrency = state.lessonLogs
      .filter(lessonLog => {
        return (
          lessonLog.enrolledSessionId === enrolledSessionId &&
          lessonLog.selectType === "selected"
        );
      })
      .map(lessonLog => Number(lessonLog.feeByCurrency));
    const lessonLogsTotal = selectedLessonLogsFeeByCurrency.reduce(
      (prev, curr) => prev + curr,
      0
    );
    const orderItems = state.orderItems.map(orderItem => {
      if (orderItem.id !== enrolledSessionId) return { ...orderItem };
      return {
        ...orderItem,
        price: lessonLogsTotal
      };
    });
    commit("setOrderItems", orderItems);
  },
  addOtherItem({ commit, state }, orderItem) {
    const orderItems = [...state.orderItems];
    orderItems.push({
      selectType: "selected",
      type: "",
      id: 0,
      priceByCurrency: 0,
      remark: "",
      ...orderItem
    });
    commit("setOrderItems", orderItems);
  },
  async getParentUnpaidFees({ commit }, { filterTagId }) {
    const parentUnpaidFees = await billApi.getUsersUnpaid({
      tag_ids: [filterTagId]
    });
    commit("setParentUnpaidFees", parentUnpaidFees);
  },
  async getReceivables({ commit }) {
    const response = await billApi.getReceivables();
    commit("setReceivables", response?.receivable_orders || []);
  },
  async getUnpaidBills({ commit }) {
    const parentUnpaidFees = await billApi.getUnpaidBills();
    commit("setParentUnpaidFees", parentUnpaidFees);
  },
  async computeUnpaidFeeAndOldestUnpaidItemDate({ commit, state }) {
    await money.methods.initialExchangeRate();
    const unexistOrderAndExistParent = state.parentUnpaidFees
      .filter(({ latest_order }) => !latest_order)
      .filter(({ parent_user_id }) => parent_user_id);
    const allParentUnpaidChildren = await Promise.all(
      unexistOrderAndExistParent.map(({ parent_user_id }) => (
        billApi.getUnpaidChildrens(parent_user_id)
      ))
    );

    let parentMapChildren = {};
    allParentUnpaidChildren.forEach(({ children_users }, index) => {
      parentMapChildren[
        unexistOrderAndExistParent[index].parent_user_id
      ] = children_users;
    });

    const allChildrenUnpaidTotal = await Promise.all(Object.keys(parentMapChildren)
      .map(
        async parentUserId => {
          const childrenUnpaidList = await Promise.all(
            parentMapChildren[parentUserId].map(({ id: studentId }) =>
              billApi.getUnpaidListByStudentId(studentId)
            )
          );
          const unpaidPriceAndCurrency = [];
          const unpaidItemDates = [];
          childrenUnpaidList.forEach(
            ({ enrolled_sessions, lesson_logs, receivable_order_items }) => {
              (enrolled_sessions || []).forEach(item => {
                unpaidPriceAndCurrency.push({
                  price: Number(item.price),
                  currency: item.price_currency || "TWD"
                });
              });
              (lesson_logs || []).forEach(lessonLogs => {
                unpaidPriceAndCurrency.push({
                  price: Number(lessonLogs.fee) * lessonLogs.teaching_log.full_hour,
                  currency: lessonLogs.fee_currency || "TWD"
                });
                unpaidItemDates.push(moment(lessonLogs.started_at));
              });
              (receivable_order_items || []).forEach(receivableOrderItem => {
                unpaidPriceAndCurrency.push({
                  price: Number(receivableOrderItem.price),
                  currency: receivableOrderItem.price_currency || "TWD"
                });
                unpaidItemDates.push(moment(receivableOrderItem.receivable_date));
              });
            });
          return {
            parentUserId,
            unpaidTotal: money.methods.addCurrencySymbol(
              money.methods.calculationPriceWithCurrency(unpaidPriceAndCurrency),
              money.methods.getMainCurrency(unpaidPriceAndCurrency.map(({ currency }) => currency))
            ),
            oldestUnpaidItemDate: (
              unpaidItemDates.length > 0
                ? moment.min(unpaidItemDates).format("YYYY-MM-DD")
                : null
            )
          };
        }
      ));

    const parentAndUnpaidTotalMap = {};
    const parentAndOldestUnpaidItemDateMap = {};
    allChildrenUnpaidTotal.forEach(({ parentUserId, unpaidTotal, oldestUnpaidItemDate }) => {
      parentAndUnpaidTotalMap[parentUserId] = unpaidTotal;
      parentAndOldestUnpaidItemDateMap[parentUserId] = oldestUnpaidItemDate;
    });
    let workedParentUnpaidFees = [...state.parentUnpaidFees].map(
      parentUnpaidFee => ({
        ...parentUnpaidFee,
        pendingOrderTotal: parentAndUnpaidTotalMap[parentUnpaidFee.parent_user_id],
        oldestUnpaidItemDate: parentAndOldestUnpaidItemDateMap[parentUnpaidFee.parent_user_id]
      })
    );
    commit("setParentUnpaidFees", workedParentUnpaidFees);
  },
  async generateBillFromEnroll({ dispatch }, enrollmentId) {
    const enroll = await enrollmentApi.fetch({ id: enrollmentId });
    dispatch("setBillParent", enroll.parent_profile.basic_info.id);
    const student = enroll.student_profile.basic_info;
    return {
      student: {
        user_id: student.id,
        name: user.displayName(student.first_name, student.last_name)
      }
    };
  },

  async getPaidBills(
    { commit },
    { pageSize, page, search, dateStart, dateEnd }
  ) {
    const { data: paidBills, total } = await billApi.getPaidBills({
      page_size: pageSize,
      page,
      search,
      start_date: dateStart,
      end_date: dateEnd
    });
    const formattedPaidBills = paidBills.map(paidBill => {
      const priceCurrency = paidBill.price_currency;
      return {
        ...paidBill,
        displayTotalPrice: money.methods.addCurrencySymbol(
          paidBill.total_price,
          priceCurrency
        )
      };
    });

    commit("setPaidBills", {
      paidBills: formattedPaidBills,
      paidBillsCount: total
    });
  },
  async generateBillFromLessonLog({ dispatch }, classId) {
    const privateLesson = await privateApi.getCustomLesson({
      class_id: classId
    });
    const studentId = privateLesson.lesson_logs[0].student_user_id;
    return await dispatch("setBillFromStudent", studentId);
  },
  async setBillFromStudent({ dispatch }, studentId) {
    const { student, basic_info } = await profileApi.getUserData(studentId);
    await dispatch("setBillParent", student.parents[0].pivot.parent_user_id);

    return {
      student: {
        user_id: basic_info.id,
        name: user.displayName(basic_info.first_name, basic_info.last_name)
      }
    };
  },
  async setBillFromOtherBill({ dispatch }, billId) {
    const {
      order_items: orderItems,
      lesson_logs: lessonLogs,
      parent_user_id: parentUserId
    } = await billApi.getBill(billId);

    dispatch("setBillParent", parentUserId);
    const studentsMap = {};
    orderItems.map(orderItem => {
      studentsMap[orderItem.user_id] = {
        user_id: orderItem.user_id,
        name: orderItem.name
      };
    });

    const students = Object.keys(studentsMap).map(
      userId => studentsMap[userId]
    );
    return { students, orderItems, lessonLogs };
  },
  async getBill({ commit, dispatch }, billId) {
    const bill = await billApi.getBill(billId);
    const orderitems = bill.order_items.map(orderItem => ({
      ...orderItem,
      selectType: "unSelected",
      studentName: orderItem.name,
      studentId: orderItem.user_id,
      priceCurrency: orderItem.price_currency,
      orderItemId: orderItem.id,
      price: Number(orderItem.price)
    }));
    const lessonLogs = bill.lesson_logs.map(lessonLog => ({
      ...lessonLog,
      selectType: "unSelected",
      studentId: lessonLog.student_user_id,
      classId: lessonLog.session_class_id,
      date: lessonLog.started_at,
      fee: Number(lessonLog.fee) * lessonLog.full_hour,
      feeCurrency: lessonLog.fee_currency,
      subject: lessonLog.subject,
      teacherName: lessonLog.teacher_name,
      studentName: lessonLog.student_name,
      hour: lessonLog.full_hour,
      orderItemId: lessonLog.order_item_id
    }));

    const transactions = bill.transactions.map(transaction => ({
      ...transaction,
      displayDate: moment(transaction.datetime).format("YYYY-MM-DD"),
      originalPrice: Number(transaction.original_price),
      originalCurrency: transaction.original_currency,
      price: Number(transaction.price)
    }));

    const problems = bill.problems.map(problem => ({
      ...problem,
      items: problem.items.map(item => {
        if (item.type === "order_item") {
          const orderItem = orderitems.find(
            orderItem => orderItem.id === item.id
          );
          return { name: orderItem.name, title: orderItem.title };
        }

        if (item.type === "lesson_log") {
          const lessonLog = lessonLogs.find(
            lessonLog => lessonLog.orderItemId === item.id
          );
          return {
            name: lessonLog.studentName,
            title: `${lessonLog.title.tw} ${lessonLog.date}`
          };
        }
      })
    }));

    const emailsWithUser = bill.order_emails.map(orderEmail => {
      const emailUsers = orderEmail.emails.map(email => {
        const user = orderEmail.users.find(user => user.email === email);
        if (user) return user;

        return { email };
      });
      return {
        ...orderEmail,
        emailUsers
      };
    });

    const billStudents = [];
    bill.order_items.forEach(orderItem => {
      const isExistStudent = billStudents.some(
        student => student.id === orderItem.user_id
      );

      if (!isExistStudent) {
        billStudents.push({ id: orderItem.user_id, name: orderItem.name });
      }
    });
    dispatch("setBillParent", bill.parent_user_id);
    commit("setStudent", billStudents);
    commit("setBillTitle", bill.title);
    commit("setBillNotes", bill.notes);
    commit("setBillReceivableDate", bill.receivable_date);
    commit("setBillEmailToUsers", emailsWithUser);
    commit("setCreateAt", moment(bill.created_at).format("YYYY-MM-DD"));
    commit("setBillCurrency", bill.price_currency);
    commit("setBillTotalPrice", Number(bill.total_price));
    commit("setBillStatus", bill.status);
    commit("setOrderItems", orderitems);
    commit("setLessonLogs", lessonLogs);
    commit("setTransactions", transactions);
    commit("setBillDifferencePrice", bill.difference_price);
    commit("setBillDifferencePriceCurrency", bill.difference_price_currency);
    commit("setPaymentInfo", bill.payment_info);
    commit("setBillPaymentIndex", bill.payment_info_index);
    commit("setPaymentInfoRemark", bill.payment_info_remark);
    commit("setBalanceTransaction", bill.remark_to_system);
    commit("setProblems", problems);
    commit("setMarkPaidAt", bill.mark_paid_at);
    commit("setUserWhoConfirmedTransaction", bill.mark_paid_by);
  },
  async generateReceivableFromEnroll({ dispatch, commit }, enrollmentId) {
    const enroll = await enrollmentApi.fetch({ id: enrollmentId });
    dispatch("setBillParent", enroll.parent_profile.basic_info.id);
    commit("setBillReceivableEnrollmentId", enrollmentId);
    return enroll.parent_profile.basic_info.id;
  },
  async getReceivable({ commit, dispatch }, receivableId) {
    const { receivable_order: receivable } = await billApi.getReceivable(
      receivableId
    );
    const orderItems = receivable.receivable_order_items.map(orderItem => ({
      ...orderItem,
      type: "editCustomItem",
      selectType: "selected",
      studentName: user.displayName(
        orderItem.user.first_name,
        orderItem.user.last_name
      ),
      studentId: orderItem.user_id,
      receivableDate: orderItem.receivable_date,
      priceCurrency: orderItem.price_currency,
      orderItemId: orderItem.id,
      price: Number(orderItem.price)
    }));

    await dispatch("setBillParent", receivable.user_id);
    commit("setBillTitle", receivable.title);
    commit("setBillReceivableDate", receivable.receivable_date);
    if (receivable.item) {
      commit("setBillReceivableEnrollmentId", receivable.item.id);
      commit("setBillReceivableEnrollmentTitle", receivable.item.course_session.title);
    }
    commit("setCreateAt", moment(receivable.created_at).format("YYYY-MM-DD"));
    commit("setBillCurrency", receivable.price_currency);
    commit("setBillTotalPrice", Number(receivable.price));
    commit("setOrderItems", orderItems);
    commit("setOriginalOrderItems", orderItems);
  },
  async createBill({ state }) {
    if (state.billTitle.trim() === "") {
      Message.error("帳單名稱不得為空！");
      throw { error: "require", label: "billTitle" };
    }

    if (state.billReceivableDate === null) {
      Message.error("收款日期不得為空！");
      throw { error: "require", label: "billReceivableDate" };
    }

    const getOrderItemType = workType => {
      // 家教課和團班 type 要轉為 enrolled_session
      const [PRIVATE_CLASS_TYPE, GROUP_CLASS_TYPE] = ["custom", "default"];
      if ([PRIVATE_CLASS_TYPE, GROUP_CLASS_TYPE].includes(workType)) {
        return "enrolled_session";
      }
      return workType;
    };

    const formattedBill = {
      title: state.billTitle,
      notes: state.billNotes,
      receivable_date: state.billReceivableDate,
      user_id: state.billParent.id,
      total_price: state.billTotalPrice,
      price_currency: state.billCurrency,
      payment_index: state.billPaymentIndex,
      payment_info_remark: state.paymentInfoRemark,
      remark: "",
      items: state.orderItems
        .filter(orderItem =>
          ["selected", "notSelectedAll"].includes(orderItem.selectType)
        )
        .map(orderItem => {
          const item = {
            type: getOrderItemType(orderItem.type),
            id: orderItem.id,
            user_id: orderItem.studentId,
            title: orderItem.title,
            price: orderItem.price,
            price_currency: orderItem.priceCurrency,
            remark: orderItem.remark
          };
          if (orderItem.type === "custom") {
            item.lesson_logs = state.lessonLogs
              .filter(lessonLog => {
                return (
                  lessonLog.enrolledSessionId === orderItem.id &&
                  lessonLog.selectType === "selected"
                );
              })
              .map(lessonLog => lessonLog.id);
          }
          if (orderItem.type === "editCustomItem") {
            item.type = "editCustomItem";
          }
          return item;
        })
    };
    const bill = await billApi.createBill(formattedBill);
    return bill;
  },
  async updateBill({ state }, billId) {
    await billApi.updateBill(billId, { title: state.billTitle, notes:  state.billNotes });
  },
  async deleteBill({}, billId) {
    await billApi.deleteBill(billId);
  },
  async createReceivable({ state }) {
    if (state.billTitle.trim() === "") {
      Message.error("未來款項名稱名稱不得為空！");
      throw { error: "require", label: "billTitle" };
    }
    const formattedBill = {
      title: state.billTitle,
      user_id: state.billParent.id,
      price: state.billTotalPrice,
      price_currency: state.billCurrency,
      enrolled_session_id: state.billReceivableEnrollmentId,
      items: state.orderItems.map(orderItem => ({
        user_id: orderItem.studentId,
        title: orderItem.title,
        receivable_date: orderItem.receivableDate,
        price: orderItem.price,
        price_currency: orderItem.priceCurrency
      }))
    };
    const bill = await billApi.createReceivable(formattedBill);
    return bill;
  },
  async updateReceivable({ state }, receivableId) {
    class ReceivableOrderItem {
      constructor(props) {
        this.user_id = props.studentId;
        this.title = props.title;
        this.receivable_date = props.receivableDate;
        this.price = props.price;
        this.price_currency = props.priceCurrency;
      }
    }
    await billApi.updateReceivable(receivableId, {
      title: state.billTitle,
      user_id: state.billParent.id,
      price: state.billTotalPrice,
      price_currency: state.billCurrency
    });
    const handleOrderItemPromises = [];
    const workedOrderItemIds = state.orderItems.map(({ id }) => id);
    const deleteOrderItems = state.originalOrderItems.filter(
      ({ id }) => !workedOrderItemIds.includes(id)
    );
    deleteOrderItems.forEach(({ id }) => {
      handleOrderItemPromises.push(billApi.deleteReceivableOrderItem(id));
    });

    const createOrderItems = state.orderItems.filter(({ id }) => id === 0);
    createOrderItems.forEach(orderItem => {
      handleOrderItemPromises.push(
        billApi.createReceivableOrderItem(
          receivableId,
          new ReceivableOrderItem(orderItem)
        )
      );
    });

    const updateOrderItems = state.orderItems.filter(
      ({ id, edit }) => edit !== undefined && id !== 0
    );
    updateOrderItems.forEach(orderItem => {
      handleOrderItemPromises.push(
        billApi.updateReceivableOrderItem(
          orderItem.id,
          new ReceivableOrderItem(orderItem)
        )
      );
    });

    await Promise.all(handleOrderItemPromises);
  },
  async deleteReceivable({}, receivableId) {
    await billApi.deleteReceivable(receivableId);
  },
  async sendBill({ state }, { billId, usersMail, customContent, lang }) {
    await billApi.sendBill(billId, {
      users: usersMail,
      lang,
      custom_content: customContent,
      expire_date: state.billReceivableDate
    });
  },
  async createTransaction({}, { billId, transaction }) {
    await billApi.createTransaction(billId, transaction);
  },
  async verifyTransaction({}, transactionId) {
    await billApi.verifyTransaction(transactionId);
  },
  async updateOrderDifferencePrice({ state }, billId) {
    await billApi.updateOrderDifferencePrice(
      billId,
      state.billDifferencePrice,
      state.billDifferencePriceCurrency
    );
  },
  async markPaid({}, bill) {
    const orderItems = bill.order_items.map(orderItem => ({
      type: "order_item",
      id: orderItem.id
    }));
    const lessonLogs = bill.lesson_logs.map(lessonLog => ({
      type: "lesson_log",
      id: lessonLog.id
    }));

    await billApi.markPaid(bill.id, { items: [...orderItems, ...lessonLogs] });
  },
  async submitProblem({}, { billId, problem }) {
    await billApi.submitProblem(billId, { ...problem });
  },
  async deleteUploadFile({}, id) {
    return await toeflApi.deleteAudio(id);
  },
  async deletePaidBill({}, billId) {
    await billApi.deletePaidBill(billId);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
