import ApiService from "@/common/api.service";

export default {
  getShortUrls() {
    return ApiService.query("short-urls");
  },
  generateShortUrls(data) {
    return ApiService.post("short-url", data);
  },
  revertShortUrl(short_url_key) {
    return ApiService.query(`revert-short-url?short_url_key=${short_url_key}`);
  },
  deleteShortUrl(short_url_id) {
    return ApiService.delete(`short-url/${short_url_id}`);
  }
};
