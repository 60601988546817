import ApiService from "@/common/api.service";

export default {
  getActiveUsers(role) {
    return ApiService.query("users/search-all-active", { role });
  },
  getUsers({ page, pageSize, q, isActive, role, tags, tagMode, subjects, subjectMode }) {
    let blurrySearch = "";
    if (q) blurrySearch = `&q=${q}`;

    let roleQuery = "";
    if (role) roleQuery = `&role=${role}`;

    let tagQuery = "";
    if ((tags?.length || []) > 0) tagQuery = `&tag_ids=${tags.join(",")}`;

    let subjectQuery = "";
    (subjects || []).forEach(subject => {
      subjectQuery += `&subjects[]=${subject}`;
    });

    return ApiService.query(
      `users/search?&page=${page || 1}&page_size=${pageSize ||
        50}&is_active=${isActive}&tag_mode=${tagMode || "or"}&subject_mode=${subjectMode || "or"}${blurrySearch}${roleQuery}${tagQuery}${subjectQuery}`
    );
  },
  getChildUsersByParents({ page, q }) {
    let blurrySearch = "";
    if (q) blurrySearch = `&q=${q}`;

    return ApiService.query(
      `children?page_size=50&page=${page}${blurrySearch}`
    );
  },
  getStudentUsersByTeacher({ page, q }) {
    let blurrySearch = "";
    if (q) blurrySearch = `&q=${q}`;

    return ApiService.query(
      `teacher/students-for-user-list?page_size=50&page=${page}${blurrySearch}`
    );
  },
  getParents() {
    return ApiService.query("parents-for-profile");
  },
  toggleUserActive(id) {
    return ApiService.patch("users/toggle-active", { id });
  },
  toggleTeacherShoeBio(id) {
    return ApiService.patch("teacher/toggle-show-bio", {
      teacher_user_id: id
    });
  },
  toggleTeacherShoeRequest(id, data) {
    return ApiService.patch(`teacher/profile/${id}`, data);
  },
  togggleStudentRole(id, bindStatus) {
    return ApiService.post(`role/student/${Number(id)}`, { bind: bindStatus });
  },
  togggleTeacherRole(id, bindStatus) {
    return ApiService.post(`role/teacher/${Number(id)}`, { bind: bindStatus });
  },
  togggleStaffRole(id, bindStatus) {
    return ApiService.post(`role/staff/${Number(id)}`, { bind: bindStatus });
  },
  togggleParentRole(id, bindStatus) {
    return ApiService.post(`role/parent/${Number(id)}`, { bind: bindStatus });
  },
  togggleTestEditorRole(id, bindStatus) {
    return ApiService.post(`role/test-editor/${Number(id)}`, { bind: bindStatus });
  },
  destoryUser(id) {
    return ApiService.delete(`users/${id}`);
  },
  conflictOfMergingUsers(users) {
    return ApiService.post("users/conflict-data-on-merging", users);
  },
  mergeUsers(users) {
    return ApiService.post("users/merge", users);
  },
  checkIfEmailAlreadyTaken(email) {
    return ApiService.post("users/check-email", { email });
  }
};
