<template>
  <div>
    <el-tooltip
      effect="dark"
      :content="`您有 ${myTodoList.length} 個待辦`"
      placement="top"
    >
      <span
        class="contact-help todo-button text-success"
        @click="showTodo = !showTodo"
      >
        <el-badge
          v-show="myTodoList && myTodoList.length > 0"
          class="item"
          :value="myTodoList.length"
          :max="99"
        >
          <i class="fas fa-list-alt"></i>
        </el-badge>
        <b v-show="myTodoList && myTodoList.length < 1">
          <i class="fas fa-list-alt"></i>
        </b>
      </span>
    </el-tooltip>
    <div class="todo-box" v-show="showTodo">
      <div class="todo">
        <i class="bart" @mousedown="dragTopStart"></i>
        <i class="barb" @mousedown="dragBottomStart"></i>
        <i class="barr" @mousedown="dragRightStart"></i>
        <i class="barl" @mousedown="dragLeftStart"></i>
        <i class="bar" @mousedown="dragBarStart"></i>
        <div class="todo-cover">
          <div :class="{ left: true, 'left-hide': !isCollapse || isPhone }">
            <div>
              <div class="logo">
                <span v-show="isCollapse">待辦</span>
                <span v-show="!isCollapse && isPhone">
                  &nbsp;
                </span>
              </div>
              <div class="text-center mt-2 mb-2 pl-2 pr-2">
                <el-button
                  slot="title"
                  type="primary"
                  style="width: 100%;"
                  @click="addNewTodo"
                >
                  <i class="fas fa-calendar-plus"></i>
                  <span v-show="isCollapse && !isPhone">
                    新增待辦
                  </span>
                </el-button>
              </div>
              <div
                :class="{
                  item: true,
                  'text-center': !isCollapse && isPhone,
                  active: showTab === 'todoList'
                }"
                @click="showTab = 'todoList'"
              >
                <i class="fas fa-calendar-alt"></i>
                <span v-show="isCollapse && !isPhone">
                  待辦事項
                </span>
              </div>
              <!-- <div
                :class="{
                  item: true,
                  'text-center': !isCollapse && isPhone,
                  active: showTab === 'myList'
                }"
                @click="showTab = 'myList'"
              >
                <i class="fas fa-calendar-alt"></i>
                <span v-show="isCollapse && !isPhone">
                  我的待辦事項
                </span>
              </div> -->
              <div
                :class="{
                  item: true,
                  'text-center': !isCollapse && isPhone,
                  active: showTab === 'checkedList'
                }"
                @click="showTab = 'checkedList'"
              >
                <i class="fas fa-calendar-check"></i>
                <span v-show="isCollapse && !isPhone">
                  完成事項
                </span>
              </div>
            </div>
            <div v-if="!isPhone">
              <div
                :class="{ item: true, 'text-center': !isCollapse && isPhone }"
                @click="isCollapse = !isCollapse"
              >
                <i class="fas fa-list"></i>
                <span v-show="isCollapse">
                  收起列表
                </span>
              </div>
            </div>
          </div>
          <div class="right">
            <div class="header" draggable="true" @mousedown="dragMove">
              <span>
                <span v-show="showTab === 'newTodo'">
                  <i class="fas fa-calendar-plus"></i>
                  <span v-if="todoDetail.id > -1">
                    编辑待辦
                  </span>
                  <span v-else>
                    新增待辦
                  </span>
                </span>
                <span v-show="showTab === 'todoList'">
                  <i class="fas fa-calendar-alt"></i>
                  待辦事項
                </span>
                <span v-show="showTab === 'checkedList'">
                  <i class="fas fa-calendar-check"></i>
                  完成事項
                </span>
                <span v-show="showTab === 'myList'">
                  <i class="fas fa-calendar-check"></i>
                  我的待辦
                </span>
              </span>
              <div class="d-flex">
                <span class="close-i" @click="showTodo = false">
                  <i class="el-icon-minus"></i>
                </span>
                <!-- <span class="close-i" @click="showTodo = false">
                  <i class="el-icon-close"></i>
                </span> -->
              </div>
            </div>
            <div class="right-content">
              <div style="padding: 0.5rem;" v-show="showTab !== 'newTodo'">
                <el-radio-group class="mb-2" v-model="isMine" size="medium">
                  <el-radio-button :label="1">我的待辦</el-radio-button>
                  <el-radio-button :label="0">所有待辦</el-radio-button>
                </el-radio-group>
                <el-input
                  placeholder="請輸入關鍵字"
                  v-model="keyword"
                  style="width: 100%;"
                  @keyup.enter.native="getAll"
                  clearable
                >
                  <el-button
                    slot="append"
                    icon="el-icon-search"
                    @click="getAll"
                  ></el-button>
                </el-input>
                <hr class="mb-0" />
              </div>
              <!-- 新增待辦 -->
              <div v-show="showTab === 'newTodo'">
                <div class="right-content-content">
                  <el-form
                    ref="form"
                    label-width="40px"
                    :label-position="`right`"
                    :rules="formRules"
                    :model="todoDetail"
                  >
                    <el-form-item required prop="title">
                      <template slot="label">
                        <el-tooltip effect="dark" placement="top">
                          <div div slot="content">
                            待辦事項
                          </div>
                          <span>
                            <i class="fas fa-calendar-alt"></i>
                          </span>
                        </el-tooltip>
                      </template>
                      <el-input
                        v-model="todoDetail.title"
                        placeholder="輸入標題"
                      ></el-input>
                    </el-form-item>
                    <el-form-item :label="`Staff`">
                      <template slot="label">
                        <el-tooltip effect="dark" placement="top">
                          <div div slot="content">
                            可以把這件事安排給相關行政人員，<br />讓這件事同步出現在他們的待辦事項
                          </div>
                          <span>
                            <i class="fas fa-user-tie"></i>
                          </span>
                        </el-tooltip>
                      </template>
                      <el-select
                        v-model="todoDetail.responsible_members"
                        value-key="user_id"
                        multiple
                        filterable
                        default-first-option
                        placeholder="請選擇相關負責人員"
                        style="width: 100%;"
                      >
                        <el-option
                          v-for="user in admins"
                          :key="user.user_id"
                          :label="
                            userMethods.displayName(
                              user.first_name,
                              user.last_name
                            )
                          "
                          :value="user"
                        >
                        </el-option>
                      </el-select>
                    </el-form-item>
                    <el-form-item required prop="from_date">
                      <template slot="label">
                        <i class="fas fa-calendar-week"></i>
                      </template>
                      <div class="d-flex">
                        <el-date-picker
                          class="mr-2"
                          v-model="todoDetail.from_date"
                          value-format="yyyy-MM-dd"
                          type="date"
                          placeholder="Date"
                          style="width: 150px;"
                        >
                        </el-date-picker>
                        <el-time-select
                          class="mr-2"
                          v-model="todoDetail.from_time"
                          :picker-options="{
                            start: '00:00',
                            step: '00:15',
                            end: '23:45'
                          }"
                          placeholder="Time"
                          style="width: 150px;"
                          :disabled="todoDetail.is_all_day === 1"
                        >
                        </el-time-select>
                        <!-- <el-select
                          class="mr-2"
                          v-model="value"
                          placeholder="Time zone"
                        >
                          <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select> -->
                        <el-checkbox
                          v-model="todoDetail.is_all_day"
                          :true-label="1"
                          :false-label="0"
                        >
                          All day
                        </el-checkbox>
                      </div>
                    </el-form-item>
                    <el-form-item required>
                      <template slot="label">
                        <el-tooltip
                          effect="dark"
                          content="提醒通知"
                          placement="top"
                        >
                          <span>
                            <i class="fas fa-bell"></i>
                          </span>
                        </el-tooltip>
                      </template>
                      <div class="d-flex">
                        <el-input
                          v-model="todoDetail.reminder_number"
                          placeholder=""
                          type="number"
                          class="mr-2"
                          style="width: 150px;"
                          :disabled="todoDetail.need_reminder == 0"
                        ></el-input>
                        <el-select
                          class="mr-2"
                          v-model="todoDetail.reminder_unit"
                          placeholder="Minutes"
                          style="width: 150px;"
                          :disabled="todoDetail.need_reminder == 0"
                        >
                          <el-option :label="`Minute(s)`" :value="`M`">
                          </el-option>
                          <el-option :label="`Hour(s)`" :value="`H`">
                          </el-option>
                          <el-option :label="`Day(s)`" :value="`D`">
                          </el-option>
                        </el-select>
                        <el-checkbox
                          v-model="todoDetail.need_reminder"
                          :true-label="0"
                          :false-label="1"
                        >
                          不需要提醒
                        </el-checkbox>
                      </div>
                    </el-form-item>

                    <el-form-item>
                      <template slot="label">
                        <el-tooltip effect="dark" placement="top">
                          <div slot="content">
                            點進通知時會連結到這個頁面，<br />方便後續作業
                          </div>
                          <span>
                            <i class="fas fa-window-maximize"></i>
                          </span>
                        </el-tooltip>
                      </template>
                      <el-input
                        v-model="todoDetail.url"
                        placeholder=""
                        style="width: 100%;"
                      ></el-input>
                      <!-- <el-button
                        size="small"
                        type="primary"
                        @click="getPageData"
                      >
                        獲取當前頁面地址
                      </el-button> -->
                    </el-form-item>
                    <!-- <el-form-item :label="`Users`">
                      <template slot="label">
                        <el-tooltip effect="dark" content="" placement="top">
                          <div div slot="content">
                            輸入跟這件事相關的對象(老師、學生、家長)，<br />對方不會看到這個通知
                          </div>
                          <span>
                            <i class="fas fa-users"></i>
                          </span>
                        </el-tooltip>
                      </template>
                      <el-input
                        v-model="todoDetail.related_members"
                        disabled
                        placeholder=""
                        style="width: 100%;"
                      ></el-input>
                    </el-form-item> -->
                    <el-form-item :label="`Location`">
                      <template slot="label">
                        <el-tooltip effect="dark" placement="top">
                          <div div slot="content">
                            選填地址/地點，<br />也可以輸入線上會議連結
                          </div>
                          <span>
                            <i class="fas fa-map-marker-alt"></i>
                          </span>
                        </el-tooltip>
                      </template>
                      <el-input
                        v-model="todoDetail.location"
                        placeholder=""
                        style="width: 100%;"
                      ></el-input>
                    </el-form-item>
                    <el-form-item :label="`Text`">
                      <template slot="label">
                        <el-tooltip
                          effect="dark"
                          content="說明"
                          placement="top"
                        >
                          <span>
                            <i class="fas fa-align-left"></i>
                          </span>
                        </el-tooltip>
                      </template>
                      <Ckeditor5 v-model="todoDetail.notes"></Ckeditor5>
                    </el-form-item>
                  </el-form>
                </div>
              </div>
              <!-- 待辦事項 -->
              <div v-show="showTab === 'todoList'">
                <div v-if="todoList && todoList.length > 0">
                  <todoCard
                    v-for="(item, index) in todoList"
                    :key="item.id"
                    :todoActiveIndex="todoActiveIndex"
                    :item="item"
                    :index="index"
                    @setActiveIndex="
                      todoActiveIndex == index
                        ? (todoActiveIndex = null)
                        : (todoActiveIndex = index)
                    "
                    @setTodo="setTodo"
                    @removeTodo="removeTodo"
                    @editTodo="editTodo"
                  ></todoCard>
                </div>
                <div v-else>
                  <el-empty description="No Data"></el-empty>
                </div>
              </div>
              <!-- 完成事項 -->
              <div v-show="showTab === 'checkedList'">
                <div v-if="checkedList && checkedList.length > 0">
                  <todoCard
                    v-for="(item, index) in checkedList"
                    :key="item.id"
                    :todoActiveIndex="todoActiveIndex"
                    :item="item"
                    :index="index"
                    @setActiveIndex="
                      todoActiveIndex == index
                        ? (todoActiveIndex = null)
                        : (todoActiveIndex = index)
                    "
                    @setTodo="setTodo"
                    @removeTodo="removeTodo"
                    @editTodo="editTodo"
                  ></todoCard>
                </div>
                <div v-else>
                  <el-empty description="No Data"></el-empty>
                </div>
              </div>
              <!-- 待辦事項 -->
              <div v-show="showTab === 'myList'">
                <div v-if="myTodoList && myTodoList.length > 0">
                  <todoCard
                    v-for="(item, index) in myTodoList"
                    :key="item.id"
                    :todoActiveIndex="todoActiveIndex"
                    :item="item"
                    :index="index"
                    @setActiveIndex="
                      todoActiveIndex == index
                        ? (todoActiveIndex = null)
                        : (todoActiveIndex = index)
                    "
                    @setTodo="setTodo"
                    @removeTodo="removeTodo"
                    @editTodo="editTodo"
                  ></todoCard>
                </div>
                <div v-else>
                  <el-empty description="No Data"></el-empty>
                </div>
              </div>
            </div>
            <div class="right-footer" v-show="showTab === 'newTodo'">
              <el-button @click="showTodo = false" size="small">
                取消
              </el-button>
              <el-button type="primary" @click="createTodo" size="small">
                儲存
              </el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Ckeditor5 from "@/components/ckeditor/ckeditor5.vue";
import Todos from "@/apis/todos.js";
import { money, user, instant } from "@ivy-way/material";
import moment from "moment";
import formMixin from "@/mixins/form";
import todoCard from "@/components/todo/todoCard";
import $ from "jquery";

export default {
  components: { Ckeditor5, todoCard },

  mixins: [formMixin],

  props: [],
  data() {
    return {
      showTodo: false,
      isCollapse: true,
      isEdit: false,
      isMine: 1,
      showTab: "todoList",
      todoDetail: {
        title: null,
        is_all_day: 1,
        timezone: "Asia/Taipei",
        from_date: null,
        from_time: "",
        to_date: "",
        to_time: "",
        need_reminder: 0,
        reminder_number: 0,
        reminder_unit: "M",
        url: "",
        location: "",
        notes: "",
        related_members: [],
        responsible_members: []
      },
      todoActiveIndex: null,
      todoList: [],
      myTodoList: [],
      checkedList: [],
      admins: [],
      keyword: null
    };
  },
  computed: {
    ...mapState("user", ["profile"]),
    userMethods() {
      return user;
    },
    instant() {
      return instant;
    },
    moment() {
      return moment;
    },
    isPhone() {
      let isPhone = false;
      if (document.body.clientWidth <= 768) {
        isPhone = true;
      }
      return isPhone;
    },
    formRules() {
      return {
        title: [
          {
            required: true,
            message: this.$t("landingPage.contact.message.name_require"),
            trigger: "blur"
          }
        ],
        from_date: [
          {
            required: true,
            message: this.$t("register.please_input_email"),
            trigger: "blur"
          }
        ]
      };
    }
  },
  watch: {
    isMine() {
      this.getTodo();
      this.getChecked();
    },
    profile() {
      this.getTodo();
      this.getMyTodo();
      this.getChecked();
    },
    showTab() {
      this.todoActiveIndex = null;
    }
  },

  async mounted() {
    const res = await Todos.getAdmins();
    // this.admins = res.users.data;
    // console.log(this.admins);
    res.users.data.forEach(user => {
      this.admins.push({
        full_name: "",
        user_id: user.id,
        first_name: user.first_name,
        last_name: user.last_name
      });
    });
    this.getTodo();
    this.getMyTodo();
    this.getChecked();
    if (this.showTab == "newTodo") {
      this.todoDetail.url = window.location.href;
      this.todoDetail.from_date = moment(new Date()).format("yyyy-MM-DD");
      this.setMe();
    }
  },

  methods: {
    setMe() {
      this.admins.forEach(admin => {
        if (admin.user_id === this.profile.id) {
          this.todoDetail.responsible_members.push(admin);
        }
      });
    },
    getAll() {
      this.getTodo();
      this.getChecked();
    },
    addNewTodo() {
      this.todoDetail = {
        title: null,
        is_all_day: 1,
        timezone: "Asia/Taipei",
        from_date: null,
        from_time: "",
        to_date: "",
        to_time: "",
        need_reminder: 0,
        reminder_number: 0,
        reminder_unit: "M",
        url: "",
        location: "",
        notes: "",
        related_members: [],
        responsible_members: []
      };
      this.showTab = "newTodo";
      this.todoDetail.from_date = moment(new Date()).format("yyyy-MM-DD");
      this.todoDetail.url = window.location.href;
      this.setMe();
    },
    getPageData() {
      this.todoDetail.url = window.location.href;
    },
    removeTodo(id) {
      this.$confirm("確認刪除這條記錄？", "提示", {
        confirmButtonText: "確定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(async () => {
        await Todos.deleteTodo(id);
        this.$message({
          type: "success",
          message: "已刪除!"
        });
        this.getTodo();
        this.getMyTodo();
        this.getChecked();
      });
    },
    editTodo(OldItem) {
      let item = JSON.parse(JSON.stringify(OldItem));
      this.isEdit = true;
      this.showTab = "newTodo";
      if (item.responsible_members.length > 0) {
        let arr = [];
        item.responsible_members.forEach(user => {
          if (user.user_id) {
            arr.push({
              full_name: "",
              user_id: user.user_id,
              first_name: user.user.first_name,
              last_name: user.user.last_name
            });
          } else {
            arr.push(user.full_name);
          }
        });
        item.responsible_members = arr;
      }
      this.todoDetail = { ...item };
    },
    async setTodo(id, value) {
      console.log(id, value);
      await Todos.editTodo(id, {
        status: value
      });
      this.getTodo();
      this.getMyTodo();
      this.getChecked();
    },
    async getTodo() {
      if (this.profile.id) {
        let user_id = null;
        if (this.isMine) {
          user_id = this.profile.id;
        }
        const res = await Todos.getTodo({
          query_string: this.keyword ? this.keyword : null,
          user_id: user_id,
          status: 0
        });
        this.todoList = res.todos;
      }
    },
    async getMyTodo() {
      if (this.profile.id) {
        const res = await Todos.getTodo({
          query_string: this.keyword ? this.keyword : null,
          user_id: this.profile.id,
          status: 0
        });
        this.myTodoList = res.todos;
      }
    },
    async getChecked() {
      if (this.profile.id) {
        let user_id = null;
        if (this.isMine) {
          user_id = this.profile.id;
        }
        const res = await Todos.getTodo({
          query_string: this.keyword ? this.keyword : null,
          user_id: user_id,
          status: 1
        });
        this.checkedList = res.todos;
      }
    },
    async createTodo() {
      const valid = await this.validateForm(this.$refs["form"]);
      if (!valid) {
        return;
      }
      if (this.todoDetail.id > 0) {
        await Todos.editTodo(this.todoDetail.id, this.todoDetail);
      } else {
        await Todos.createTodo(this.todoDetail);
      }
      this.getTodo();
      this.getMyTodo();
      this.getChecked();
      this.showTab = "todoList";
    },
    dragLeftStart(event) {
      event = event || window.event;
      var disX = $(".todo-box .todo").offset().left;
      var drafw = $(".todo-box .todo").width();
      if (event.preventDefault) {
        event.preventDefault();
      } else {
        event.returnValue = false;
      }
      document.onmousemove = function(event) {
        event = event || window.event;
        var mouseX = event.pageX;
        if (mouseX < 0) mouseX = 0;
        $(".todo-box .todo").css({
          left: mouseX + "px",
          width: disX - mouseX - 4 + drafw + "px"
        });
      };
      document.onmouseup = function() {
        document.mousedown = null;
        document.onmousemove = null;
      };
    },
    dragRightStart(event) {
      event = event || window.event;
      var disX = $(".todo-box .todo").offset().left;
      var drafw = $(".todo-box .todo").width();
      if (event.preventDefault) {
        event.preventDefault();
      } else {
        event.returnValue = false;
      }
      document.onmousemove = function(event) {
        event = event || window.event;
        var mouseX = event.pageX - disX,
          maxX = document.documentElement.clientWidth - disX - 2;
        if (mouseX > maxX) mouseX = maxX;
        $(".todo-box .todo").css({ width: mouseX + "px" });
      };
      document.onmouseup = function() {
        document.mousedown = null;
        document.onmousemove = null;
      };
    },
    dragTopStart(event) {
      event = event || window.event;
      var disY = $(".todo-box .todo").offset().top;
      var drafH = $(".todo-box .todo").height();
      if (event.preventDefault) {
        event.preventDefault();
      } else {
        event.returnValue = false;
      }
      document.onmousemove = function(event) {
        event = event || window.event;
        var mouseY = event.pageY;
        var range = disY - mouseY - 4;
        if (mouseY + 4 > 0) {
          $(".todo-box .todo").css({
            top: event.clientY + "px",
            height: range + drafH + "px"
          });
        }
      };
      document.onmouseup = function() {
        document.mousedown = null;
        document.onmousemove = null;
      };
    },
    dragBottomStart(event) {
      event = event || window.event;
      var disY = $(".todo-box .todo").offset().top;
      if (event.preventDefault) {
        event.preventDefault();
      } else {
        event.returnValue = false;
      }
      document.onmousemove = function(event) {
        event = event || window.event;
        var mouseY = event.pageY - disY,
          maxY = document.documentElement.clientHeight - disY - 2;
        // if (mouseY > maxY) mouseY = maxY;
        $(".todo-box .todo").css({ height: mouseY + "px" });
      };
      document.onmouseup = function() {
        document.mousedown = null;
        document.onmousemove = null;
      };
    },
    dragBarStart(event) {
      event = event || window.event;
      var disX = $(".todo-box .todo").offset().left;
      var disY = $(".todo-box .todo").offset().top;
      if (event.preventDefault) {
        event.preventDefault();
      } else {
        event.returnValue = false;
      }
      document.onmousemove = function(event) {
        event = event || window.event;
        var mouseX = event.pageX - disX + 14,
          mouseY = event.pageY - disY + 14,
          maxX = document.documentElement.clientWidth - disX - 2,
          maxY = document.documentElement.clientHeight - disY - 2;
        // if (mouseX > maxX) mouseX = maxX;
        // if (mouseY > maxY) mouseY = maxY;
        $(".todo-box .todo").css({
          width: mouseX + "px",
          height: mouseY + "px"
        });
      };
      document.onmouseup = function() {
        document.mousedown = null;
        document.onmousemove = null;
      };
    },
    dragMove(event) {
      event = event || window.event;
      var disX = event.pageX - $(".todo-box .todo").offset().left;
      var disY = event.pageY - $(".todo-box .todo").offset().top;
      console.log(disX, disY);
      if (event.preventDefault) {
        event.preventDefault();
      } else {
        event.returnValue = false;
      }
      document.onmousemove = function(event) {
        event = event || window.event;
        var mouseX = event.clientX - disX;
        var mouseY = event.clientY - disY;

        console.log(event.pageX, event.pageY);
        var maxX =
            document.documentElement.clientWidth -
            $(".todo-box .todo").outerWidth(),
          maxY =
            document.documentElement.clientHeight -
            $(".todo-box .todo").outerHeight();
        if (mouseX < 0) {
          mouseX = 0;
        } else if (mouseX > maxX) {
          mouseX = maxX;
        }
        if (mouseY < 0) {
          mouseY = 0;
        } else if (mouseY > maxY) {
          mouseY = maxY;
        }
        $(".todo-box .todo").css({ left: mouseX + "px", top: mouseY + "px" });
      };
      document.onmouseup = function() {
        document.mousedown = null;
        document.onmousemove = null;
      };
    }
  }
};
</script>

<style scoped>
::v-deep .el-badge__content {
  line-height: 16px;
}
::v-deep .el-badge__content.is-fixed {
  right: 25px;
  top: 5px;
}
.todo > i {
  position: absolute;
  z-index: 2;
}
.todo i.bart,
.todo i.barb {
  height: 4px;
  left: -1px;
  right: -1px;
  cursor: n-resize;
}
.todo i.bart {
  top: -1px;
}
.todo i.barb {
  bottom: -1px;
}
.todo i.barr,
.todo i.barl {
  width: 8px;
  top: -1px;
  bottom: -1px;
  cursor: e-resize;
  /*background:#ffc;
	*/
}
.todo i.barl {
  left: -1px;
}
.todo i.barr {
  right: -1px;
}
.todo i.bar {
  width: 14px;
  height: 14px;
  cursor: nw-resize;
  bottom: -1px;
  right: -1px;
}
.todo-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1002;
  background-color: rgba(0, 0, 0, 0.5);
}
.todo {
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  background-color: white;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.todo-cover {
  display: flex;
  width: 100%;
  height: 100%;
}

.left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  border-right: 1px solid #ebeef5;
  transition: width 0.3s;
  width: 200px;
  user-select: none;
  height: 100%;
  overflow: auto;
}
.left.left-hide {
  width: 60px;
  text-align: center;
}
.logo {
  color: var(--themeColor);
  text-align: center;
  font-size: 1.3rem;
  font-weight: 700;
  margin: 1rem 0;
  padding: 0 1rem;
}

.item {
  height: 50px;
  line-height: 50px;
  padding: 0 1rem;
  cursor: pointer;
  font-size: 0.9rem;
}
.item:hover {
  opacity: 0.8;
  color: var(--themeColor);
  /* font-size: 0.8rem; */
}
.item.active {
  background-color: var(--themeColor);
  color: white;
}
.right {
  flex: 1;
  /* min-width: 500px; */
  background-color: #e3e3e3;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.header {
  height: 50px;
  line-height: 50px;
  background-color: white;
  cursor: move;
  text-align: right;
  padding-left: 1rem;
  font-weight: 700;
  border-bottom: 1px solid #ebeef5;
  display: flex;
  justify-content: space-between;
}
.close-i {
  cursor: pointer;
  display: block;
  height: 100%;
  padding: 0 1rem;
}
.close-i:hover {
  opacity: 0.8;
  background-color: #e3e3e3;
}
.right-content {
  width: 100%;
  /* padding: 1rem; */
  /* height: 650px; */
  overflow: auto;
  flex: 1;
}
.right-content-content {
  /* height: 600px; */
  overflow: auto;
  padding: 1rem;
}
.right-footer {
  padding-right: 1rem;
  text-align: right;
  height: 50px;
  line-height: 50px;
  background-color: white;
  font-weight: 700;
  /* border-top: 1px solid #ebeef5; */
}
</style>
<style scoped>
.todo-item {
  padding: 1rem;
  margin: 0.5rem;
  border-radius: 0.5rem;
  border: 2px solid #ebeef5;
  cursor: pointer;
  background-color: white;
}
.todo-item:hover,
.todo-item-active {
  border: 2px solid var(--themeColor);
}
.todo-item-col {
  display: flex;
  align-items: center;
}
::v-deep .todo-item .el-form-item {
  margin: 0;
}
.todo-date {
  width: 120px;
  color: #989898;
  display: flex;
  align-items: center;
}
.todo-title {
  flex: 1;
  overflow: hidden;
  margin-right: 1rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.todo-users {
  width: 50px;
  font-weight: 500;
}
.todo-actions {
  width: 40px;
}
.todo-check {
  width: 30px;
}
::v-deep .todo-item-active .el-form-item .el-form-item__content {
  word-wrap: break-word;
  min-height: 40px;
  line-height: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items:center; */
}
::v-deep .el-form-item__content * {
  margin: 0;
}
@media screen and (max-width: 768px) {
  .todo {
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}
</style>
<style scoped>
.todo-button {
  bottom: 200px;
}
</style>
