import flashcards from "@/apis/flashcards";
import myVocabLists from "@/apis/myVocabLists";

const state = {
  currentBooks: {},
  currentSelectedVocabs: [],
  currentSelectList: []
};

const getters = {
  getCurrentBooks(state) {
    return state.currentBooks;
  },
  getCurrentSelectedList(state) {
    return state.currentSelectList;
  },
  getCurrentSelectedVocabs(state) {
    return state.currentSelectedVocabs;
  }
};

const mutations = {
  setCurrentBooks(state, data) {
    state.currentBooks = data;
  },
  setCurrentSelectedList(state, data) {
    state.currentSelectList = data;
  },
  setCurrentSelectedVocabs(state, data) {
    state.currentSelectedVocabs = data;
  }
};

const actions = {
  async getBooks({ commit }) {
    const data = await flashcards.getBooks();
    commit("setCurrentBooks", data);
  },
  async getVocabs({ commit }, list) {
    const data = await flashcards.getVocabs(list);
    commit("setCurrentSelectedList", list);
    commit("setCurrentSelectedVocabs", data);
  },
  async getMyVocabs({ commit }, list) {
    const data = await myVocabLists.getCollectVocab(list);
    commit("setCurrentSelectedList", list);
    commit("setCurrentSelectedVocabs", data.vocabs);
  },
  /*eslint-disable no-unused-vars */
  recordVocab({ commit }, data) {
    /*eslint-enable */
    flashcards.recordVocab(data);
  }
  // updateVocab({ commit }, data) {

  // }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
