import ApiService from "@/common/api.service";

export default {
  fetchCounselingProfile(studentUserId) {
    return ApiService.get(`counseling-profile/${studentUserId}`);
  },
  saveCounselingProfile(studentUserId, data) {
    return ApiService.post(`counseling-profile/${studentUserId}`, data);
  },
  syncCounselingProfile(studentUserId, thirdServiceType) {
    return ApiService.post(`counseling-profile/sync/${studentUserId}`, {
      third_service_type: thirdServiceType
    });
  },
  fetchHighSchoolsOfCounselingProfile(searchText, filterState) {
    return ApiService.query("counseling-profile/selector", {
      selector_title: "high_school_selector",
      keyword: searchText,
      state: filterState
    });
  },
  fetchCollegesOfCounselingProfile(searchText) {
    return ApiService.query("counseling-profile/selector", {
      selector_title: "college_selector",
      keyword: searchText
    });
  },
  fetchOptionsOfCounselingProfile(selector_title, service_types) {
    return ApiService.query("counseling-profile/selector", {
      selector_title,
      service_types: service_types.includes("all") ? null : service_types
    });
  },
  fetchCountriesOfFilterHighSchool() {
    return ApiService.query("counseling-profile/country-selector");
  }
};
