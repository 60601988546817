import ApiService from "@/common/api.service";

export default {
  getStories(data) {
    // params: { page: page } === params: { page }
    return ApiService.query("stories", data);
  },
  addStory(data) {
    return ApiService.post("stories", data);
  },
  updateStory(id, data) {
    return ApiService.patch("stories/" + id, data);
  },
  removeStory(id) {
    return ApiService.delete("stories/" + id);
  }
};
