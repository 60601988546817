import instructorsApi from "@/apis/instructors";

const state = {
  instructors: {},
  instructorsFaq: [],
  instructorsForBio: {}
};

const getters = {
  getInstructors(state) {
    return state.instructors;
  },
  getInstructorsFaq(state) {
    return state.instructorsFaq;
  },
  getInstructorsForBio(state) {
    return state.instructorsForBio;
  }
};

const mutations = {
  setInstructors(state, data) {
    state.instructors = data;
  },
  setInstructorsFaq(state, data) {
    state.instructorsFaq = data;
  },
  setInstructorsForBio(state, data) {
    state.instructorsForBio = data;
  }
};

const actions = {
  async getInstructors({ commit }) {
    const data = await instructorsApi.getInstructors();
    commit("setInstructors", data);
  },
  async getInstructorsFaq({ commit }) {
    const data = await instructorsApi.getInstructorsFaq();
    commit("setInstructorsFaq", data);
  },
  async getInstructorsForBio({ commit }) {
    const data = await instructorsApi.getInstructorsForBio();
    commit("setInstructorsForBio", data);
  },
  /*eslint-disable no-unused-vars */
  async postInstructorsFaq({ commit }, payload) {
    /*eslint-enable */
    await instructorsApi.postInstructorsFaq(payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
