import Vue from "vue";
import VueRouter from "vue-router";

import JwtService from "@/common/jwt.service";
import store from "@/store";
import role from "@/mixins/role";
import enrollmentApi from "@/apis/enrollment";
import { Message, MessageBox } from "element-ui";

Vue.use(VueRouter);

const routes = [
  {
    path: "/online-sat",
    name: "SatMocks",
    component: () => import("@/views/SatMocks/Index.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "privateStudents" }]
    }
  },
  {
    path: "/",
    name: "LandingPage",
    props: true,
    component: () => import("@/views/LandingPage.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    props: true,
    component: () => import("@/views/LandingPage.vue")
  },
  {
    path: "/#/:id",
    name: "LandingPageContact",
    props: true,
    component: () => import("@/views/LandingPage.vue")
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/register",
    name: "Register",
    component: () => import("@/views/Login.vue")
  },
  {
    path: "/quick-register",
    name: "QuickRegister",
    component: () => import("@/views/quickRegister/Index.vue")
  },
  {
    path: "/password/Forgot",
    name: "ForgetPassword",
    component: () => import("@/views/ForgetPassword.vue")
  },
  {
    path: "/password/set",
    name: "SetPassword",
    component: () => import("@/views/SetPassword.vue")
  },
  {
    path: "/password/reset",
    name: "ResetPassword",
    component: () => import("@/views/ResetPassword.vue")
  },
  {
    path: "/line/bind",
    name: "BindLineLogin",
    component: () => import("@/views/line/BindLineLogin.vue")
  },
  {
    path: "/line/login",
    name: "LineLogin",
    component: () => import("@/views/line/Login.vue")
  },
  {
    path: "/schools",
    name: "SchoolsList",
    component: () => import("@/views/schools/Index.vue")
  },
  {
    path: "/schools/postcreator",
    name: "postCreator",
    component: () => import("@/views/postCreator/Index.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "postCreator" }]
    }
  },
  {
    path: "/schools/edit",
    name: "SchoolsEdit",
    component: () => import("@/views/schools/EditSchools.vue")
  },
  {
    path: "/schools/:schoolId/:type",
    name: "School",
    component: () => import("@/views/schools/School.vue")
  },
  {
    path: "/schools/:schoolId",
    name: "SchoolOverview",
    component: () => import("@/views/schools/School.vue")
  },
  // ===== 會員中心（？） =====
  {
    path: "/my-courses",
    name: "StudentCourses",
    component: () => import("@/views/studentCourses/Index.vue")
  },
  {
    path: "/my-classes",
    name: "myClasses",
    component: () => import("@/views/private/Students.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "myClasses" }]
    }
  },
  {
    path: "/all-students/filter/:roleType/:userId",
    name: "filterAllStudentLessonsByUser",
    component: () => import("@/views/private/Students.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "privateStudents" }]
    }
  },
  {
    path: "/home",
    component: () => import("@/views/home/Index.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "",
        name: "Home",
        component: () => import("@/views/home/Dashboard.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "*",
        redirect: { name: "Home" }
      }
    ]
  },
  {
    path: "/enrollment",
    component: () => import("@/views/home/enrollment/Index.vue"),
    children: [
      {
        path: "",
        name: "EnrollmentList",
        component: () => import("@/views/home/enrollment/List.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "EnrollmentList" }]
        }
      },
      {
        path: ":id",
        props: true,
        name: "EnrollmentDetail",
        component: () => import("@/views/home/enrollment/Detail.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "EnrollmentList" }]
        }
      }
    ]
  },
  {
    path: "/link-student",
    name: "LinkStudent",
    component: () => import("@/views/enrollmentNew/LinkStudent.vue")
  },
  // 分班管理
  {
    path: "/placement",
    name: "Arrange",
    component: () => import("@/views/home/enrollment/Arrange.vue")
  },
  {
    path: "/session-class/:courseId/log/:teachingLogId",
    name: "sessionClassesTeachingLogDetail",
    component: () => import("@/views/lessonLog/LessonLog.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "myClasses" }]
    }
  },
  {
    path: "/lessonLogDetail/:lessonLogId",
    name: "LessonLogDetail",
    component: () => import("@/views/lessonLog/ViewLessonLogById.vue")
  },
  {
    path: "/session-class/:courseId/log/:lessonLogId/student/:studentId",
    name: "sessionClassesLessonLogDetail",
    component: () => import("@/views/lessonLog/LessonLog.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "myClasses" }]
    }
  },
  {
    path: "/session-class/:id/createLog",
    name: "sessionClassesCreateLog",
    component: () => import("@/views/lessonLog/LessonLogForm.vue"),
    children: [],
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "myClasses" }]
    }
  },
  {
    path: "/session-class/:id/editLog/:lessonLogId",
    name: "sessionClassesEditLog",
    component: () => import("@/views/lessonLog/LessonLogForm.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "myClasses" }]
    }
  },
  {
    path: "/progressReportStatuses",
    name: "reportStatuses",
    component: () => import("@/views/report/Management.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "reportStatuses" }]
    }
  },
  {
    path: "/session-class/:classId/progress-report/create",
    name: "sessionClassesCreateReport",
    component: () => import("@/views/report/Form.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "myClasses" }]
    }
  },
  {
    path: "/session-class/:classId/progress-report/:reportId/edit",
    name: "sessionClassesEditReport",
    component: () => import("@/views/report/Form.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "myClasses" }]
    }
  },
  {
    path: "/session-class/:classId/progress-report/:reportId",
    name: "sessionClassesReport",
    component: () => import("@/views/report/Report.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "myClasses" }]
    }
  },
  {
    path: "/students/attendances",
    name: "studentsAttendances",
    component: () => import("@/views/attendance/Index.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "studentsAttendances" }]
    }
  },
  {
    path: "/applications",
    name: "applications",
    component: () => import("@/views/applications/Index.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "applications" }]
    }
  },
  // 家教課管理
  {
    path: "/private",
    component: () => import("@/views/home/enrollment/Index.vue"),
    children: [
      {
        path: "private-lessons",
        name: "privateLessons",
        component: () => import("@/views/private/Index.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "privateLessons" }]
        }
      },
      {
        path: "private-lessons/filter/:roleType/:userId",
        name: "filterPrivateLessonsByUser",
        component: () => import("@/views/private/Index.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "privateLessons" }]
        }
      },
      // 家教課配對邀請
      {
        path: "private-lessons/invitation/:id",
        name: "privateLessonInvitation",
        component: () => import("@/views/private/Invitation.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [
            { name: "myClasses" },
            { name: "privateLessonInvitation" }
          ]
        }
      },
      // 家教課設定連結老師
      {
        path: ":privateType/link-teacher/:class_id",
        name: "privateLessonLinkTeacher",
        component: () => import("@/views/private/Form/Form.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ":privateType/:courseId/log/:lessonLogId/student/:studentId",
        name: "privateLessonsLessonLogDetail",
        component: () => import("@/views/lessonLog/LessonLog.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ":privateType/:courseId/log/:teachingLogId",
        name: "privateLessonsTeachingLogDetail",
        component: () => import("@/views/lessonLog/LessonLog.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ":privateType/:id/createLog",
        name: "privateClassesCreateLog",
        component: () => import("@/views/lessonLog/LessonLogForm.vue"),
        children: [],
        meta: {
          requiresAuth: true
        }
      },
      {
        path:
          "private-lessons/invitation-reply/:reply_status/:class_teacher_invitation_id(.*)",
        name: "privateLessonInvitationReply",
        component: () => import("@/views/private/InvitationReply.vue"),
        meta: {
          requiresAuth: false
        }
      },
      {
        path: "private-lessons/:id/detail",
        name: "privateClassesDetail",
        component: () => import("@/views/private/PrivateDetail.vue"),
        children: [],
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "myClasses" }]
        }
      },
      {
        path: "counseling/:id/detail",
        name: "counselingClassesDetail",
        component: () => import("@/views/private/CounselingDetail.vue"),
        children: [],
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "myClasses" }]
        }
      },
      {
        path: ":privateType/:id/editLog/:lessonLogId",
        name: "privateClassesEditLog",
        component: () => import("@/views/lessonLog/LessonLogForm.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ":privateType/:classId/progress-report/create",
        name: "privateClassesCreateReport",
        component: () => import("@/views/report/Form.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ":privateType/:classId/progress-report/:reportId/edit",
        name: "privateClassesEditReport",
        component: () => import("@/views/report/Form.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ":privateType/:classId/progress-report/:reportId",
        name: "privateClassesReport",
        component: () => import("@/views/report/Report.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ":privateType/:class_id/edit",
        name: "privateClassesEdit",
        component: () => import("@/views/private/Form/Form.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: ":privateType/create",
        name: "privateCreate",
        component: () => import("@/views/private/Form/Form.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "counseling",
        name: "counseling",
        component: () => import("@/views/private/Index.vue"),
        async beforeEnter(to, from, next) {
          if (
            role.methods.isRoleOnlyStudent() ||
            (role.methods.isRoleStudent() &&
              role.methods.isRoleParent() &&
              role.methods.parentIsStudentSelf())
          ) {
            const {
              session_classes: { data: sessionClasses }
            } = await enrollmentApi.fetchPrivateLessons({
              course_session_type: "counseling"
            });
            if (sessionClasses.length > 0) {
              next({
                name: "counselingClassesDetail",
                params: { id: sessionClasses[0].id }
              });
            } else {
              if (store.getters["user/student"].counseling_schools_type) {
                next({ name: "counselingUserPortal" });
              } else {
                next({
                  name: "SetCounselingSchoolsType",
                  params: {
                    username: store.getters["user/getProfile"].username
                  }
                });
              }
            }
          } else {
            next();
          }
        },
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "counseling" }]
        }
      },
      {
        path: "counseling/filter/:roleType/:userId",
        name: "filterCounselingByUser",
        component: () => import("@/views/private/Index.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "counseling" }]
        }
      },
      {
        path: "setCounselingSchoolsType/student/:username",
        name: "SetCounselingSchoolsType",
        component: () =>
          import("@/views/private/CounselingUser/SetCounselingSchoolsType.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "counseling/detail",
        name: "redirectCounselingUserPortal",
        async beforeEnter(to, from, next) {
          next({ name: "counselingUserPortal" });
        }
      },
      {
        path: "counseling/portal",
        name: "counselingUserPortal",
        component: () => import("@/views/private/CounselingUser/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "counseling/student/:username",
        name: "counselingUser",
        component: () => import("@/views/private/CounselingUser/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "counseling/student/:username/colleges",
        name: "counselingUserSchool",
        component: () => import("@/views/private/Counseling/School/School.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path:
          "counseling/student/:username/collected/colleges/:collectedCollegeId",
        name: "userCollectedSchool",
        component: () => import("@/views/private/Counseling/School/Detail.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path:
          "counseling/student/:username/collected/colleges/:collectedCollegeId/edit",
        name: "editCounselingUserSchool",
        component: () => import("@/views/private/Counseling/School/Edit.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "counseling/student/:username/applicationForms",
        name: "counselingUserApplication",
        component: () =>
          import("@/views/private/Counseling/ThirdApp/ThirdApp.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "counseling/student/:username/applicationForms/:thirdAppType",
        name: "counselingUserThirdAppDetail",
        component: () =>
          import("@/views/private/Counseling/ThirdApp/Detail.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "counseling/student/:username/essays",
        name: "counselingUserEssays",
        component: () => import("@/views/private/Counseling/Essays.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "counseling/student/:username/tasks",
        name: "counselingUserTasks",
        component: () => import("@/views/private/Counseling/Tasks/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "counseling/student/:username/subtasks/:subtaskId",
        name: "counselingUserSubtaskView",
        component: () =>
          import("@/views/private/Counseling/Tasks/SubtaskView.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "counseling/student/:username/subtasks/edit/:subtaskId",
        name: "editCounselingUserSubtasks",
        component: () =>
          import("@/views/private/Counseling/Tasks/EditSubtask.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "counseling/student/:username/subtasks/create",
        name: "createCounselingUserSubtasks",
        component: () =>
          import("@/views/private/Counseling/Tasks/CreateSubtask.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "counseling/:id/info",
        name: "counselingInfo",
        component: () => import("@/views/private/Counseling/Info.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "counseling" }]
        }
      },
      {
        path: "counseling/:id/colleges",
        name: "counselingSchool",
        component: () => import("@/views/private/Counseling/School/School.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "counseling" }]
        }
      },
      {
        path: "counseling/:counselingId/colleges/add",
        name: "addCounselingSchool",
        component: () => import("@/views/schools/Index.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "counseling" }]
        }
      },
      {
        path: "counseling/:counselingId/:thirdAppType/colleges/add",
        name: "addCounselingThirdAppSchool",
        component: () => import("@/views/schools/Index.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "counseling" }]
        }
      },
      {
        path: "counseling/:id/applicationForms",
        name: "counselingThirdApp",
        component: () =>
          import("@/views/private/Counseling/ThirdApp/ThirdApp.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "counseling" }]
        }
      },
      {
        path: "counseling/:id/applicationForms/:thirdAppType",
        name: "counselingThirdAppDetail",
        component: () =>
          import("@/views/private/Counseling/ThirdApp/Detail.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "counseling" }]
        }
      },
      {
        path: "counseling/:id/meetingLogs",
        name: "counselingMeetingLogs",
        component: () => import("@/views/private/Counseling/MeetingLogs.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "counseling" }]
        }
      },
      {
        path: "counseling/:id/reports",
        name: "counselingMeetingReports",
        component: () => import("@/views/private/Counseling/Reports.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "counseling" }]
        }
      },
      {
        path: "counseling/:id/essays",
        name: "counselingEssays",
        component: () => import("@/views/private/Counseling/Essays.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "counseling" }]
        }
      },
      {
        path: "counseling/:id/tasks",
        name: "counselingTasks",
        component: () => import("@/views/private/Counseling/Tasks/Index.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "counseling" }]
        }
      },
      {
        path: "counseling/:id/subtasks/:subtaskId",
        name: "counselingSubtaskView",
        component: () =>
          import("@/views/private/Counseling/Tasks/SubtaskView.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "counseling" }]
        }
      },
      {
        path: "counseling/:id/subtasks/edit/:subtaskId",
        name: "editCounselingSubtasks",
        component: () =>
          import("@/views/private/Counseling/Tasks/EditSubtask.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "counseling" }]
        }
      },
      {
        path: "counseling/:id/subtasks/create",
        name: "createCounselingSubtasks",
        component: () =>
          import("@/views/private/Counseling/Tasks/CreateSubtask.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "counseling" }]
        }
      }
    ]
  },
  {
    path: "/counseling/:counselingId/collected/colleges/:collectedCollegeId",
    name: "counselingCollectedColleges",
    component: () => import("@/views/private/Counseling/School/Detail.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path:
      "/counseling/:counselingId/collected/colleges/:collectedCollegeId/edit",
    name: "editCounselingCollectedColleges",
    component: () => import("@/views/private/Counseling/School/Edit.vue"),
    meta: {
      requiresAuth: true
    }
  },
  //学生获取考卷
  {
    path: "/get-tests",
    name: "GetTests",
    component: () => import("@/views/getTests/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/get-folder",
    name: "GetUnitExam",
    component: () => import("@/views/getUnitExam/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  //学生考试管理
  {
    path: "/my-tests",
    name: "MyTests",
    component: () => import("@/views/myTests/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/create-class/how-to-billing",
    name: "howTobilling",
    component: () =>
      import("@/views/sessionClasses/components/CheckHowToBilling.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/bill",
    name: "Bill",
    component: () => import("@/views/bill/Index.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Bill" }]
    }
  },
  {
    path: "/receivable",
    name: "Receivable",
    component: () => import("@/views/bill/Index.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Bill" }]
    }
  },
  {
    path: "/bill/create",
    name: "createBill",
    component: () => import("@/views/bill/BillForm.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Bill" }, { name: "createBill" }]
    }
  },
  {
    path: "/receivable/create",
    name: "createReceivable",
    component: () => import("@/views/bill/ReceivableForm.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Bill" }]
    }
  },
  {
    path: "/receivable/create/enrollment/:id",
    name: "createReceivableWithEnrollment",
    component: () => import("@/views/bill/ReceivableForm.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Bill" }]
    }
  },
  {
    path: "/bill/create/enrollment/:id",
    name: "createBillWithEnrollment",
    component: () => import("@/views/bill/BillForm.vue")
  },
  {
    path: "/receivable/edit/:receivableId",
    name: "editReceivable",
    component: () => import("@/views/bill/ReceivableForm.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Bill" }]
    }
  },
  {
    path: "/bill/:billId",
    name: "billView",
    component: () => import("@/views/bill/BillDetail.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Bill" }]
    }
  },
  {
    path: "/receivable/:receivableId",
    name: "ReceivableView",
    component: () => import("@/views/bill/ReceivableDetail.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Bill" }]
    }
  },
  {
    path: "/bill/create/lessonLog/:enrolledId/:classId/:lessonLogId",
    name: "createBillWithLessonLog",
    component: () => import("@/views/bill/BillForm.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Bill" }]
    }
  },
  {
    path: "/bill/create/student/:studentId",
    name: "createBillWithStudent",
    component: () => import("@/views/bill/BillForm.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Bill" }]
    }
  },
  {
    path: "/bill/reset/:billId",
    name: "createBillWithOtherBill",
    component: () => import("@/views/bill/BillForm.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Bill" }]
    }
  },
  {
    path: "/salary",
    name: "Salary",
    component: () => import("@/views/salary/Index.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Salary" }]
    }
  },
  {
    path: "/:teacherId/salary/:salaryId",
    name: "SalaryView",
    component: () => import("@/views/salary/Detail.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Salary" }]
    }
  },
  {
    path: "/salary/create",
    name: "createSalary",
    component: () => import("@/views/salary/Create.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Salary" }, { name: "createSalary" }]
    }
  },
  {
    path: "/salary/create/teacher/:teacherId",
    name: "createSalaryWithTeacherId",
    component: () => import("@/views/salary/Create.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Salary" }, { name: "createSalary" }]
    }
  },
  {
    path: "/salary/teacher/:teacherId/reset/:payrollId",
    name: "createSalaryReset",
    component: () => import("@/views/salary/Create.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Salary" }, { name: "createSalary" }]
    }
  },
  {
    path: "/payment",
    name: "payment",
    component: () => import("@/views/payment/Index.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "payment" }]
    }
  },
  {
    path: "/payment/create",
    name: "createPayment",
    component: () => import("@/views/payment/Form.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "payment" }, { name: "createPayment" }]
    }
  },
  {
    path: "/salary/teacher/:teacherId/payment/create",
    name: "createPaymentWithTeacher",
    component: () => import("@/views/payment/Form.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "payment" }, { name: "createPaymentWithTeacher" }]
    }
  },
  {
    path: "/payment/edit/:teacherPaymentId",
    name: "editPayment",
    component: () => import("@/views/payment/Form.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "payment" }, { name: "editPayment" }]
    }
  },
  {
    path: "/monetaryStatement",
    name: "monetaryStatement",
    component: () => import("@/views/monetaryStatement/Index.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "monetaryStatement" }]
    }
  },
  {
    path: "/monetaryStatement/create",
    name: "createMonetaryStatement",
    component: () => import("@/views/monetaryStatement/Create.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: "monetaryStatement" },
        { name: "createMonetaryStatement" }
      ]
    }
  },
  {
    path: "/monetaryStatement/edit/:id",
    name: "editMonetaryStatement",
    component: () => import("@/views/monetaryStatement/Edit.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [
        { name: "monetaryStatement" },
        { name: "editMonetaryStatement" }
      ]
    }
  },
  // ===== 家長帳單 ======
  {
    path: "/bill/parents/transaction/:billId",
    name: "parentsSubmitTransaction",
    component: () => import("@/views/bill/parents/SubmitTransaction.vue")
  },
  {
    path: "/bill/parents/problem/:billId",
    name: "parentsSubmitProblem",
    component: () => import("@/views/bill/parents/SubmitProblem.vue")
  },
  {
    path: "/balance",
    name: "Balance",
    component: () => import("@/views/balance/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path:
      "/balance/parent/:parentUserId/balanceTransaction/:balanceTransactionId",
    name: "BalanceTransactionDetail",
    component: () => import("@/views/balance/BalanceTransaction.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/finance/add",
    name: "FinanceAdd",
    component: () => import("@/views/balance/Add.vue"),
    meta: {
      requiresAuth: true
    }
  },
  //练习题
  {
    path: "/practices",
    name: "Practices",
    component: () => import("@/views/practices/Practices.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Practices" }]
    }
  },
  {
    path: "/practice/:practiceId",
    name: "Practice",
    component: () => import("@/views/practices/Practice.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Practices" }]
    }
  },
  {
    path: "/practice/:practiceId/userExam/:userExamId/question/:questionOrder",
    name: "PracticeQuestion",
    component: () => import("@/views/practices/PracticeQuestion.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Practices" }]
    }
  },
  {
    path: "/practice/:practiceId/results",
    name: "PracticeResults",
    component: () => import("@/views/practices/Results.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Practices" }]
    }
  },
  {
    path: "/practice/:practiceId/userExam/:userExamId/result",
    name: "PracticeResult",
    component: () => import("@/views/practices/Result.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Practices" }]
    }
  },
  {
    path: "/practices/:practiceId/edit",
    name: "EditPractices",
    component: () => import("@/views/practices/form/Practice.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Practices" }, { name: "editPractices" }]
    }
  },
  {
    path: "/practices/:practiceId/addQuestions",
    name: "AddQuestionsToPractice",
    component: () => import("@/views/practices/AddQuestions.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Practices" }]
    }
  },
  {
    path: "/fullPractice/:fullPracticeId",
    name: "FullPractice",
    component: () => import("@/views/practices/FullPractice.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Practices" }]
    }
  },
  {
    path: "/fullPractices/:fullPracticeId/edit",
    name: "EditFullPractices",
    component: () => import("@/views/practices/form/FullPractice.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Practices" }, { name: "editFullPractices" }]
    }
  },
  {
    path: "/practices/:fullPracticeId/addPractices",
    name: "AddPracticesToFullPractice",
    component: () => import("@/views/practices/AddPractices.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Practices" }]
    }
  },
  {
    path:
      "/fullPractice/:fullPracticeId/userExam/:userExamId/section/:sectionOrder/question/:questionOrder",
    name: "PracticeSection",
    component: () => import("@/views/practices/PracticeSection.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Practices" }]
    }
  },
  {
    path: "/fullPractice/:fullPracticeId/userExam/:userExamId/BreakTime",
    name: "PracticeBreakTime",
    component: () => import("@/views/practices/BreakTime.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Practices" }]
    }
  },
  {
    path: "/fullPractice/:fullPracticeId/userExam/:userExamId/result",
    name: "FullPracticeResult",
    component: () => import("@/views/practices/FullPracticeResult.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Practices" }]
    }
  },
  {
    path: "/fullPractice/:fullPracticeId/results",
    name: "FullPracticeResults",
    component: () => import("@/views/practices/FullPracticeResults.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Practices" }]
    }
  },
  //老师后台
  {
    path: "/courses/teacherbackend",
    component: () => import("@/views/courses/teacherBackend/Index.vue"),
    children: [
      {
        path: "courseslist",
        name: "teacherCoursesList",
        component: () => import("@/views/courses/teacherBackend/Courses.vue")
      },
      {
        path: "datalist",
        name: "teacherDataList",
        component: () => import("@/views/courses/teacherBackend/Data.vue")
      }
    ]
  },
  //学生后台
  {
    path: "/courses/my-courses/",
    name: "myCourses",
    component: () => import("@/views/courses/my-courses/Learning/Learning.vue")
  },
  {
    path: "/courses/course/",
    name: "studentCourse",
    component: () => import("@/views/courses/my-courses/Course/Course.vue")
  },
  // 管理员后台
  {
    path: "/courses/adminbackend",
    component: () => import("@/views/courses/adminBackend/Index.vue"),
    children: [
      {
        path: "courseslist",
        name: "adminCoursesList",
        component: () => import("@/views/courses/adminBackend/Courses.vue")
      }
    ]
  },
  //课程详情
  {
    path: "/courses/coursedetail",
    name: "courseDetail",
    component: () => import("@/views/courses/components/CourseDetail.vue")
  },
  //查看学生的课程进度
  {
    path: "/courses/studentprogress",
    name: "studentProgress",
    component: () => import("@/views/courses/components/StudentProgress.vue")
  },
  //课程编辑
  {
    path: "/courses/editcourse",
    name: "EditCourse",
    component: () =>
      import("@/views/courses/components/editCourse/EditCourse.vue")
  },
  // 家长后台
  {
    path: "/courses/parentbackend",
    component: () => import("@/views/courses/parentBackend/Index.vue"),
    children: [
      {
        path: "studentsList",
        name: "studentsList",
        component: () => import("@/views/courses/parentBackend/Data.vue")
      }
    ]
  },
  // 課程介紹
  {
    path: "/courses",
    name: "courseIntrosList",
    component: () => import("@/views/courseIntros/Index.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "courseIntrosList" }]
    }
  },
  {
    path: "/courses/create",
    name: "courseIntrosCreate",
    component: () => import("@/views/courseIntros/Create.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "courseIntrosList" }]
    }
  },
  {
    path: "/courses/:id/edit",
    name: "courseIntrosEdit",
    component: () => import("@/views/courseIntros/Create.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "courseIntrosList" }]
    }
  },
  {
    path: "/courses/detail",
    name: "CoursesIntroDetail",
    component: () => import("@/views/courseIntros/Detail.vue"),
    meta: {
      requiresAuth: true
    }
  },
  // 梯次
  {
    path: "/course-sessions",
    name: "courseSessionsList",
    component: () => import("@/views/courseSessions/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/course-sessions/create",
    name: "courseSessionsCreate",
    component: () => import("@/views/courseSessions/Create.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "courseSessionsList" }]
    }
  },
  {
    path: "/course-sessions/:id/detail",
    name: "courseSessionsDetail",
    component: () => import("@/views/courseSessions/Detail.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/course-sessions/:id/edit",
    name: "courseSessionsEdit",
    component: () => import("@/views/courseSessions/Edit.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "courseSessionsList" }]
    }
  },
  // 班級
  {
    path: "/session-classes",
    name: "sessionClassesList",
    component: () => import("@/views/sessionClasses/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/session-classes/create",
    name: "sessionClassesCreate",
    component: () => import("@/views/sessionClasses/Create.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "myClasses" }]
    }
  },
  {
    path: "/session-classes/analysis",
    name: "SessionClassesAnalysis",
    component: () => import("@/views/sessionClasses/analysis/Analysis.vue"),
    children: []
  },
  {
    path: "/session-classes/analysis/detail",
    name: "SessionClassesAnalysisDetail",
    component: () => import("@/views/sessionClasses/analysis/Detail.vue"),
    children: []
  },
  {
    path: "/session-classes/analysis/onlineSATdetail",
    name: "SessionClassesAnalysisOnlineSATdetailDetail",
    component: () =>
      import("@/views/sessionClasses/analysis/OnlineSATDetail.vue"),
    children: []
  },
  {
    path: "/session-classes/:id/edit",
    name: "sessionClassesEdit",
    component: () => import("@/views/sessionClasses/Edit.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "myClasses" }]
    }
  },
  {
    path: "/session-classes/all",
    name: "GroupCourse",
    component: () => import("@/views/sessionClasses/GroupCourse.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "myClasses" }]
    }
  },
  {
    path: "/session-classes/:classId",
    name: "classDetail",
    component: () => import("@/views/sessionClasses/Detail.vue"),
    redirect: "/session-classes/:classId/info",
    children: [
      {
        path: "info",
        name: "ClassInfo",
        component: () => import("@/views/sessionClasses/info/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "students",
        name: "ClassStudents",
        component: () => import("@/views/sessionClasses/students/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "attendance",
        name: "ClassAttendance",
        component: () => import("@/views/sessionClasses/attendance/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "materials",
        name: "ClassMaterials",
        component: () => import("@/views/sessionClasses/materials/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "tests",
        name: "ClassTests",
        component: () => import("@/views/sessionClasses/tests/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "practices",
        name: "ClassPractices",
        component: () => import("@/views/sessionClasses/practices/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "lesson-logs",
        name: "ClassLessonLogs",
        component: () => import("@/views/sessionClasses/lessonlogs/Index.vue"),
        meta: {
          requiresAuth: true
        }
      },
      {
        path: "progress-report",
        name: "ClassProgressReport",
        component: () =>
          import("@/views/sessionClasses/progressreport/Index.vue"),
        meta: {
          requiresAuth: true
        }
      }
    ],
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/old-session-classes/:classId",
    name: "classDetail",
    component: () => import("@/views/sessionClasses/DetailOld.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/session-classes/:classId/materials",
    name: "ClassMaterials",
    component: () => import("@/views/sessionClasses/materials/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  // ===== ACT系统 ======
  {
    path: "/act",
    name: "ACTList",
    component: () => import("@/views/act/Index.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: "/act/test",
    name: "ACTList",
    component: () => import("@/views/act/Test.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: "/act/history",
    name: "ACTHistory",
    component: () => import("@/views/act/History.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: "/act/Explanations",
    name: "ACTExplanations",
    component: () => import("@/views/act/Explanations.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },

  // ===== TOEFL系统 ======
  {
    path: "/toefl",
    name: "ToeflList",
    component: () => import("@/views/toefl/toeflList/Index.vue")
  },
  {
    path: "/toefl/setTest",
    name: "SetTest",
    component: () => import("@/views/toefl/toeflTest/Index.vue")
  },
  {
    path: "/toefl/testresults",
    name: "ToeflTestResults",
    component: () => import("@/views/toefl/toeflTestResults/Index.vue")
  },
  {
    path: "/toefl/testresults/combine",
    name: "ToeflTestResultsCombine",
    component: () => import("@/views/toefl/toeflTestResults/CombineTests.vue")
  },
  {
    path: "/toefl/transcript",
    name: "Transcript",
    component: () => import("@/views/toefl/transcript/Index.vue")
  },
  {
    path: "/toefl/resolve",
    name: "ToeflResolve",
    component: () => import("@/views/toefl/toeflResolve/Index.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: "/toefl/test",
    name: "ToeflTest",
    component: () => import("@/views/toefl/toeflTest/Test.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  // ===== TEST系统 ======
  //Start Test 开始考试
  {
    path: "/tests/start",
    name: "StartTest",
    component: () => import("@/views/tests/startTest/Index.vue")
  },
  //Start Test 开始考试
  {
    path: "/tests/start/download/:fileToken",
    name: "DownloadFile",
    component: () => import("@/views/tests/startTest/download.vue")
  },
  //Download PDF 下载考试
  {
    path: "/tests/file",
    name: "TestFile",
    component: () => import("@/views/tests/timer/SelectFile.vue")
  },
  //Timer 计时器
  {
    path: "/tests/timer",
    name: "Timer",
    component: () => import("@/views/tests/timer/Index.vue")
  },
  //Grading 试卷批改
  {
    path: "/tests/grading",
    name: "Grading",
    component: () => import("@/views/tests/grading/Index.vue")
  },
  {
    path: "/tests/setTest",
    name: "SetStudentTest",
    component: () => import("@/views/tests/setTest/Index.vue")
  },
  {
    path: "/tests/grading/addstudents",
    name: "GradingAddStudents",
    component: () => import("@/views/tests/grading/AddStudents.vue")
  },
  {
    path: "/tests/grading/act",
    name: "GradingACT",
    component: () => import("@/views/tests/grading/Act.vue")
  },
  {
    path: "/tests/grading/sat",
    name: "GradingSAT",
    component: () => import("@/views/tests/grading/Sat.vue")
  },
  {
    path: "/tests/grading/private",
    name: "GradingPrivate",
    component: () => import("@/views/tests/grading/PrivateLesson.vue")
  },
  {
    path: "/tests/grading/exam",
    name: "GradingExam",
    component: () => import("@/views/tests/grading/PrivateLesson.vue")
  },
  //Result
  {
    path: "/:testType/result",
    name: "TestResult",
    component: () => import("@/views/tests/result/Detail.vue")
  },
  {
    path: "/tests/results",
    name: "Results",
    component: () => import("@/views/tests/result/Index.vue")
  },
  //Answers
  {
    path: "/sat/answers",
    name: "SatAnswers",
    component: () => import("@/views/tests/answers/Sat.vue")
  },
  {
    path: "/act/answers",
    name: "ActAnswers",
    component: () => import("@/views/tests/answers/Act.vue")
  },
  {
    path: "/tests",
    name: "Tests",
    component: () => import("@/views/tests/Index.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Tests" }]
    }
  },
  {
    path: "/questions",
    name: "Questions",
    component: () => import("@/views/questions/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/questions/add",
    name: "AddQuestion",
    component: () => import("@/views/questions/Add.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/tests/list",
    name: "TestsList",
    component: () => import("@/views/tests/Results.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Tests" }]
    }
  },
  {
    path: "/tests/:testType/question/create",
    name: "CreateQuestion",
    component: () => import("@/views/tests/form/Question.vue")
  },
  {
    path: "/tests/:testType/edit/test",
    name: "EditTest",
    component: () => import("@/views/tests/form/EditTest.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Tests" }]
    }
  },
  {
    path: "/tests/:testType/test/:testTypeId/section/:sectionId",
    name: "Section",
    component: () => import("@/views/tests/Section.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Tests" }]
    }
  },
  {
    path: "/tests/:testType/test/:testTypeId/section/:sectionId/question",
    name: "Question",
    component: () => import("@/views/tests/Question.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Tests" }]
    }
  },
  {
    path:
      "/tests/:testType/test/:testTypeId/section/:sectionId/question/:questionId/edit",
    name: "EditQuestion",
    component: () => import("@/views/tests/form/Question.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Tests" }]
    }
  },
  {
    path:
      "/tests/:testType/test/:testTypeId/section/:sectionId/question/:questionId/view/:answerId",
    name: "ViewTestQuestionAnswer",
    component: () => import("@/views/tests/explanation/Index.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Tests" }],
      showFooter: false
    }
  },
  {
    path:
      "/tests/:testType/test/:testTypeId/section/:sectionId/question/:questionId/view",
    name: "ViewQuestion",
    component: () => import("@/views/tests/explanation/Index.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Tests" }],
      showFooter: false
    }
  },
  {
    path: "/tests/:testType/:questionId/:answerId",
    name: "ViewQuestionAnswer",
    component: () => import("@/views/tests/explanation/Index.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Tests" }],
      showFooter: false
    }
  },
  {
    path: "/tests/:testType/:questionId/",
    name: "ViewQuestionShortRoute",
    component: () => import("@/views/tests/explanation/Index.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Tests" }],
      showFooter: false
    }
  },
  {
    path:
      "/tests/:testType/test/:testTypeId/section/:sectionId/passage/:passageId/edit",
    name: "EditPassages",
    component: () => import("@/views/tests/form/Passage.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Tests" }]
    }
  },
  {
    path: "/tests/:testType/test/:testTypeId/section/:sectionId/passage/create",
    name: "CreatePassages",
    component: () => import("@/views/tests/form/Passage.vue")
  },
  {
    path:
      "/tests/:testType/test/:testTypeId/section/:sectionId/passage/:passageId",
    name: "EditPassage",
    component: () => import("@/views/tests/Passage.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "Tests" }]
    }
  },
  // 角色綁定
  {
    path: "/register-detail",
    name: "RegisterDetail",
    component: () => import("@/views/RegisterDetail.vue"),
    meta: {
      requiresAuth: true,
      skipCheckBind: true
    }
  },
  {
    path: "/survey",
    name: "Survey",
    component: () => import("@/views/survey/Index.vue")
  },
  {
    path: "/user",
    name: "User",
    component: () => import("@/views/user/User.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/users",
    component: () => import("@/views/user/FindUsers.vue"),
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "",
        name: "UserList",
        component: () => import("@/views/user/UserList.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "UserList" }]
        }
      },
      {
        path: "teachers",
        name: "TeacherList",
        component: () => import("@/views/user/TeacherList.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "UserList" }, { name: "TeacherList" }]
        }
      },
      {
        path: "students",
        name: "StudentList",
        component: () => import("@/views/user/StudentList.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "UserList" }, { name: "StudentList" }]
        }
      },
      {
        path: "Administrators",
        name: "AdminList",
        component: () => import("@/views/user/AdminList.vue"),
        meta: {
          requiresAuth: true,
          breadcrumb: [{ name: "UserList" }, { name: "AdminList" }]
        }
      }
    ]
  },
  {
    path: "/users/merge",
    name: "MergeUser",
    component: () => import("@/views/user/Merge.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "MergeUser" }]
    }
  },
  {
    path: "/tags",
    name: "tags",
    component: () => import("@/views/tags/Index.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "UserList" }]
    }
  },
  {
    path: "/create/tag",
    name: "createTag",
    component: () => import("@/views/tags/TagForm.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "tags" }]
    }
  },
  {
    path: "/edit/tag/:tagId",
    name: "editTag",
    component: () => import("@/views/tags/TagForm.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "tags" }]
    }
  },
  {
    path: "/tag/:tagId",
    name: "tag",
    component: () => import("@/views/tags/Tag.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "tags" }]
    }
  },
  //analysis
  {
    path: "/analysis",
    name: "Analysis",
    component: () => import("@/views/analysis/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/analysis/sat",
    name: "AnalysisSAT",
    component: () => import("@/views/analysis/components/SAT.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/analysis/act",
    name: "AnalysisACT",
    component: () => import("@/views/analysis/components/ACT.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/analysis/toefl",
    name: "AnalysisToefl",
    component: () => import("@/views/analysis/components/TOEFL.vue"),
    meta: {
      requiresAuth: true
    }
  },
  // profile
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/user/UserProfile.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "profile" }]
    }
  },
  {
    path: "/userProfile",
    name: "userProfile",
    component: () => import("@/views/user/ProfileForAdminEditUser.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "UserList" }]
    }
  },
  {
    path: "/user/:id",
    name: "ProfileForAdmin",
    component: () => import("@/views/user/ProfileForAdminEditUser.vue"),
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "UserList" }]
    }
  },
  {
    path: "/user/:id/:editStudentInfoDialog",
    name: "EditStudentInfoForAdmin",
    component: () => import("@/views/user/ProfileForAdminEditUser.vue"),
    props: true,
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "UserList" }]
    }
  },
  {
    path: "/collegeAliases",
    name: "collegeAliases",
    component: () => import("@/views/collegeAliases/Index.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "collegeAliases" }]
    }
  },
  {
    path: "/attachLinkToSchool",
    name: "attachLinkToSchool",
    component: () => import("@/views/attachLinkToSchool/Index.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "attachLinkToSchool" }]
    }
  },
  // Header
  {
    path: "/about",
    name: "About",
    component: () => import("@/views/headerRouter/about/About.vue")
  },
  {
    path: "/instructors",
    name: "Instructors",
    component: () => import("@/views/headerRouter/instructors/Instructors.vue")
  },
  // ClassesAndCounseling
  {
    path: "/classes",
    name: "ClassesAndCounseling",
    component: () => import("@/views/headerRouter/classes/Classes.vue")
  },
  {
    path: "/classes",
    name: "StaticClassTemplate",
    component: () =>
      import("@/components/classesandcounseling/ClassTemplate.vue"),
    children: [
      {
        path: "sat_course",
        name: "SATCourse",
        component: () =>
          import("@/components/classesandcounseling/SATCourse.vue")
      },
      {
        path: "act_course",
        name: "ACTCourse",
        component: () =>
          import("@/components/classesandcounseling/ACTCourse.vue")
      },
      {
        path: "sat_course_part2",
        name: "SATCoursePart2",
        component: () =>
          import("@/components/classesandcounseling/SATCoursePart2.vue")
      },
      {
        path: "sat_math_level2",
        name: "SATMathLevel2",
        component: () =>
          import("@/components/classesandcounseling/SATMathLevel2.vue")
      },
      {
        path: "sat_math",
        name: "SATMath",
        component: () => import("@/components/classesandcounseling/SATMath.vue")
      },
      {
        path: "private_inperson",
        name: "PrivateInperson",
        component: () =>
          import("@/components/classesandcounseling/PrivateInperson.vue")
      },
      {
        path: "private_online",
        name: "PrivateOnline",
        component: () =>
          import("@/components/classesandcounseling/PrivateOnline.vue")
      },
      {
        path: "mentoring_program",
        name: "MentoringProgram",
        component: () =>
          import("@/components/classesandcounseling/MentoringProgram.vue")
      },
      {
        path: "application_counseling",
        name: "ApplicationCounseling",
        component: () =>
          import("@/components/classesandcounseling/ApplicationCounseling.vue")
      },
      {
        path: "toefl_course",
        name: "TOEFLCourse",
        component: () =>
          import("@/components/classesandcounseling/TOEFLCourse.vue")
      },
      {
        path: "toefl_eric",
        name: "TOEFLEric",
        component: () =>
          import("@/components/classesandcounseling/TOEFLEric.vue")
      },
      {
        path: "sat_course_winter",
        name: "SATCourseWinter",
        component: () =>
          import("@/components/classesandcounseling/SATCourseWinter.vue")
      }
    ]
  },
  {
    path: "/classes/:courseId",
    name: "ClassTemplate",
    component: () =>
      import("@/components/classesandcounseling/ClassTemplate.vue")
  },
  // stories
  {
    path: "/stories",
    name: "Stories",
    component: () => import("@/views/headerRouter/stories/Stories.vue")
  },
  {
    path: "/stories/list",
    name: "StoriesList",
    component: () => import("@/views/stories/List.vue")
  },
  {
    path: "/line",
    name: "LinePage",
    component: () => import("@/views/LinePage.vue")
  },
  // apply
  {
    path: "/apply",
    name: "Apply",
    component: () => import("@/views/apply/Index.vue")
  },
  {
    path: "/apply/thanks",
    name: "ApplyThanks",
    component: () => import("@/views/apply/Thanks.vue")
  },
  {
    path: "/terms",
    name: "Terms",
    component: () => import("@/views/apply/Terms.vue")
  },
  {
    path: "/blogs",
    name: "Blogs",
    beforeEnter() {
      location.href = "https://blog.ivy-way.com/";
    }
  },
  // 關於(FAQ)
  {
    path: "/faq",
    name: "Faq",
    component: () => import("@/views/headerRouter/about/Faq.vue"),
    redirect: { name: "FaqChild", params: { id: "about-us" } },
    props: true,
    meta: {
      skipCheckBind: true
    },
    children: [
      {
        path: ":id",
        name: "FaqChild",
        props: true,
        component: () => import("@/components/faq/CollapseItems")
      }
    ]
  },
  {
    path: "/admin/edit-faq",
    name: "EditFaq",
    component: () => import("@/views/headerRouter/about/EditFaq.vue"),
    props: true,
    redirect: { name: "EditFaqHome" },
    meta: {
      requiresAuth: true
    },
    children: [
      {
        path: "/admin/edit-faq-home",
        name: "EditFaqHome",
        props: true,
        component: () => import("@/views/headerRouter/about/EditFaqHome.vue")
      },
      {
        path: "/admin/edit-faq-about",
        name: "EditFaqMain",
        props: true,
        component: () => import("@/views/headerRouter/about/EditFaqMain.vue")
      },
      {
        path: "/admin/edit-faq-category",
        name: "EditFaqCategory",
        props: true,
        component: () =>
          import("@/views/headerRouter/about/EditFaqCategory.vue")
      },
      {
        path: "/admin/edit-faq-topic",
        name: "EditFaqTopic",
        props: true,
        component: () => import("@/views/headerRouter/about/EditFaqTopic.vue")
      },
      {
        path: "/admin/edit-instructors-faq",
        name: "EditInstructorsFaq",
        props: true,
        component: () =>
          import("@/views/headerRouter/about/EditInstructorsFaq.vue")
      }
    ]
  },
  // flash cards
  {
    path: "/vocabs",
    name: "Vocabs",
    props: true,
    component: () => import("@/views/flashCards/Index.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: "/flashcards",
    name: "FlashCards",
    props: true,
    component: () => import("@/views/flashCards/FlashCards.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: "/flashcards/list/:title",
    name: "FlashCardsList",
    props: true,
    component: () => import("@/views/flashCards/List.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: "/flashcards/memorize/:title",
    name: "MemorizeList",
    props: true,
    component: () => import("@/views/flashCards/MemorizeList.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: "/my-vocab-lists",
    name: "MyVocabLists",
    props: true,
    component: () => import("@/views/myVocabLists/Index.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: "/mywords",
    name: "MyWords",
    props: true,
    component: () => import("@/views/myWords/Index.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: "/myvocablists/startlist",
    name: "StartList",
    props: true,
    component: () => import("@/views/myVocabLists/StartList.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: "/myvocablists/list",
    name: "MyVocabListsList",
    props: true,
    component: () => import("@/views/myVocabLists/List.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: "/mywords/list",
    name: "MyWordsList",
    props: true,
    component: () => import("@/views/myWords/List.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },
  {
    path: "/myvocablists/memorize",
    name: "myVocablistsMemorizeList",
    props: true,
    component: () => import("@/views/myVocabLists/MemorizeList.vue"),
    meta: {
      showHeader: false,
      showFooter: false
    }
  },

  // ===== 報名表 =====
  {
    path: "/enroll",
    name: "EnrollmentNew",
    component: () => import("@/views/enrollmentNew/Index.vue")
  },
  {
    path: "/quick_enroll",
    name: "QuickEnrollNew",
    component: () => import("@/views/enrollmentNew/QuickEnroll.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "QuickEnrollNew" }]
    }
  },
  {
    path: "/enroll/success",
    name: "EnrollmentSuccess",
    component: () => import("@/views/enrollmentNew/Success.vue")
  },
  //寄送信件
  {
    path: "/mail",
    name: "EmailCompose",
    component: () => import("@/views/mail/Index.vue"),
    redirect: "/mail/create",
    children: [
      {
        path: "/mail/create",
        name: "CreateEmail",
        component: () => import("@/views/mail/Compose.vue")
      },
      {
        path: "/mail/edit/:id",
        name: "EditEmail",
        component: () => import("@/views/mail/Compose.vue")
      },
      {
        path: "/mail/view/:id",
        name: "ViewEmail",
        component: () => import("@/views/mail/ViewEmail.vue")
      },
      {
        path: "/mail/list",
        name: "EmailList",
        component: () => import("@/views/mail/List.vue")
      },
      {
        path: "/mail/template",
        name: "EmailTemplate",
        component: () => import("@/views/mail/EmailTemplate.vue")
      }
    ]
  },
  {
    path: "/mail/progress-report",
    name: "EmailProgressReport",
    component: () => import("@/views/mail/ProgressReportMail.vue")
  },
  {
    path: "/inactive",
    name: "InactivePage",
    props: true,
    component: () => import("@/views/Inactive.vue")
  },
  {
    path: "/stripe/deposit",
    name: "stripeDeposit",
    props: true,
    component: () => import("@/views/stripe/Index.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/stripe",
    name: "Stripe",
    props: true,
    component: () => import("@/views/stripe/Public.vue")
  },
  {
    path: "/stripe/success",
    name: "stripeSuccess",
    props: true,
    component: () => import("@/views/stripe/Success.vue"),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: "/shortUrls",
    name: "shortUrls",
    component: () => import("@/views/shortUrls/Index.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "shortUrls" }]
    }
  },
  {
    path: "/shortUrls/add",
    name: "addShortUrl",
    component: () => import("@/views/shortUrls/AddShortUrls.vue"),
    meta: {
      requiresAuth: true,
      breadcrumb: [{ name: "shortUrls" }, { name: "addShortUrl" }]
    }
  },
  {
    path: "/l/*",
    name: "redirectShortUrl",
    component: () => import("@/views/shortUrls/RedirectShortUrl.vue"),
    meta: {}
  },
  {
    path: "/toefl-videos",
    name: "ToeflVideos",
    component: () => import("@/views/toeflVideos/Index.vue")
  },

  // TODO: 404
  {
    path: "/404",
    name: "PageNotFound",
    component: () => import("@/views/PageNotFound.vue")
  },
  {
    path: "*",
    name: "RedirectToNotFound",
    component: () => import("@/views/RedirectToNotFound.vue")
  },
  {
    path: "/wpstest",
    name: "WpsTest",
    component: () => import("@/views/wpsTest/Test.vue")
  },
  // Redirect to absolute path
  {
    path: "/l/admissions_stories_seminar",
    beforeEnter: (to, from, next) => {
      location.href = "https://forms.gle/oiW4isx8GUfEe46BA";
    }
  },
  //feedback
  {
    path: "/feedback",
    name: "Feedback",
    component: () => import("@/views/feedback/Index.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // 導向新的 router 會到新頁面的 top
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});

router.beforeEach(async (to, from, next) => {
  const ignoreRemeberPage = [
    "Login",
    "LineLogin",
    "PageNotFound",
    "ForgetPassword",
    "SetPassword",
    "ResetPassword",
    "InactivePage"
  ];
  if (!ignoreRemeberPage.includes(to.name)) {
    localStorage.setItem(
      "lastAccessRoutePath",
      JSON.stringify({ path: to.path, query: to.query })
    );
  }
  if (to.meta.requiresAuth && !JwtService.hasToken()) {
    next({ name: "Login" });
  }

  if (!to.meta.skipCheckBind && JwtService.hasToken()) {
    await store.dispatch("user/checkFinishBind");

    if (!store.state.user.finishBind) {
      next({ name: "RegisterDetail" });
    }
    await store.dispatch("user/getProfile");
  }

  const pagesWhichCanNotAccessAfterLogin = [
    "Login",
    "LineLogin",
    "Register",
    "InactivePage"
  ];
  if (
    JwtService.hasToken() &&
    pagesWhichCanNotAccessAfterLogin.includes(to.name)
  ) {
    next({ name: "Home" });
  }

  let redirectedFrom = to?.redirectedFrom || "";
  // for old private lesson route path
  if (
    redirectedFrom.includes("/private-lessons") &&
    !redirectedFrom.includes("/invitation-reply") &&
    !redirectedFrom.includes("/private/private/private/")
  ) {
    next({ path: `/private${to.redirectedFrom}` });
  }

  // for old response private lesson invitation
  if (
    redirectedFrom.includes("/admin/private-lessons/invitation-reply") &&
    !redirectedFrom.includes("/private/private/private/")
  ) {
    redirectedFrom = redirectedFrom.replace("/admin", "");
    next({ path: `/private${redirectedFrom}` });
  }

  //最后检查此页面是否在编辑
  if (store.state.router.isEditing) {
    //页面中含有正在编辑内容
    MessageBox.confirm(
      "Your changes may not be saved. Are you sure you want to leave the page?",
      "Warning",
      {
        confirmButtonText: "Confirm",
        cancelButtonText: "Cancel",
        type: "warning"
      }
    )
      .then(async () => {
        store.commit("router/SET_EDITING", false);
        next();
      })
      .catch(() => {
        next(false);
      });
  } else {
    next();
  }
});

export default router;
