import ApiService from "@/common/api.service";
export default {
  getToefls(data) {
    return ApiService.query("toefls", data);
  },
  getExamId(data) {
    return ApiService.post("toefls/get-exam-id", data);
  },
  getQuestions(exam_id, data) {
    return ApiService.get("toefls/questions", exam_id, data);
  },
  getAnswers(data) {
    return ApiService.get("toefls/answers", data);
  },
  updateAnswers(id, data) {
    return ApiService.update("toefls/answer", id, data);
  },
  uploadAudio(data) {
    return ApiService.post("document", data);
  },
  deleteAudio(id) {
    return ApiService.delete(`document/${id}`);
  },
  getTranscript(user_exam_id) {
    return ApiService.get("toefls/transcript", user_exam_id);
  },
  getResolve(user_exam_id, data) {
    return ApiService.get("toefls/question-exp", user_exam_id, data);
  },
  getAllTranscripts(data) {
    return ApiService.query("toefls/transcripts", data);
  },
  gradeScore(user_exam_answer_id, data) {
    return ApiService.update("toefls/grade", user_exam_answer_id, data);
  },
  updateScore(id) {
    return ApiService.update("toefls/update-score", id);
  },
  getTests(data) {
    return ApiService.query("toefls/transcripts/combine", data);
  },
  combineTests(data) {
    return ApiService.post("toefls/transcripts/combine", data);
  },
  getStudents() {
    return ApiService.query("toefls/transcripts/taken-exam-users");
  },
  sendEmail(userExam) {
    return ApiService.post(`/toefls/corrected/${userExam}`);
  }
};
