<template>
  <div id="bg">
    <el-row class="d-md-flex block1">
      <el-col>
        <div class="rights pl-4 text-left">{{ rights }}</div>
      </el-col>
      <el-col class="block2 text-right">
        <div class="pr-4 text-right">
          <!-- Taiwan phone -->
          <el-tooltip
            class="item"
            :content="
              isoCodeIsCn ? '173-2124-7147' : '(02)2370-5397 | 0978-038-334'
            "
            placement="top"
            popper-class="tooltipColorFooter"
          >
            <el-button circle size="mini">
              <span class="icon">
                <i class="fa fa-phone"></i>
              </span>
            </el-button>
          </el-tooltip>

          <!-- Email -->
          <el-tooltip
            class="item"
            placement="top"
            popper-class="tooltipColorFooter"
          >
            <div slot="content">
              <img
                v-if="isoCodeIsCn"
                style="display:block;height: 14px;"
                src="https://ivy-way.s3.ap-northeast-1.amazonaws.com/images/ivy-way_china_email_white.png"
                alt=""
              />
              <img
                v-else
                style="display:block;height: 14px;"
                src="https://ivy-way.s3.ap-northeast-1.amazonaws.com/images/ivyway_email_white.png"
                alt=""
              />
            </div>
            <el-button circle size="mini" class="iconButton">
              <span class="icon">
                <i class="fa fa-envelope"></i>
              </span>
            </el-button>
          </el-tooltip>

          <!-- Weibo -->
          <el-tooltip
            v-if="isoCodeIsCn"
            class="item"
            content="ivywayedu"
            placement="top"
            popper-class="tooltipColorFooter"
          >
            <el-button circle size="mini" class="iconButton">
              <span class="icon">
                <i class="fab fa-weibo"></i>
              </span>
            </el-button>
          </el-tooltip>
          <!-- Wechat -->
          <el-tooltip
            class="item"
            :content="isoCodeIsCn ? 'ivywaychina | ivywayedu' : 'ivywaysat'"
            placement="top"
            popper-class="tooltipColorFooter"
          >
            <el-button circle size="mini" class="iconButton">
              <span class="icon">
                <i class="fab fa-weixin"></i>
              </span>
            </el-button>
          </el-tooltip>

          <!-- QQ -->
          <el-tooltip
            v-if="isoCodeIsCn"
            class="item"
            content="2933435017"
            placement="top"
            popper-class="tooltipColorFooter"
          >
            <el-button circle size="mini" class="iconButton">
              <span class="icon">
                <i class="fab fa-qq"></i>
              </span>
            </el-button>
          </el-tooltip>

          <!-- Line -->
          <el-tooltip
            v-if="!isoCodeIsCn"
            class="item"
            content="@ivyway"
            placement="top"
            popper-class="tooltipColorFooter"
          >
            <el-button circle size="mini" class="iconButton">
              <a target="_blank" href="https://page.line.me/ivyway">
                <span class="icon">
                  <i class="fab fa-line"></i>
                </span>
              </a>
            </el-button>
          </el-tooltip>

          <!-- Facebook -->
          <el-tooltip
            v-if="!isoCodeIsCn"
            class="item"
            content="ivywayacademy"
            placement="top"
            popper-class="tooltipColorFooter"
          >
            <el-button circle size="mini" class="iconButton">
              <a target="_blank" href="https://facebook.com/ivywayacademy">
                <span class="icon">
                  <i class="fab fa-facebook-square"></i>
                </span>
              </a>
            </el-button>
          </el-tooltip>

          <!-- IG -->
          <el-tooltip
            v-if="!isoCodeIsCn"
            class="item"
            content="ivywayacademy"
            placement="top"
            popper-class="tooltipColorFooter"
          >
            <el-button circle size="mini" class="iconButton">
              <a target="_blank" href="https://www.instagram.com/ivywayacademy/">
                <span class="icon">
                  <i class="fab fa-instagram" />
                </span>
              </a>
            </el-button>
          </el-tooltip>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import moment from "moment";
export default {
  computed: {
    isoCodeIsCn() {
      return this.$store.getters["ipGeo/getGeoAfterCall"].iso_code === "CN";
    }
  },
  data() {
    return {
      rights: ""
    };
  },
  mounted() {
    this.$store.dispatch("ipGeo/getGeoLocation");
    // get current year
    const year = moment().year();
    this.rights = "© 2009-" + year + " Ivy-Way. All rights reserved.";
  }
};
</script>

<style lang="scss" scoped>
#bg {
  background-color: #42a16a;
  padding: 1.3em 0 1.3em 0;
}
.icon {
  font-size: 16px;
  color: #42a16a;
}
.block1 {
  color: white;
  text-align: center;
  text-justify: center;
}
.rights {
  line-height: 32px;
}
.block2 {
  display: flex;
  justify-content: flex-end;
}

.iconButton {
  width: 36px;
  height: 36px;
}

@media screen and (max-width: 768px) {
  .block2 {
    // padding: 0px 120px 0px 120px;
    justify-content: center;
    padding-top: 10px;
    display: flex;
  }
  .btnsLeft {
    padding-right: 10px;
  }
}

@media screen and (max-width: 400px) {
  .block2 {
    display: block;
    text-align: center !important;
  }
  .btnsLeft {
    padding-right: 0px;
    padding-bottom: 8px;
  }
}

.input-icon {
  width: 18px;
  height: 18px;
  display: inline-block;
  background-size: cover;
  background-color: #42a16a;
}

.icon-email {
  mask: url("../assets/svg/email.svg") no-repeat center;
}

.icon-weibo {
  mask: url("../assets/svg/sina-weibo.svg") no-repeat center;
}

.icon-wechat {
  mask: url("../assets/svg/wechat.svg") no-repeat center;
}

.icon-qq {
  mask: url("../assets/svg/qqchat.svg") no-repeat center;
}

.icon-line {
  mask: url("../assets/svg/line-brands.svg") no-repeat center;
}

.icon-facebook {
  mask: url("../assets/svg/facebook.svg") no-repeat center;
}

.icon-mobile {
  mask: url("../assets/svg/cellphone-iphone.svg") no-repeat center;
}
</style>
