import ApiService from "@/common/api.service";

export default {
  getSchoolByKeyword(keyword) {
    return ApiService.query("schools/search", { keyword });
  },
  getSchoolByNameOrAlias(schools) {
    return ApiService.post("school-aliases/search", { schools });
  },
  getSchoolAliases(school_id) {
    return ApiService.query(`schools/${school_id}/aliases`, { school_id });
  },
  getSchoolListName(school_id) {
    return ApiService.query(`school/${school_id}/name`);
  },
  createCollegeAlias(collegeAlias) {
    return ApiService.post("school-aliases", {
      lang: collegeAlias.lang,
      name: collegeAlias.name,
      school_id: collegeAlias.schoolId,
      is_default: collegeAlias.isDefault
    });
  },
  updateCollegeAlias(collegeAlias) {
    return ApiService.put(`school-aliases/${collegeAlias.id}`, {
      lang: collegeAlias.lang,
      name: collegeAlias.name,
      school_id: collegeAlias.schoolId,
      is_default: collegeAlias.isDefault
    });
  },
  deleteCollegeAlias(schoolAliasId) {
    return ApiService.delete(`school-aliases/${schoolAliasId}`);
  },
  checkAliasIsExist(keyword) {
    return ApiService.query("school-aliases/exist", { keyword });
  },
  getSchoolColumn() {
    return ApiService.query("school-tables");
  },
  getSchoolInfo(data) {
    return ApiService.post("school-table-info", data);
  }
};
