import faq from "@/apis/faq";
import courseApi from "@/apis/course";

const state = {
  sessionTitle: ""
};

const getters = {
  getSessionTitle(state) {
    return state.sessionTitle;
  },
  getFaqList(state) {
    return state.faqList;
  }
};

const mutations = {
  setSessionTitle(state, data) {
    state.sessionTitle = data;
  }
};

const actions = {
  async getSessionTitle({ commit }, classId) {
    const res = await courseApi.fetchSessionClass(classId);
    console.log(res);
    commit("setSessionTitle", res.session_class.title);
  },
  // Faq
  async getFaqList({ commit }, topicId) {
    const params = { faq_topic_id: topicId };
    const data = await faq.getFaqList(params);
    if (topicId) {
      commit("setFaqListWithId", data);
    } else {
      commit("setFaqList", data);
    }
  },
  /*eslint-disable no-unused-vars */
  async createFaq({ commit }, data) {
    /*eslint-enable */
    await faq.createFaq(data);
  },
  /*eslint-disable no-unused-vars */
  async getFaq({ commit }, topicId) {
    /*eslint-enable */
    await faq.getFaq(topicId);
  },
  /*eslint-disable no-unused-vars */
  async updateFaq({ commit }, payload) {
    /*eslint-enable */
    const faqId = payload.id;
    await faq.updateFaq(faqId, payload);
  },
  /*eslint-disable no-unused-vars */
  async deleteFaq({ commit }, faqId) {
    /*eslint-enable */
    await faq.removeFaq(faqId);
  },

  // Label
  async getLabelList({ commit }) {
    const data = await faq.getLabelList();
    commit("setLabelList", data);
  },
  /*eslint-disable no-unused-vars */
  async createLabel({ commit }, payload) {
    /*eslint-enable */
    await faq.createLabel(payload);
  },
  /*eslint-disable no-unused-vars */
  async getLabel({ commit }, labelId) {
    /*eslint-enable */
    await faq.getLabel(labelId);
  },
  /*eslint-disable no-unused-vars */
  async updateLabel({ commit }, payload) {
    /*eslint-enable */
    const labelId = payload.id;
    await faq.updateLabel(labelId, payload);
  },
  /*eslint-disable no-unused-vars */
  async deleteLabel({ commit }, labelId) {
    /*eslint-enable */
    await faq.removeLabel(labelId);
  },

  // Topic
  async getTopicList({ commit }, labelId) {
    const params = { faq_label_id: labelId };
    const data = await faq.getTopicList(params);
    commit("setTopicList", data);
  },
  /*eslint-disable no-unused-vars */
  async createTopic({ commit }, payload) {
    /*eslint-enable */
    await faq.createTopic(payload);
  },
  /*eslint-disable no-unused-vars */
  async getTopic({ commit }, topicId) {
    /*eslint-enable */
    await faq.getTopic(topicId);
  },
  /*eslint-disable no-unused-vars */
  async updateTopic({ commit }, payload) {
    /*eslint-enable */
    const topicId = payload.id;
    await faq.updateTopic(topicId, payload);
  },
  /*eslint-disable no-unused-vars */
  async deleteTopic({ commit }, topicId) {
    /*eslint-enable */
    await faq.removetopic(topicId);
  },
  // for FAQ About page
  async getFaqs({ commit }) {
    const data = await faq.getFaqs();
    commit("setFaqs", data);
  },

  // tool
  saveCurrentLabel({ commit }, data) {
    commit("setCurrentLabel", data);
  },
  saveCurrentTopic({ commit }, data) {
    commit("setCurrentTopic", data);
  },
  clearFaqList({ commit }) {
    commit("setFaqList", []);
  },
  clearTopicList({ commit }) {
    commit("setTopicList", []);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
