<template>
  <ckeditor5
    @ready="onReady"
    :editor="editor"
    :config="editorConfig"
    :value="value || ''"
    @input="value => $emit('input', value)"
  />
</template>

<script>
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import EditorImgUpload from "@/apis/EditorImgUpload.js";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import FileRepository from "@ckeditor/ckeditor5-upload/src/filerepository";
import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";

export default {
  data() {
    return {
      editor: ClassicEditor
    };
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    testType: {
      type: String,
      default: "sat"
    },
    height: {
      type: String,
      default: "auto"
    }
  },
  computed: {
    editorConfig() {
      return {
        versionCheck: false,
        plugins: [
          Essentials,
          Bold,
          Italic,
          Image,
          ImageCaption,
          ImageStyle,
          ImageToolbar,
          ImageUpload,
          FileRepository,
          Paragraph
        ],
        toolbar: {
          items: ["bold", "italic", "|", "imageUpload"]
        },
        image: {
          toolbar: [
            "imageStyle:full",
            "imageStyle:side",
            "|",
            "imageTextAlternative"
          ]
        },
        language: "en"
      };
    }
  },
  methods: {
    onReady(editor) {
      // 自定义上传图片插件
      editor.plugins.get("FileRepository").createUploadAdapter = loader => {
        return new EditorImgUpload(loader);
      };
      editor.editing.view.change(writer => {
        writer.setStyle(
          "height",
          `${this.height}px`,
          editor.editing.view.document.getRoot()
        );
      });
    }
  }
};
</script>
