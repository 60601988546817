import storiesApi from "@/apis/stories";

const state = {
  currentStories: {}
};

const getters = {
  getCurrentStories(state) {
    return state.currentStories;
  }
};

const mutations = {
  setCurrentStories(state, data) {
    state.currentStories = data;
  }
};

const actions = {
  async getStories({ commit }, payload) {
    if (!payload) payload = 1;
    const data = await storiesApi.getStories(payload);
    commit("setCurrentStories", data);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
