import ApiService from "@/common/api.service";

export default {
  register(data) {
    return ApiService.post("register", data);
  },
  quickRegisterAsStudent(data) {
    return ApiService.post("quick-register-as-student", data);
  },
  login(data) {
    return ApiService.post("login", data);
  },
  getProfile() {
    return ApiService.post("me");
  },
  logout() {
    return ApiService.post("logout");
  },
  checkUsernameIsDuplicate(data) {
    return ApiService.post("check-username", data);
  },
  getTeacherApplicationByEmail(data){
    return ApiService.query("teacher-application-by-email", data);
  },
  getLineInfo(data) {
    return ApiService.post("line-notes/sync", data);
  },
  getLineNotes(userId) {
    return ApiService.query(`line-notes/${userId}`);
  },
  getLineNotesDate() {
    return ApiService.query("line-notes/last-sync-at");
  },
  searchStudents(data) {
    return ApiService.query("students", data);
  },
  searchParents(data) {
    return ApiService.query("parent/search", data);
  },
  getStudentByParentId(parentId) {
    return ApiService.query(`parent/${parentId}/children`);
  },
  connectStudentAndParentWithoutLogin(data) {
    return ApiService.post("parent-student/connect-by-token", data);
  },
  connectStudentAndParent(parentId, users) {
    return ApiService.post("parent/connect-student", {
      parent_user_id: parentId,
      users
    });
  },
  disconnectStudentAndParent(parentId, users) {
    return ApiService.post("parent/disconnect-student", {
      parent_user_id: parentId,
      users
    });
  },
  attachTagsToUser(userId, tagIds) {
    return ApiService.post(`user/${userId}/attach-tags`, {
      tag_ids: tagIds
    });
  },
  detachTagsToUser(userId, tagIds) {
    return ApiService.patch(`user/${userId}/detach-tags`, {
      tag_ids: tagIds
    });
  },
  saveLang(data) {
    return ApiService.patch("lang", data);
  },
  quickRegisterStudent(data) {
    return ApiService.post("register/student", data);
  },
  quickRegisterParent(data) {
    return ApiService.post("register/parent", data);
  },
  getTeacherContracts(data) {
    return ApiService.query("teacher-contracts/search", data);
  },
  getMineContracts() {
    return ApiService.query("teacher-contracts/mine");
  },
  createContracts(data){
    return ApiService.post("teacher-contract", data);
  },
  updateContracts(id, data) {
    return ApiService.patch(`teacher-contract/${id}`, data);
  },
  removeContracts(id) {
    return ApiService.delete(`teacher-contract/${id}`);
  }
};
