const state = {
  enrollmentForm: {}
};

const getters = {
  enrollmentFormId(state) {
    const enrollmentForm = state.enrollmentForm;

    if (!Object.keys(enrollmentForm).length) {
      return 0;
    }

    return enrollmentForm.id;
  }
};

const mutations = {
  setEnrollmentForm(state, enrollmentForm) {
    state.enrollmentForm = enrollmentForm;
  }
};

const actions = {
  keepEnrollmentForm({ commit }, enrollmentForm) {
    commit("setEnrollmentForm", enrollmentForm);
  },
  removeEnrollmentForm({ commit }, enrollmentForm) {
    commit("setEnrollmentForm", enrollmentForm);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
