import currencyApi from "@/apis/currency";

const state = {
  exchangeRate: null
};

const getters = {
  exchangeRate(state) {
    return state.exchangeRate;
  }
};

const mutations = {
  setExchangeRate(state, exchangeRate) {
    state.exchangeRate = exchangeRate;
  }
};

const actions = {
  async fetchExchangeRate({ commit }) {
    const exchangeRate = await currencyApi.fetchExchangeRate();
    commit("setExchangeRate", exchangeRate);
    return exchangeRate;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
