import ApiService from "@/common/api.service";

const state = {
  detail: null,
  isAuthCode: false,
  isValidStudentEmail: false,
  isSubmitError: false,
  isBinding: false
};

const mutations = {
  setDetail(state, data) {
    state.detail = data;
  },
  setIsAuthCode(state, data) {
    state.isAuthCode = data;
  },
  setIsValidStudentEmail(state, data) {
    state.isValidStudentEmail = data;
  },
  setIsSubmitError(state, data) {
    state.isSubmitError = data;
  },
  setIsBinding(state, data) {
    state.isBinding = data;
  }
};

const actions = {
  async createNewStudent({ commit }, student) {
    try {
      await ApiService.post("bind-student", student);
      commit("setDetail", student);
      commit("setIsSubmitError", false);
    } catch (e) {
      commit("setIsSubmitError", true);
    }
  },
  async createNewTeacher({ commit }, teacher) {
    try {
      await ApiService.post("bind-teacher", teacher);
      commit("setDetail", teacher);
      commit("setIsSubmitError", false);
    } catch (e) {
      commit("setIsSubmitError", true);
    }
  },
  async createNewParent({ commit }, parent) {
    try {
      await ApiService.post("bind-parent", parent);
      commit("setDetail", parent);
      commit("setIsSubmitError", false);
    } catch (e) {
      commit("setIsSubmitError", true);
    }
  },
  /*eslint-disable no-unused-vars */
  async uploadFile({ commit }, file, config) {
    /*eslint-enable */
    return await ApiService.post("image", file, config);
  },
  /*eslint-disable no-unused-vars */
  async deleteUploadFile({ commit }, id) {
    /*eslint-enable */
    return await ApiService.delete("image/" + id);
  },
  async checkInstructorAuth({ commit }, payload) {
    let isAuthCode = false;
    try {
      await ApiService.post("auth-instructor", payload);
      isAuthCode = true;
    } catch (e) {
      isAuthCode = false;
    }
    commit("setIsAuthCode", isAuthCode);
  },
  async checkStudentEmail({ commit }, payload) {
    let isValid = false;
    try {
      await ApiService.post("check-email", payload);
      isValid = true;
    } catch (e) {
      isValid = false;
    }
    commit("setIsValidStudentEmail", isValid);
  },
  async checkBinding({ commit }) {
    const isBinding = await ApiService.post("finish-bind");
    console.log(isBinding);

    commit("setIsBinding", isBinding.finishBind);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
