import ApiService from "@/common/api.service";

export default {
  sendRestLink(data) {
    return ApiService.post("send-reset-link", data);
  },
  reset(data) {
    return ApiService.post("reset", data);
  },
  getPasswordUrl(data) {
    return ApiService.post("set-url", data);
  }
};
