import ApiService from "@/common/api.service";
import role from "@/mixins/role";

export default {
  fetchStudentList(params) {
    return ApiService.query("student-class-tickets/placement-list", params);
  },
  fetchPlacementList(params) {
    return ApiService.query("session-classes/placement-list", params);
  },
  postStudentClassTicket(id, params) {
    return ApiService.post("student-class-tickets/" + id, params);
  },
  quickEnrollStudent(params) {
    return ApiService.post("quick-enroll-student", params);
  },
  inviteTeachers(params) {
    return ApiService.post("invite-teachers", params);
  },
  updateInvitation(params) {
    return ApiService.patch("teacher-invitation", params);
  },
  deleteTeacherInvitation(id) {
    return ApiService.delete(`teacher-invitation/${id}`);
  },
  sendInvitationToTeacher(id) {
    return ApiService.post("send-teacher-invitation-email", { id });
  },
  sendMailProxy(handler, params) {
    if (role.methods.isRoleAdmin()) {
      return handler(params);
    }

    if (role.methods.isRoleTeacher()) {
      return handler({
        ...params,
        need_send_mail: 1
      });
    }
  },
  replyTeacherInvitation(params) {
    return this.sendMailProxy(
      handledParams =>
        ApiService.post("reply-teacher-invitation", handledParams),
      params
    );
  },
  linkTeacher(params) {
    return ApiService.post("link-teacher", params);
  }
};
