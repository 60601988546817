/* eslint no-empty-pattern: 0 */
import moment from "moment";
import salaryAPI from "@/apis/salary";

const initialState = {
  unpaids: []
};

const state = { ...initialState };

const getters = {
  unpaids(state) {
    return state.unpaids;
  },
  unpaid: state => teacherId => {
    return state.unpaids.find(unpaid => unpaid.user_id === Number(teacherId));
  }
};

const mutations = {
  setUnpaids(state, unpaids) {
    state.unpaids = unpaids;
  }
};

const actions = {
  async fetchTeachersUnpaids({ commit }) {
    const unpaids = await salaryAPI.fetchTeacherUnpaids();
    commit(
      "setUnpaids",
      unpaids.map(unpaid => ({
        ...unpaid,
        userId: unpaid.user_id,
        userName: unpaid.user_name,
        latestPayroll: {
          ...unpaid.latest_payroll,
          createDate: moment(unpaid.latest_order).format("YYYY-MM-DD")
        }
      }))
    );
  },
  async fetchTeachersPayrolls({ commit }) {
    const unpaids = await salaryAPI.fetchTeacherPayrolls();
    commit(
      "setUnpaids",
      unpaids.map(unpaid => ({
        ...unpaid,
        userId: unpaid.user_id,
        userName: unpaid.user_name,
        latestPayroll: {
          ...unpaid.latest_payroll,
          createDate: moment(unpaid.latest_order).format("YYYY-MM-DD")
        }
      }))
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
