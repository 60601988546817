import {
  CounselingProfile,
  Address,
  PhoneNumber,
  Language,
  MilitaryExperience,
  LegalGuardianCollege,
  Parent,
  ParentCollege,
  LegalGuardian,
  Degree,
  Sibling,
  SiblingCollege,
  SiblingCollegeDegree,
  MathCourse78th,
  LanguageCourse78th,
  HighSchoolAcadYear,
  OtherHighSchool,
  HighSchoolCourse,
  TakenCollege,
  TakenCollegeGrade,
  CompletedCollegeCourse,
  InProgressCollegeCourse,
  RecentCourse,
  Honor,
  Organization,
  SatSubject,
  ApSubject,
  IbSubject,
  Duolingo,
  OtherProficiencyExam,
  ALevelSubject,
  LeavingExam,
  Activity,
  ActivityOrAwardFactory
} from "@/class/counselingProfile";
import counselingProfileApi from "@/apis/counselingProfile";

const getCourseworkYearValue = (year) => {
  switch (year) {
    case 9:
      return "{\"code\":\"9TH\",\"value\":\"9th or earlier\"}";
    case 10:
      return "{\"code\":\"10TH\",\"value\":\"10th or earlier\"}";
    case 11:
      return "{\"code\":\"11TH\",\"value\":\"11th or earlier\"}";
    case 12:
      return "{\"code\":\"12TH\",\"value\":\"12th or earlier\"}";
    default:
      return "";
  }
};

const selectorTitleAndKeyMap = {
  suffix_selector: "suffixes",
  cbo_selector: "organizations",
  state_selector: "states",
  phone_type_selector: "phoneTypes",
  country_selector: "countries",
  sibling_relationship_selector: "siblingRelationships",
  mobile_country_code_selector: "mobileCountryCodes",
  language_selector: "languages",
  visa_type_selector: "visaTypes",
  citizenship_selector: "citizenships",
  college_degree_selector: "collegeDegrees",
  ap_subject_selector: "apSubjects",
  ib_subject_selector: "ibSubjects",
  honor_recognition_level_selector: "honorRecognitionLevels",
  grade_level_selector: "gradeLevels",
  activity_type_selector: "activityTypes",
  hispanic_selector: "hispanics",
  black_background_selector: "blackBackgrounds",
  white_background_selector: "whiteBackgrounds",
  asian_background_selector: "asianBackgrounds",
  gpa_types_selector: "gpaTypes",
  gpa_scales_selector: "gpaScales",
  parents_marital_status_selector: "parentMaritalStatuses",
  who_your_permanent_home_selector: "whoYourPermanentHome",
  tribal_affiliation_selector: "tribalAffiliations",
  classes_scheduled_selector: "classesScheduled",
  grading_scale_selector: "gradingScales",
  letters_grading_selector: "lettersGrading",
  sat_subjects_selector: "satSubjects"
};

const state = {
  counselingProfile: new CounselingProfile(),
  currentApplicationType: "",
  suffixes: [],
  organizations: [],
  states: [],
  phoneTypes: [],
  countries: [],
  siblingRelationships: [],
  mobileCountryCodes: [],
  languages: [],
  visaTypes: [],
  citizenships: [],
  countriesOfFilterHighSchool: [],
  highSchools: [],
  colleges: [],
  collegeDegrees: [],
  apSubjects: [],
  ibSubjects: [],
  gradeLevels: [],
  honorRecognitionLevels: [],
  activityTypes: [],
  hispanics: [],
  blackBackgrounds: [],
  whiteBackgrounds: [],
  asianBackgrounds: [],
  gpaTypes: [],
  gpaScales: [],
  parentMaritalStatuses: [],
  whoYourPermanentHome: [],
  tribalAffiliations: [],
  classesScheduled: [],
  gradingScales: [],
  lettersGrading: [],
  satSubjects: [],
  awardLevels: [
    { value: 1, label: "School" },
    { value: 2, label: "City / Community" },
    { value: 3, label: "State" },
    { value: 4, label: "Regional" },
    { value: 5, label: "National" },
    { value: 6, label: "International" }
  ],
  awardGrades: [
    { value: 1, label: "9th grade" },
    { value: 2, label: "10th grade" },
    { value: 3, label: "11th grade" },
    { value: 4, label: "12th grade" },
    { value: 5, label: "After 12th grade" }
  ]
};

const getters = {
  counselingProfile(state) {
    return state.counselingProfile;
  },
  countriesOfFilterHighSchool(state) {
    return state.countriesOfFilterHighSchool;
  },
  highSchoolOptions(state) {
    return state.highSchools;
  },
  collegeOptions(state) {
    return state.colleges;
  },
  suffixOptions(state) {
    return state.suffixes;
  },
  organizationOptions(state) {
    return state.organizations;
  },
  stateOptions(state) {
    return state.states;
  },
  phoneTypeOptions(state) {
    return state.phoneTypes;
  },
  countryOptions(state) {
    return state.countries;
  },
  siblingRelationshipOptions(state) {
    return state.siblingRelationships;
  },
  mobileCountryCodeOptions(state) {
    return state.mobileCountryCodes;
  },
  languageOptions(state) {
    return state.languages;
  },
  visaTypeOptions(state) {
    return state.visaTypes;
  },
  citizenshipOptions(state) {
    return state.citizenships;
  },
  collegeDegreeOptions(state) {
    return state.collegeDegrees;
  },
  apSubjectOptions(state) {
    return state.apSubjects;
  },
  ibSubjectOptions(state) {
    return state.ibSubjects;
  },
  gradeLevelOptions(state) {
    return state.gradeLevels;
  },
  honorRecognitionLevelOptions(state) {
    return state.honorRecognitionLevels;
  },
  activityTypeOptions(state) {
    return state.activityTypes;
  },
  hispanicsOptions(state) {
    return state.hispanics;
  },
  blackBackgroundOptions(state) {
    return state.blackBackgrounds;
  },
  whiteBackgroundOptions(state) {
    return state.whiteBackgrounds;
  },
  asianBackgroundOptions(state) {
    return state.asianBackgrounds;
  },
  gpaTypeOptions(state) {
    return state.gpaTypes;
  },
  gpaScaleOptions(state) {
    return state.gpaScales;
  },
  parentMaritalStatusOptions(state) {
    return state.parentMaritalStatuses;
  },
  whoYourPermanentHomeOptions(state) {
    return state.whoYourPermanentHome;
  },
  tribalAffiliationOptions(state) {
    return state.tribalAffiliations;
  },
  classScheduledOptions(state) {
    return state.classesScheduled;
  },
  gradingScaleOptions(state) {
    return state.gradingScales;
  },
  letterGradingOptions(state) {
    return state.lettersGrading.map(({ id, title }) => ({
      value: String(id),
      label: title
    }));
  },
  satSubjects(state) {
    return state.satSubjects;
  },
  awardLevelOptions(state) {
    return state.awardLevels;
  },
  awardGradeOptions(state) {
    return state.awardGrades;
  }
};

const mutations = {
  setOptions(state, options) {
    const optionKey = Object.keys(options)[0];
    state[optionKey] = options[optionKey];
  },
  setCurrentApplicationType(state, currentApplicationType) {
    state.currentApplicationType = currentApplicationType;
    for (const selectorTitleKey in selectorTitleAndKeyMap) {
      state[selectorTitleAndKeyMap[selectorTitleKey]] = [];
    };
  },
  setCountriesOfFilterHighSchool(state, countriesOfFilterHighSchool) {
    state.countriesOfFilterHighSchool = countriesOfFilterHighSchool;
  },
  setHighSchoolsOptions(state, highSchools) {
    state.highSchools = highSchools;
  },
  setCollegesOptions(state, colleges) {
    state.colleges = colleges;
  },
  setCounselingProfile(state, data) {
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      ...data
    });
  },
  setAddress(state, data) {
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      address: new Address({
        ...state.counselingProfile.address,
        ...data
      })
    });
  },
  setMailingAddress(state, data) {
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      mailing_address: new Address({
        ...state.counselingProfile.mailing_address,
        ...data
      })
    });
  },
  setPreferredPhone(state, data) {
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      preferred_phone_number: new PhoneNumber({
        ...state.counselingProfile.preferred_phone_number,
        ...data
      })
    });
  },
  setAlternatePhone(state, data) {
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      alternate_phone_number: new PhoneNumber({
        ...state.counselingProfile.alternate_phone_number,
        ...data
      })
    });
  },
  setLanguage(state, { languageIndex, data }) {
    state.counselingProfile.languages[languageIndex] = new Language({
      ...state.counselingProfile.languages[languageIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      languages: state.counselingProfile.languages
    });
  },
  setMilitaryExperience(state, { militaryExperienceIndex, data }) {
    state.counselingProfile.us_military_experiences[militaryExperienceIndex] = new MilitaryExperience({
      ...state.counselingProfile.us_military_experiences[militaryExperienceIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      us_military_experiences: state.counselingProfile.us_military_experiences
    });
  },
  setParent(state, { parentIndex, data }) {
    state.counselingProfile.parents[parentIndex] = new Parent({
      ...state.counselingProfile.parents[parentIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      parents: state.counselingProfile.parents
    });
  },
  setPhoneOfParent(state, { parentIndex, data }) {
    state.counselingProfile.parents[parentIndex].preferred_phone_number = new PhoneNumber({
      ...state.counselingProfile.parents[parentIndex].preferred_phone_number,
      ...data
    });
    state.counselingProfile = new CounselingProfile(state.counselingProfile);
  },
  setAddressOfParent(state, { parentIndex, data }) {
    state.counselingProfile.parents[parentIndex].address = new Address({
      ...state.counselingProfile.parents[parentIndex].address,
      ...data
    });
    state.counselingProfile = new CounselingProfile(state.counselingProfile);
  },
  setCollegeOfParent(state, { parentIndex, collegeIndex, data }) {
    state.counselingProfile.parents[parentIndex].colleges[collegeIndex] = new ParentCollege({
      ...state.counselingProfile.parents[parentIndex].colleges[collegeIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      parents: state.counselingProfile.parents
    });
  },
  setDegreeOfParentCollege(state, { parentIndex, collegeIndex, degreeIndex, data }) {
    state.counselingProfile.parents[parentIndex].colleges[collegeIndex].degrees[degreeIndex] = new Degree({
      ...state.counselingProfile.parents[parentIndex].colleges[collegeIndex].degrees[degreeIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      parents: state.counselingProfile.parents
    });
  },
  setLegalGuardian(state, { legalGuardianIndex, data }) {
    state.counselingProfile.legal_guardians[legalGuardianIndex] = new LegalGuardian({
      ...state.counselingProfile.legal_guardians[legalGuardianIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      legal_guardians: state.counselingProfile.legal_guardians
    });
  },
  setLegalGuardianCollegeEmployer(state, { legalGuardianIndex, data }) {
    state.counselingProfile.legal_guardians[legalGuardianIndex].college_employer = new Address({
      ...state.counselingProfile.legal_guardians[legalGuardianIndex].college_employer,
      ...data
    });

    state.counselingProfile = new CounselingProfile(state.counselingProfile);
  },
  setCollegeOfLegalGuardian(state, { legalGuardianIndex, collegeIndex, data }) {
    state.counselingProfile.legal_guardians[legalGuardianIndex].colleges[collegeIndex] = new LegalGuardianCollege({
      ...state.counselingProfile.legal_guardians[legalGuardianIndex].colleges[collegeIndex],
      ...data
    });

    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile
    });
  },
  setDegreeOfLegalGuardianCollege(
    state,
    { legalGuardianIndex, collegeIndex, degreeIndex, data }
  ) {
    state.counselingProfile.legal_guardians[legalGuardianIndex].colleges[collegeIndex].degrees[degreeIndex] = new Degree({
      ...state.counselingProfile.legal_guardians[legalGuardianIndex].colleges[collegeIndex].degrees[degreeIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile
    });
  },
  setPhoneOfLegalGuardian(state, { legalGuardianIndex, data }) {
    state.counselingProfile.legal_guardians[legalGuardianIndex].preferred_phone_number = new PhoneNumber({
      ...state.counselingProfile.legal_guardians[legalGuardianIndex].preferred_phone_number,
      ...data
    });
    state.counselingProfile = new CounselingProfile(state.counselingProfile);
  },
  setAddressOfLegalGuardian(state, { legalGuardianIndex, data }) {
    state.counselingProfile.legal_guardians[legalGuardianIndex].address = new Address({
      ...state.counselingProfile.legal_guardian_address,
      ...data
    });

    state.counselingProfile = new CounselingProfile(state.counselingProfile);
  },
  setSibling(state, { siblingIndex, data }) {
    state.counselingProfile.siblings[siblingIndex] = new Sibling({
      ...state.counselingProfile.siblings[siblingIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      siblings: state.counselingProfile.siblings
    });
  },
  setCollegeOfSibling(state, { siblingIndex, data }) {
    state.counselingProfile.siblings[siblingIndex].college = new SiblingCollege({
      ...state.counselingProfile.siblings[siblingIndex].college,
      ...data
    });
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile
    });
  },
  setDegreeOfSiblingCollege(state, { siblingIndex, data }) {
    state.counselingProfile.siblings[siblingIndex].college.degree = new SiblingCollegeDegree({
      ...state.counselingProfile.siblings[siblingIndex].college.degree,
      ...data
    });
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      siblings: state.counselingProfile.siblings
    });
  },
  setOtherHighSchool(state, { otherHighSchoolIndex, data }) {
    state.counselingProfile.other_high_schools[otherHighSchoolIndex] = new OtherHighSchool({
      ...state.counselingProfile.other_high_schools[otherHighSchoolIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      other_high_schools: state.counselingProfile.other_high_schools
    });
  },
  setAttendDateOfOtherHighSchool(state, { otherHighSchoolIndex, attendDateIndex, data }) {
    state.counselingProfile.other_high_schools[otherHighSchoolIndex] = new OtherHighSchool({
      ...state.counselingProfile.other_high_schools[otherHighSchoolIndex],
      attend_dates: state.counselingProfile.other_high_schools[otherHighSchoolIndex].attend_dates
        .map((attendDate, index) => {
          if (attendDateIndex === index) {
            return { ...attendDate, ...data };
          } else {
            return attendDate;
          }
        })
    });
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      other_high_schools: state.counselingProfile.other_high_schools
    });
  },
  setAttendDateOfCollege(state, { takenCollegeIndex, attendDateIndex, data }) {
    state.counselingProfile.colleges[takenCollegeIndex] = new TakenCollege({
      ...state.counselingProfile.colleges[takenCollegeIndex],
      attend_dates: state.counselingProfile.colleges[takenCollegeIndex].attend_dates
        .map((attendDate, index) => {
          if (attendDateIndex === index) {
            return { ...attendDate, ...data };
          } else {
            return attendDate;
          }
        })
    });
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      colleges: state.counselingProfile.colleges
    });
  },
  setGradeOfCollege(state, { takenCollegeIndex, gradeIndex, data }) {
    state.counselingProfile.colleges[takenCollegeIndex].grades[gradeIndex] = new TakenCollegeGrade({
      ...state.counselingProfile.colleges[takenCollegeIndex].grades[gradeIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      colleges: state.counselingProfile.colleges
    });
  },
  setTakenCollege(state, { takenCollegeIndex, data }) {
    state.counselingProfile.colleges[takenCollegeIndex] = new TakenCollege({
      ...state.counselingProfile.colleges[takenCollegeIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      colleges: state.counselingProfile.colleges
    });
  },
  setRecentCourse(state, { recentCourseIndex, data }) {
    state.counselingProfile.courses[recentCourseIndex] = new RecentCourse({
      ...state.counselingProfile.courses[recentCourseIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      courses: state.counselingProfile.courses
    });
  },
  setHonor(state, { honorIndex, data }) {
    state.counselingProfile.honors[honorIndex] = new Honor({
      ...state.counselingProfile.honors[honorIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      honors: state.counselingProfile.honors
    });
  },
  setOrganization(state, { organizationIndex, data }) {
    state.counselingProfile.community_based_organizations[organizationIndex] = new Organization({
      ...state.counselingProfile.community_based_organizations[organizationIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile(state.counselingProfile);
  },
  setPhoneOfOrganization(state, { organizationIndex, data }) {
    state.counselingProfile.community_based_organizations[organizationIndex].mentor_phone_number = new PhoneNumber({
      ...state.counselingProfile.community_based_organizations[organizationIndex].mentor_phone_number,
      ...data
    });
    state.counselingProfile = new CounselingProfile(state.counselingProfile);
  },
  setFutureActSittingMonth(state, { futureActSittingMonthIndex, futureActSittingMonth }) {
    let newFutureActSittingMonths = [...state.counselingProfile.future_act_sitting_months];
    newFutureActSittingMonths[futureActSittingMonthIndex] = futureActSittingMonth;

    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      future_act_sitting_months: newFutureActSittingMonths
    });
  },
  setFutureSatSittingMonth(state, { futureSatSittingMonthIndex, futureSatSittingMonth }) {
    let newFutureSatSittingMonths = [...state.counselingProfile.future_sat_sitting_months];
    newFutureSatSittingMonths[futureSatSittingMonthIndex] = futureSatSittingMonth;

    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      future_sat_sitting_months: newFutureSatSittingMonths
    });
  },
  setSatSubject(state, { satSubjectIndex, data }) {
    state.counselingProfile.sats[satSubjectIndex] = new SatSubject({
      ...state.counselingProfile.sats[satSubjectIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile(state.counselingProfile);
  },
  setApSubject(state, { apSubjectIndex, data }) {
    state.counselingProfile.aps[apSubjectIndex] = new ApSubject({
      ...state.counselingProfile.aps[apSubjectIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile(state.counselingProfile);
  },
  setIbSubject(state, { ibSubjectIndex, data }) {
    state.counselingProfile.ibs[ibSubjectIndex] = new IbSubject({
      ...state.counselingProfile.ibs[ibSubjectIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile(state.counselingProfile);
  },
  setDuolingo(state, { duolingoIndex, data }) {
    state.counselingProfile.duolingoes[duolingoIndex] = new Duolingo({
      ...state.counselingProfile.duolingoes[duolingoIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile(state.counselingProfile);
  },
  setOtherProficiencyExam(state, { otherProficiencyExamIndex, data }) {
    state.counselingProfile.other_proficiency_exams[otherProficiencyExamIndex] = new OtherProficiencyExam({
      ...state.counselingProfile.other_proficiency_exams[otherProficiencyExamIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile(state.counselingProfile);
  },
  setALevelSubject(state, { aLevelIndex, data }) {
    state.counselingProfile.a_levels[aLevelIndex] = new ALevelSubject({
      ...state.counselingProfile.a_levels[aLevelIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile(state.counselingProfile);
  },
  setFutureToeflIbtSittingMonth(state, { futureToeflIbtSittingMonthIndex, futureToeflIbtSittingMonth }) {
    let newFutureToeflsSittingMonths = [...state.counselingProfile.future_toefls_sitting_months];
    newFutureToeflsSittingMonths[futureToeflIbtSittingMonthIndex] = futureToeflIbtSittingMonth;

    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      future_toefls_sitting_months: newFutureToeflsSittingMonths
    });
  },
  setFutureToeflPaperSittingMonth(state, { futureToeflPaperSittingMonthIndex, futureToeflPaperSittingMonth }) {
    let newFutureToeflPaperSittingMonths = [...state.counselingProfile.future_toefl_papers_sitting_months];
    newFutureToeflPaperSittingMonths[futureToeflPaperSittingMonthIndex] = futureToeflPaperSittingMonth;

    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      future_toefl_papers_sitting_months: newFutureToeflPaperSittingMonths
    });
  },
  setFuturePtesPapersSittingMonth(state, { futurePtesSittingMonthIndex, futurePtesSittingMonth }) {
    let newFuturePtesSittingMonths = [...state.counselingProfile.future_ptes_sitting_months];
    newFuturePtesSittingMonths[futurePtesSittingMonthIndex] = futurePtesSittingMonth;

    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      future_ptes_sitting_months: newFuturePtesSittingMonths
    });
  },
  setFutureIeltsPapersSittingMonth(state, { futureIeltsSittingMonthIndex, futureIeltsSittingMonth }) {
    let newFutureIeltsSittingMonths = [...state.counselingProfile.future_ielts_sitting_months];
    newFutureIeltsSittingMonths[futureIeltsSittingMonthIndex] = futureIeltsSittingMonth;

    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      future_ielts_sitting_months: newFutureIeltsSittingMonths
    });
  },
  setFutureDuolingoesSittingMonth(state, { futureDuolingoesSittingMonthIndex, futureDuolingoesSittingMonth }) {
    let newFutureDuolingoesSittingMonths = [...state.counselingProfile.future_duolingoes_sitting_months];
    newFutureDuolingoesSittingMonths[futureDuolingoesSittingMonthIndex] = futureDuolingoesSittingMonth;

    state.counselingProfile = new CounselingProfile({
      ...state.counselingProfile,
      future_duolingoes_sitting_months: newFutureDuolingoesSittingMonths
    });
  },
  setLeavingExam(state, { leaveExamIndex, data }) {
    state.counselingProfile.leaving_exams[leaveExamIndex] = new LeavingExam({
      ...state.counselingProfile.leaving_exams[leaveExamIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile(state.counselingProfile);
  },
  setActivity(state, { activityIndex, data }) {
    state.counselingProfile.activities[activityIndex] = new Activity({
      ...state.counselingProfile.activities[activityIndex],
      ...data
    });
    state.counselingProfile = new CounselingProfile(state.counselingProfile);
  },
  setCaliforniaAppEssayContent(state, { essayPromptIndex, essayContent }) {
    state.counselingProfile.california_app_essay_contents[essayPromptIndex] = essayContent;
    state.counselingProfile = new CounselingProfile(state.counselingProfile);
  }
};

const actions = {
  removeLastMilitaryExperience({ state, commit }) {
    state.counselingProfile.us_military_experiences.pop();
    const resetUsMilitaryExperiences = [
      ...state.counselingProfile.us_military_experiences,
      new MilitaryExperience()
    ];
    commit(
      "setCounselingProfile",
      new CounselingProfile({
        ...state.counselingProfile,
        us_military_experiences: resetUsMilitaryExperiences
      })
    );
  },
  moveMilitaryExperienceOrderUp({ state, commit }, { militaryExperienceCountIndex }) {
    [
      state.counselingProfile.us_military_experiences[militaryExperienceCountIndex - 1],
      state.counselingProfile.us_military_experiences[militaryExperienceCountIndex]
    ] = [
      state.counselingProfile.us_military_experiences[militaryExperienceCountIndex],
      state.counselingProfile.us_military_experiences[militaryExperienceCountIndex - 1]
    ];

    commit(
      "setCounselingProfile",
      new CounselingProfile(state.counselingProfile)
    );
  },
  moveMilitaryExperienceOrderDown({ state, commit }, { militaryExperienceCountIndex }) {
    [
      state.counselingProfile.us_military_experiences[militaryExperienceCountIndex + 1],
      state.counselingProfile.us_military_experiences[militaryExperienceCountIndex]
    ] = [
      state.counselingProfile.us_military_experiences[militaryExperienceCountIndex],
      state.counselingProfile.us_military_experiences[militaryExperienceCountIndex + 1]
    ];

    commit(
      "setCounselingProfile",
      new CounselingProfile(state.counselingProfile)
    );
  },
  setMathCourse78th({ state, commit }, { mathCourse78thIndex, mathCourse78th }) {
    const workingMathCourses78th = state.counselingProfile.math_courses_78th;
    workingMathCourses78th[mathCourse78thIndex] = new MathCourse78th({
      ...workingMathCourses78th[mathCourse78thIndex],
      ...mathCourse78th
    });
    commit("setCounselingProfile", 
      new CounselingProfile({
        ...state.counselingProfile,
        math_courses_78th: workingMathCourses78th
      })
    );
  },
  removeMathCourse78th({ state, commit }, mathCourse78thIndex) {
    const workingMathCourses78th = state.counselingProfile.math_courses_78th;
    workingMathCourses78th.splice(mathCourse78thIndex, 1);
    commit("setCounselingProfile", 
      new CounselingProfile({
        ...state.counselingProfile,
        math_courses_78th: workingMathCourses78th
      })
    );
  },
  addMathCourse78th({ state, commit }) {
    const workingMathCourses78th = state.counselingProfile.math_courses_78th;
    workingMathCourses78th.push(new MathCourse78th());
    commit("setCounselingProfile", 
      new CounselingProfile({
        ...state.counselingProfile,
        math_courses_78th: workingMathCourses78th
      })
    );
  },
  setLanguageCourse78th({ state, commit }, { languageCourse78thIndex, languageCourse78th }) {
    const workingLanguageCourses78th = state.counselingProfile.language_courses_78th;
    workingLanguageCourses78th[languageCourse78thIndex] = new LanguageCourse78th({
      ...workingLanguageCourses78th[languageCourse78thIndex],
      ...languageCourse78th
    });
    commit("setCounselingProfile", 
      new CounselingProfile({
        ...state.counselingProfile,
        language_courses_78th: workingLanguageCourses78th
      })
    );
  },
  removeLanguageCourse78th({ state, commit }, languageCourse78thIndex) {
    const workingLanguageCourses78th = state.counselingProfile.language_courses_78th;
    workingLanguageCourses78th.splice(languageCourse78thIndex, 1);
    commit("setCounselingProfile", 
      new CounselingProfile({
        ...state.counselingProfile,
        language_courses_78th: workingLanguageCourses78th
      })
    );
  },
  addLanguageCourse78th({ state, commit }) {
    const workingLanguageCourses78th = state.counselingProfile.language_courses_78th;
    workingLanguageCourses78th.push(new LanguageCourse78th());
    commit("setCounselingProfile", 
      new CounselingProfile({
        ...state.counselingProfile,
        language_courses_78th: workingLanguageCourses78th
      })
    );
  },
  setHighSchoolAcadYear({ state, commit }, { index, highSchoolAcadYear }) {
    state.counselingProfile.high_school_acad_years[index] = new HighSchoolAcadYear({
      ...state.counselingProfile.high_school_acad_years[index],
      ...highSchoolAcadYear
    });
    commit("setCounselingProfile", state.counselingProfile);
  },
  addHighSchoolAnotherAcadYear({ state, commit }) {
    state.counselingProfile.high_school_acad_years.push(
      new HighSchoolAcadYear()
    );
    commit("setCounselingProfile", state.counselingProfile);
  },
  setOtherHighSchoolAcadYear({ state, commit }, { otherHighSchoolIndex, index, highSchoolAcadYear }) {
    state.counselingProfile.other_high_schools[otherHighSchoolIndex].acad_years[index] = new HighSchoolAcadYear({
      ...state.counselingProfile.other_high_schools[otherHighSchoolIndex].acad_years[index],
      ...highSchoolAcadYear
    });

    commit("setCounselingProfile", state.counselingProfile);
  },
  addOtherHighSchoolAnotherAcadYear({ state, commit }, { otherHighSchoolIndex }) {
    state.counselingProfile.other_high_schools[otherHighSchoolIndex].acad_years.push(
      new HighSchoolAcadYear()
    );
    commit("setCounselingProfile", state.counselingProfile);
  },
  addOtherHighSchoolAttendDate({ state, commit }, otherHighSchoolIndex) {
    const addedAttendDates = [
      ...state.counselingProfile.other_high_schools[otherHighSchoolIndex].attend_dates,
      { from_month: "", to_month: "" }
    ];
    commit("setOtherHighSchool", { otherHighSchoolIndex, data: { attend_dates: addedAttendDates }});
  },
  removeOtherHighSchoolAttendDate({ state, commit }, { otherHighSchoolIndex, removeAttendDateIndex}) {
    const workAttendDates = [
      ...state.counselingProfile.other_high_schools[otherHighSchoolIndex].attend_dates
    ];
    workAttendDates.splice(removeAttendDateIndex, 1);
    commit("setOtherHighSchool", { otherHighSchoolIndex, data: { attend_dates: workAttendDates }});
  },
  addCollegeAttendDate({ state, commit }, takenCollegeIndex) {
    const addedAttendDates = [
      ...state.counselingProfile.colleges[takenCollegeIndex].attend_dates,
      { from_month: "", to_month: "" }
    ];
    commit("setTakenCollege", { takenCollegeIndex, data: { attend_dates: addedAttendDates }});
  },
  removeCollegeAttendDate({ state, commit }, { takenCollegeIndex, removeAttendDateIndex}) {
    const workAttendDates = [
      ...state.counselingProfile.colleges[takenCollegeIndex].attend_dates
    ];
    workAttendDates.splice(removeAttendDateIndex, 1);
    commit("setTakenCollege", { takenCollegeIndex, data: { attend_dates: workAttendDates }});
  },
  addCollegeGrade({ state, commit }, takenCollegeIndex) {
    const addedGrades = [
      ...state.counselingProfile.colleges[takenCollegeIndex].grades,
      new TakenCollegeGrade()
    ];
    commit("setTakenCollege", { takenCollegeIndex, data: { grades: addedGrades }});
  },
  removeCollegeGrade({ state, commit }, { takenCollegeIndex, removeGradeIndex}) {
    const workGrades = [
      ...state.counselingProfile.colleges[takenCollegeIndex].grades
    ];
    workGrades.splice(removeGradeIndex, 1);
    commit("setTakenCollege", { takenCollegeIndex, data: { grades: workGrades }});
  },
  addGradeCourseworkOfHighSchool({ state, commit }, { gradeCoursework }) {
    const addedCourses = [
      ...state.counselingProfile.high_school_courses,
      new HighSchoolCourse(gradeCoursework)
    ];
    commit("setCounselingProfile", { high_school_courses: addedCourses });
  },
  addGradeCourseworkOfOtherHighSchool({ state, commit }, { otherHighSchoolIndex, gradeCoursework }) {
    const addedCourses = [
      ...state.counselingProfile.other_high_schools[otherHighSchoolIndex].courses,
      new HighSchoolCourse(gradeCoursework)
    ];
    commit("setOtherHighSchool", { otherHighSchoolIndex, data: { courses: addedCourses }});
  },
  saveGradeCourseworkOfHighSchool({ state, commit }, { targetYear, courseIndex, coursework }) {
    const exceptTargetYearCourses = state.counselingProfile.high_schools[0].courses
      .filter(({ year }) => year !== getCourseworkYearValue(targetYear));
    const targetYearCourses = state.counselingProfile.high_schools[0].courses
      .filter(({ year }) => year === getCourseworkYearValue(targetYear));
    targetYearCourses[courseIndex] = new HighSchoolCourse(coursework);
    const workCourses = [...exceptTargetYearCourses, ...targetYearCourses];
    commit("setCounselingProfile", { high_school_courses: workCourses });
  },
  saveGradeCourseworkOfOtherHighSchool({ state, commit }, { otherHighSchoolIndex, targetYear, courseIndex, coursework }) {
    const exceptTargetYearCourses = state.counselingProfile.other_high_schools[otherHighSchoolIndex].courses
      .filter(({ year }) => year !== getCourseworkYearValue(targetYear));
    const targetYearCourses = state.counselingProfile.other_high_schools[otherHighSchoolIndex].courses
      .filter(({ year }) => year === getCourseworkYearValue(targetYear));
    targetYearCourses[courseIndex] = new HighSchoolCourse(coursework);
    const workCourses = [...exceptTargetYearCourses, ...targetYearCourses];
    commit("setOtherHighSchool", { otherHighSchoolIndex, data: { courses: workCourses }});
  },
  deleteGradeCourseworkOfHighSchool({ state, commit }, { targetYear, courseIndex }) {
    const exceptTargetYearCourses = state.counselingProfile.high_schools[0].courses
      .filter(({ year }) => year !== getCourseworkYearValue(targetYear));
    const targetYearCourses = state.counselingProfile.high_schools[0].courses
      .filter(({ year }) => year === getCourseworkYearValue(targetYear));
    targetYearCourses.splice(courseIndex, 1);
    const workCourses = [...exceptTargetYearCourses, ...targetYearCourses];
    commit("setCounselingProfile", { high_school_courses: workCourses });
  },
  deleteGradeCourseworkOfOtherHighSchool({ state, commit }, { otherHighSchoolIndex, targetYear, courseIndex }) {
    const exceptTargetYearCourses = state.counselingProfile.other_high_schools[otherHighSchoolIndex].courses
      .filter(({ year }) => year !== getCourseworkYearValue(targetYear));
    const targetYearCourses = state.counselingProfile.other_high_schools[otherHighSchoolIndex].courses
      .filter(({ year }) => year === getCourseworkYearValue(targetYear));
    targetYearCourses.splice(courseIndex, 1);
    const workCourses = [...exceptTargetYearCourses, ...targetYearCourses];

    commit("setOtherHighSchool", { otherHighSchoolIndex, data: { courses: workCourses }});
  },
  addCompletedGradeCourseworkOfCollege({ state, commit }, { takenCollegeIndex, gradeCoursework }) {
    const addedCourses = [
      ...state.counselingProfile.colleges[takenCollegeIndex].completed_courses,
      new CompletedCollegeCourse(gradeCoursework)
    ];
    commit("setTakenCollege", { takenCollegeIndex, data: { completed_courses: addedCourses }});
  },
  saveCompletedGradeCourseworkOfCollege({ state, commit }, { takenCollegeIndex, originalCoursework, newCoursework }) {
    const updatedCourses = state.counselingProfile.colleges[takenCollegeIndex].completed_courses
      .map((course) => course === originalCoursework ? newCoursework : course);
    commit("setTakenCollege", { takenCollegeIndex, data: { completed_courses: updatedCourses }});
  },
  deleteCompletedGradeCourseworkOfCollege({ state, commit }, { takenCollegeIndex, coursework }) {
    const deletedCourses = state.counselingProfile.colleges[takenCollegeIndex].completed_courses.
      filter((course) => course !== coursework);

    commit("setTakenCollege", {
      takenCollegeIndex,
      data: { completed_courses: deletedCourses }
    });
  },
  addInProgressGradeCourseworkOfCollege({ state, commit }, { takenCollegeIndex, gradeCoursework }) {
    const addedCourses = [
      ...state.counselingProfile.colleges[takenCollegeIndex].in_progress_courses,
      new InProgressCollegeCourse(gradeCoursework)
    ];
    commit("setTakenCollege", { takenCollegeIndex, data: { in_progress_courses: addedCourses }});
  },
  saveInProgressGradeCourseworkOfCollege({ state, commit }, { takenCollegeIndex, originalCoursework, newCoursework }) {
    const updatedCourses = state.counselingProfile.colleges[takenCollegeIndex].in_progress_courses
      .map((course) => course === originalCoursework ? newCoursework : course);
    commit("setTakenCollege", { takenCollegeIndex, data: { in_progress_courses: updatedCourses }});
  },
  deleteInProgressGradeCourseworkOfCollege({ state, commit }, { takenCollegeIndex, coursework }) {
    const deletedCourses = state.counselingProfile.colleges[takenCollegeIndex].in_progress_courses.
      filter((course) => course !== coursework);

    commit("setTakenCollege", {
      takenCollegeIndex,
      data: { in_progress_courses: deletedCourses }
    });
  },
  moveHonorOrderUp({ state, commit }, { honorIndex }) {
    [
      state.counselingProfile.honors[honorIndex - 1],
      state.counselingProfile.honors[honorIndex]
    ] = [
      state.counselingProfile.honors[honorIndex],
      state.counselingProfile.honors[honorIndex - 1]
    ];

    commit(
      "setCounselingProfile",
      new CounselingProfile(state.counselingProfile)
    );
  },
  moveHonorOrderDown({ state, commit }, { honorIndex }) {
    [
      state.counselingProfile.honors[honorIndex + 1],
      state.counselingProfile.honors[honorIndex]
    ] = [
      state.counselingProfile.honors[honorIndex],
      state.counselingProfile.honors[honorIndex + 1]
    ];

    commit(
      "setCounselingProfile",
      new CounselingProfile(state.counselingProfile)
    );
  },
  removeLastHonor({ state, commit }) {
    state.counselingProfile.honors.pop();
    const resetHonors = [
      ...state.counselingProfile.honors,
      new Honor()
    ];
    commit(
      "setCounselingProfile",
      new CounselingProfile({
        ...state.counselingProfile,
        honors: resetHonors
      })
    );
  },
  moveActivityOrderUp({ state, commit }, { activityIndex }) {
    [
      state.counselingProfile.activities[activityIndex - 1],
      state.counselingProfile.activities[activityIndex]
    ] = [
      state.counselingProfile.activities[activityIndex],
      state.counselingProfile.activities[activityIndex - 1]
    ];

    commit(
      "setCounselingProfile",
      new CounselingProfile(state.counselingProfile)
    );
  },
  moveActivityOrderDown({ state, commit }, { activityIndex }) {
    [
      state.counselingProfile.activities[activityIndex + 1],
      state.counselingProfile.activities[activityIndex]
    ] = [
      state.counselingProfile.activities[activityIndex],
      state.counselingProfile.activities[activityIndex + 1]
    ];

    commit(
      "setCounselingProfile",
      new CounselingProfile(state.counselingProfile)
    );
  },
  removeLastActivity({ state, commit }) {
    state.counselingProfile.activities.pop();
    const resetActivities = [
      ...state.counselingProfile.activities,
      new Activity()
    ];
    commit(
      "setCounselingProfile",
      new CounselingProfile({
        ...state.counselingProfile,
        activities: resetActivities
      })
    );
  },
  addActivityOrAward({ state, commit }, activityOrAward) {
    const workingActivitiesOrAwards = state.counselingProfile.activities_or_awards;
    commit("setCounselingProfile", 
      new CounselingProfile({
        ...state.counselingProfile,
        activities_or_awards: [
          ...workingActivitiesOrAwards,
          ActivityOrAwardFactory.createActivityOrAward(activityOrAward)
        ]
      })
    );
  },
  saveActivityOrAward({ state, commit }, { editingActivityOrAwardIndex, newActivityOrAward }) {
    const workingActivitiesOrAwards = state.counselingProfile.activities_or_awards.map((activitiesOrAwards, index) => {
      if (index === editingActivityOrAwardIndex) {
        return ActivityOrAwardFactory.createActivityOrAward(newActivityOrAward);
      } else {
        return ActivityOrAwardFactory.createActivityOrAward(editingActivityOrAwardIndex);
      }
    });
    commit("setCounselingProfile", 
      new CounselingProfile({
        ...state.counselingProfile,
        activities_or_awards: workingActivitiesOrAwards
      })
    );
  },
  removeActivitiesOrAward({ state, commit }, targetActivitiesOrAward) {
    commit("setCounselingProfile", 
      new CounselingProfile({
        ...state.counselingProfile,
        activities_or_awards: state.counselingProfile.activities_or_awards.filter((activitiesOrAward) => (
          activitiesOrAward !== targetActivitiesOrAward
        ))
      })
    );
  },
  setAcademicInterest({ state, commit }, { academicInterestIndex, academicInterest }) {
    const workingAcademicInterests = state.counselingProfile.academic_interests;
    workingAcademicInterests[academicInterestIndex] = academicInterest;
    commit("setCounselingProfile", 
      new CounselingProfile({
        ...state.counselingProfile,
        academic_interests: workingAcademicInterests
      })
    );
  },
  removeAcademicInterest({ state, commit }, academicInterestIndex) {
    const workingAcademicInterests = state.counselingProfile.academic_interests;
    workingAcademicInterests.splice(academicInterestIndex, 1);
    commit("setCounselingProfile", 
      new CounselingProfile({
        ...state.counselingProfile,
        academic_interests: workingAcademicInterests
      })
    );
  },
  addAcademicInterest({ state, commit }) {
    const workingAcademicInterests = state.counselingProfile.academic_interests;
    workingAcademicInterests.push("");
    commit("setCounselingProfile", 
      new CounselingProfile({
        ...state.counselingProfile,
        academic_interests: workingAcademicInterests
      })
    );
  },
  async fetchCountriesOfFilterHighSchool({ state, commit }) {
    if (state.countriesOfFilterHighSchool.length === 0) {
      const {
        counseling_country_options: countriesOfFilterHighSchool
      } = await counselingProfileApi.fetchCountriesOfFilterHighSchool();
      commit("setCountriesOfFilterHighSchool", countriesOfFilterHighSchool);
    }
  },
  async fetchHighSchoolsOfCounselingProfile({ commit }, {searchText, filterState, filterCountry}) {
    const {
      counseling_selector_options: highSchools
    } = await counselingProfileApi.fetchHighSchoolsOfCounselingProfile(searchText, filterState, filterCountry);
    commit("setHighSchoolsOptions", highSchools);
  },
  async fetchCollegesOfCounselingProfile({ commit }, searchText) {
    const {
      counseling_selector_options: colleges
    } = await counselingProfileApi.fetchCollegesOfCounselingProfile(searchText);
    commit("setCollegesOptions", colleges);
  },
  async fetchOptionsOfCounselingProfile({ commit, state }, selectorTitle) {
    if (state[selectorTitleAndKeyMap[selectorTitle]].length === 0) {
      const {
        counseling_selector_options: options
      } = await counselingProfileApi.fetchOptionsOfCounselingProfile(
        selectorTitle,
        [state.currentApplicationType]
      );
      commit("setOptions", {
        [selectorTitleAndKeyMap[selectorTitle]]: options
      });
    }
  },
  async fetchSuffixesOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "suffix_selector");
  },
  async fetchOrganizationsOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "cbo_selector");
  },
  async fetchStatesOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "state_selector");
  },
  async fetchPhoneTypesOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "phone_type_selector");
  },
  async fetchCountriesOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "country_selector");
  },
  async fetchSiblingRelationshipsOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "sibling_relationship_selector");
  },
  async fetchMobileCountryCodesOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "mobile_country_code_selector");
  },
  async fetchLanguagesOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "language_selector");
  },
  async fetchVisaTypesOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "visa_type_selector");
  },
  async fetchCitizenshipsOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "citizenship_selector");
  },
  async fetchCollegeDegreesOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "college_degree_selector");
  },
  async fetchApSubjectsOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "ap_subject_selector");
  },
  async fetchIbSubjectsOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "ib_subject_selector");
  },
  async fetchHonorRecognitionLevelsOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "honor_recognition_level_selector");
  },
  async fetchGradeLevelsOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "grade_level_selector");
  },
  async fetchActivityTypesOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "activity_type_selector");
  },
  async fetchHispanicsOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "hispanic_selector");
  },
  async fetchBlackBackgroundsOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "black_background_selector");
  },
  async fetchWhiteBackgroundsOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "white_background_selector");
  },
  async fetchAsianBackgroundsOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "asian_background_selector");
  },
  async fetchGpaTypesOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "gpa_types_selector");
  },
  async fetchGpaScalesOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "gpa_scales_selector");
  },
  async fetchParentMaritalStatusesOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "parents_marital_status_selector");
  },
  async fetchWhoYourPermanentHomeOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "who_your_permanent_home_selector");
  },
  async fetchTribalAffiliationOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "tribal_affiliation_selector");
  },
  async fetchClassesScheduledOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "classes_scheduled_selector");
  },
  async fetchGradingScalesOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "grading_scale_selector");
  },
  async fetchLettersGradingOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "letters_grading_selector");
  },
  async fetchSatSubjectsOfCounselingProfile({ dispatch }) {
    dispatch("fetchOptionsOfCounselingProfile", "sat_subjects_selector");
  },
  async fetchCounselingProfile({ commit }, studentUserId) {
    const {
      counseling_profile
    } = await counselingProfileApi.fetchCounselingProfile(studentUserId);
    commit("setCounselingProfile", counseling_profile);
  },
  async saveCounselingProfile({ state }, studentUserId) {
    await counselingProfileApi.saveCounselingProfile(
      studentUserId,
      state.counselingProfile.profile
    );
  },
  async saveCounselingFamily({ state }, studentUserId) {
    await counselingProfileApi.saveCounselingProfile(
      studentUserId,
      state.counselingProfile.family
    );
  },
  async saveCounselingCampusesMajors({ state }, studentUserId) {
    await counselingProfileApi.saveCounselingProfile(
      studentUserId,
      state.counselingProfile.campusesMajors
    );
  },
  async saveCounselingEducation({ state }, studentUserId) {
    await counselingProfileApi.saveCounselingProfile(
      studentUserId,
      state.counselingProfile.education
    );
  },
  async saveCounselingTesting({ state }, studentUserId) {
    await counselingProfileApi.saveCounselingProfile(
      studentUserId,
      state.counselingProfile.testing
    );
  },
  async saveCounselingActivities({ state }, studentUserId) {
    await counselingProfileApi.saveCounselingProfile(studentUserId, {
      // activities original is counseling profile's member, it is not getter.
      activities: state.counselingProfile.activities,
      wish_to_report_activities: state.counselingProfile.wish_to_report_activities
    });
  },
  async saveCounselingScholarshipsPrograms({ state }, studentUserId) {
    await counselingProfileApi.saveCounselingProfile(
      studentUserId,
      state.counselingProfile.scholarshipsPrograms
    );
  },
  async saveCounselingWriting({ state }, studentUserId) {
    await counselingProfileApi.saveCounselingProfile(
      studentUserId,
      state.counselingProfile.writing
    );
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
