import courseApi from "@/apis/course";
import privateApi from "@/apis/private";
import lessonLogApi from "@/apis/lessonLog";
import { user } from "@ivy-way/material";
import { Message } from "element-ui";
import i18n from "@/i18n.js";

import moment from "moment";

const initState = {
  classes: [],
  the_class: {
    lesson_logs: [],
    student_class_tickets: []
  }
};

const state = {
  ...initState
};

const getters = {
  classes(state) {
    return state.classes;
  },
  theClass(state) {
    return state.the_class;
  },
  theClassTeachers(state) {
    if (state.the_class.class_is_private) {
      return state.the_class.session_class.session_class_teachers;
    } else {
      return [{ teacher: state.the_class.teacher_user }];
    }
  },
  theClassStudents(state) {
    return state.the_class.student_class_tickets;
  },
  lessonDates(state) {
    const lesson_logs = [];
    const teaching_date_map_ids = {};
    for (const student_user_id in state.the_class.lesson_logs_group_by_user) {
      lesson_logs.push(...state.the_class.lesson_logs_group_by_user[student_user_id]);
    }
    const lesson_dates_set = new Set();
    lesson_logs.forEach(( lesson_log ) => {
      const { teaching_log } = lesson_log;
      lesson_dates_set.add(teaching_log.started_at);
      teaching_date_map_ids[teaching_log.started_at] = teaching_log.id;
    });

    return Array.from(lesson_dates_set).map(lessonDate => {
      const lessonDateMoment = moment(lessonDate);
      return {
        date: `${lessonDateMoment.format("M")}/${lessonDateMoment.format("D")}`,
        day: lessonDateMoment.format("ddd"),
        teaching_log_id: teaching_date_map_ids[lessonDate]
      };
    }).reverse();
  },
  studentsAttendances(state) {
    return state.the_class.student_class_tickets.map(
      student_class_ticket => {
        const student_lesson_logs =
          state.the_class.lesson_logs_group_by_user[
            student_class_ticket.student.id
          ] || [];

        let attendances = {};
        student_lesson_logs.forEach(student_lesson_log => {
          const lesson_date = student_lesson_log.teaching_log.started_at;
          const lesson_date_moment = moment(lesson_date);
          const lesson_date_formatted_MD = `${lesson_date_moment.format(
            "M"
          )}/${lesson_date_moment.format("D")}`;
          attendances[lesson_date_formatted_MD] = {
            status: student_lesson_log.attendance_status,
            lesson_log_id: student_lesson_log.id
          };
        });

        return {
          student: {
            ...student_class_ticket.student,
            displayName: user.displayName(
              student_class_ticket.student.first_name,
              student_class_ticket.student.last_name
            )
          },
          attendances
        };
      }
    );
  }
};

const mutations = {
  setClasses(state, data) {
    state.classes = data;
  },
  setClass(state, the_class) {
    const session_course_id = the_class?.session_class?.course_session_id;
    const MAX_PRIVATE_LESSON_ID = 10;
    const class_is_private = session_course_id <= MAX_PRIVATE_LESSON_ID;
    state.the_class = {
      ...the_class,
      class_is_private
    };
  },
  setStudentLessonLogAttendance(state, lessonLogAttendance) {
    const {
      lessonLogId: lesson_log_id,
      attendanceStatus: attendance_status,
      studentUserId: student_user_id
    } = lessonLogAttendance;
    const student_lesson_logs =
      state.the_class.lesson_logs_group_by_user[student_user_id] || [];

    const new_student_lesson_logs = student_lesson_logs.map(
      lesson_log => {
        if (lesson_log.id === lesson_log_id) {
          return { ...lesson_log, attendance_status };
        } else {
          return { ...lesson_log };
        }
      }
    );

    state.the_class = {
      ...state.the_class,
      lesson_logs_group_by_user: {
        ...state.the_class.lesson_logs_group_by_user,
        [student_user_id]: new_student_lesson_logs
      }
    };
  }
};

const actions = {
  async getClasses({ commit }) {
    const { session_classes } = await courseApi.fetchSessionClassAndPrivate();
    commit("setClasses", session_classes);
  },
  async getClass({ commit }, class_id) {
    const the_class = await privateApi.getCustomLesson({ class_id });
    commit("setClass", the_class);
  },
  async updateStudentAttendance({ _ }, studentAttendance) {
    const {
      lessonLogId: lesson_log_id,
      attendanceStatus: attendance_status
    } = studentAttendance;

    try {
      await lessonLogApi.batchUpdateStudentAttendanceStatus({
        lesson_logs: [{ id: lesson_log_id, attendance_status }]
      });
      Message.success(i18n.t("message.update_success"));
    } catch (e) {
      Message.error(i18n.t("message.something_went_wrong"));
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
