/* eslint-disable no-empty-pattern */
import lessonLogApi from "@/apis/lessonLog";
import { Message } from "element-ui";
import i18n from "@/i18n.js";

const state = {};

const getters = {};

const mutations = {};

const actions = {
  createPrivateScheduleLessonLog({}, payload) {
    const {
      privateScheduleLesson: {
        classId,
        teacherUserId,
        salary,
        salaryCurrency,
        studentClassTickets,
        schedules
      },
      callbacks
    } = payload;

    const promiseSchedules = schedules.map(
      async schedule =>
        await lessonLogApi.createLog({
          class_id: classId,
          teacher_user_id: teacherUserId,
          salary: salary,
          salary_currency: salaryCurrency,
          started_at: schedule.started_at,
          timezone: schedule.timezone,
          duration_hours: schedule.duration_hours,
          duration_minutes: schedule.duration_minutes,
          has_mailed: 0,
          mail_to_admin: 0,
          mail_to_teacher: 0,
          mail_to_student: 0,
          mail_to_parents: 0,
          students: studentClassTickets.map(studentClassTicket => ({
            student_class_ticket_id: studentClassTicket.id,
            fee: studentClassTicket.fee,
            fee_currency: studentClassTicket.fee_currency,
            attendance_status: 1,
            has_vocab_test: 0
          }))
        })
    );

    try {
      Promise.all(promiseSchedules).then(values => {
        if (callbacks) {
          callbacks(values);
        }
      });
    } catch (e) {
      console.log(e);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
