import ApiService from "@/common/api.service";

export default {
  getBooks() {
    return ApiService.get("vocabs");
  },
  getVocabs(payload) {
    return ApiService.query("vocab/list", payload);
  },
  recordVocab(payload) {
    return ApiService.post("vocab/usage", payload);
  },
  createMnemonic(data) {
    return ApiService.post("mnemonic", data);
  },
  deleteMnemonic(id) {
    return ApiService.delete("mnemonic/" +  id);
  },
  updateMnemonic(id,data) {
    return ApiService.patch("mnemonic/" +  id, data);
  },
  getMnemonics(data) {
    return ApiService.query("mnemonics", data);
  },
  saveLike(id){
    return ApiService.post("mnemonic/" +  id + "/toggle-like");
  },
  likedByme(id) {
    return ApiService.query(`vocab-back/${id}/is-liked-by-me`);
  },
  saveWordLike(id) {
    return ApiService.post(`vocab-back/${id}/toggle-like`);
  },
  uploadImg(data) {
    return ApiService.post("mnemonics/file", data);
  }
};
