import ApiService from "@/common/api.service";

export default {
  getUserRoleProfile() {
    return ApiService.get("profile");
  },
  patchBasicProfile(data) {
    return ApiService.patch("basic/profile", data);
  },
  patchStudentProfile(data) {
    return ApiService.patch("student/profile", data);
  },
  patchTeacherProfile(data) {
    return ApiService.patch("teacher/profile", data);
  },
  changePassword(userId) {
    return ApiService.post("reset-url", { user_id: userId });
  },
  getUserData(userId) {
    return ApiService.get("profile/" + userId);
  },
  checkEmail(email) {
    return ApiService.post("/users/check-email", { email: email });
  },
  getUserByName(userName) {
    return ApiService.get("profile/username/" + userName);
  },
  patchBasicProfileByAdmin(id, data) {
    return ApiService.patch("basic/profile/" + id, data);
  },
  patchStudentProfileByAdmin(id, data) {
    return ApiService.patch("student/profile/" + id, data);
  },
  patchStudentSurvey(id, data) {
    return ApiService.patch("student/survey/" + id, data);
  },
  patchTeacherProfileByAdmin(id, data) {
    return ApiService.patch("teacher/profile/" + id, data);
  },
  fetchTeachers(query = {}) {
    return ApiService.query("teachers", query);
  }
};
