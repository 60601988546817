export default class CollegeAlias {
  constructor(props) {
    const {
      id = null,
      schoolId = null,
      name = "",
      lang = "",
      isDefault = 0,
      originalName = false
    } = props || {};

    this.id = id;
    this.schoolId = schoolId;
    this.name = name;
    this.lang = lang;
    this.isDefault = isDefault;
    this.originalName = originalName;
  }
}
