import ApiService from "@/common/api.service";

class MyUploadAdapter {
  constructor(loader) {
    this.loader = loader;
  }

  async upload() {
    const data = new FormData();
    data.append("document", await this.loader.file);
    data.append("type", "img");
    const res = await ApiService.post("document", data);
    return {
      default: res.document.url
    };
  }
}

export default MyUploadAdapter;
