import moment from "moment";

class GreetMeeting {
  constructor(props = {}) {
    this.teachersId = props.teachersId || [];
    this.isDecidedMeetingTime = props.isDecidedMeetingTime || false;
    this.isSameOfClassAddress = props.isSameOfClassAddress || false;
    this.date = props.date || moment(new Date()).format("YYYY-MM-DD");
    this.time = props.time || "08:00";
    this.timezone = props.timezone || "Asia/Taipei";
    this.address = props.address || "";
  }
}

export default GreetMeeting;
