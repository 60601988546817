import moment from "moment";
const COUNSELING_PROFILE_MAX_US_MILITARY_EXPERIENCE_COUNT = 3;
const COUNSELING_PROFILE_MAX_PARENT_SCHOOL_COUNT = 5;
const COUNSELING_PROFILE_MAX_PARENT_SCHOOL_DEGREE_COUNT = 5;
const COUNSELING_PROFILE_MAX_SCHOOL_ACAD_YEAR_COUNT = 4;
const COUNSELING_PROFILE_MAX_RECENT_COURSE_COUNT = 15;
const COUNSELING_PROFILE_MAX_HONOR_COUNT = 5;
const COUNSELING_PROFILE_MAX_ORGANIZATION_COUNT = 5;
const COUNSELING_PROFILE_MAX_SAT_SUBJECT_COUNT = 10;
const COUNSELING_PROFILE_MAX_AP_SUBJECT_COUNT = 15;
const COUNSELING_PROFILE_MAX_IB_SUBJECT_COUNT = 10;
const COUNSELING_PROFILE_MAX_DUOLINGO_COUNT = 5;
const COUNSELING_PROFILE_MAX_OTHER_PROFICIENCY_EXAMS_COUNT = 4;
const COUNSELING_PROFILE_MAX_A_LEVEL_SUBJECT_COUNT = 6;
const COUNSELING_PROFILE_MAX_LEAVE_EXAM_COUNT = 10;
const COUNSELING_PROFILE_MAX_ACTIVITY_COUNT = 10;

export class CounselingProfile {
  constructor(props) {
    const {
      first_name = "",
      middle_name = "",
      last_name = "",
      suffix = null,
      pronouns = [],
      other_pronoun_descr = "",
      preferred_name = "",
      would_you_like_to_share_different_first_name = null,
      different_first_name = "",
      used_any_other_names = null,
      former_first_name = "",
      former_middle_name = "",
      former_last_name = "",
      former_suffix = "",
      sex = null,
      sex_descr = "",
      gender_identities = [],
      sexual_orientation = null,
      other_sexual_orientation_descr = "",
      other_gender_identity = "",
      has_ssn = null,
      birth_date = "",
      us_armed_forces_status = null,
      us_military_anticipated_status = null,
      us_military_experiences = [],
      current_enrollment_status = "",
      has_graduated_from_high_school = true,
      has_taken_college_course = true,
      plan_to_begin_studying_year = "",
      apply_for_need_based_financial_aid = null,
      disaster_impacts = [],
      address = null,
      used_mailing_address = null,
      mailing_address = null,
      is_temporary_mailing_address = null,
      temporary_mailing_address_from_date = "",
      temporary_mailing_address_to_date = "",
      residency = null,
      preferred_phone = null,
      preferred_phone_number = null,
      used_alternate_phone = 0,
      alternate_phone_number = null,
      is_alt_text_auth = null,
      can_release_contact_info = null,
      birth_country = "",
      birth_city = "",
      birth_state = null,
      lived_in_us_yr = null,
      lived_outside_us_yr = null,
      religion = null,
      religion_descr = "",
      ever_received_discharge = null,
      discharge_descr = null,
      is_hispanic_or_latino = null,
      hispanic_or_latino_identities = [],
      hispanic_or_latino_descr = "",
      other_identities = [],
      amerind_background = [],
      ucai_tribe_status = "",
      is_ucai_want_support_info = "",
      tribal_identity = "",
      is_federally_recognized_tribe = null,
      tribal_enrollment_number = "",
      amerind_addl = "",
      asian_backgrounds = [],
      east_asian_descr = "",
      south_asian_descr = "",
      southeast_asian_descr = "",
      african_american_backgrounds = [],
      african_american_descr = "",
      native_hawaiian_or_other_pacific_islander_backgrounds = [],
      native_hawaiian_or_other_pacific_islander_descr = "",
      white_backgrounds = [],
      white_descr = "",
      southwest_asian_and_north_africans_background = [],
      other_southwest_asian_descr = "",
      other_north_asian_descr = "",
      lang_first_code = "",
      lang_second_code = null,
      backgrounds = [],
      military_service = "",
      has_military_transcript = "",
      is_military_dependent = "",
      languages_length = 1,
      languages = [],
      citizenship_status = null,
      is_tribal_member = "",
      tribal_membership_type = "",
      tribal_affiliation_fr = "",
      is_agree_tribe_use_verification = "",
      tribal_affiliation_sr = "",
      tribal_affiliation_cn = "",
      green_card_file = null,
      arn_expiration_date = "",
      arn_number = "",
      ssn_number = "",
      list_citizenship = [],
      hold_us_visa = 0,
      held_visa = null,
      visa_number = "",
      planned_visa = null,
      hold_planned_visa = null,
      visa_date_issued = null,
      daca_approved = null,
      daca_expiry = "",
      has_graduated_from_cahs = "",
      has_attended_cahs = "",
      is_living_in_ca = "",
      has_lived_in_ca_when = "",
      has_lived_in_ca_since = "",
      is_parent_working_at_uc = "",
      apply_fee_waiver = 0,
      common_app_fee_waiver_indicators = [],
      coalition_app_fee_waiver_indicators = [],
      fee_waiver_signature = "",
      want_info_from_strive_for_college = null,
      household = [],
      parents_marital_status = null,
      other_parent_marital_descr = "",
      family_income_curr_year = null,
      family_size_curr_year = null,
      household_size = null,
      highest_parent_ed = "",
      has_children = null,
      children_number = 0,
      whom_make_your_permanent_home = null,
      living_situation_descr = "",
      divorced_yr = "",
      want_to_list_step_parents = null,
      step_parents_number = null,
      parents_length = 0,
      parents = [],
      legal_guardians_length = 0,
      legal_guardians = [],
      siblings_number = 0,
      siblings = [],
      accepted_us_values = "",
      apply_level = "",
      entering_level = "",
      math_flag_78th = "",
      math_courses_78th = [],
      language_flag_78th = "",
      language_courses_78th = [],
      intl_exp_flag_78th = "",
      intl_exp_lang_code = "",
      intl_exp_lang_other = "",
      equiv_exam_yn = false,
      high_school_state_type = "",
      high_school_filter_state = "",
      high_school_filter_country = "",
      high_school_type = "",
      high_school_value = "",
      high_school_name = "",
      high_school_ceeb_code = "",
      high_school_school_type_code = "",
      high_school_city = "",
      high_school_state = "",
      high_school_state_value = "",
      high_school_zip = "",
      high_school_country_code = "",
      high_school_country_value = "",
      high_school_address_line_1 = "",
      high_school_address_line_2 = "",
      high_school_address_line_3 = "",
      high_school_entry_month = "",
      high_school_is_boarding_high_school = null,
      high_school_is_exam_based = null,
      high_school_grade_frequency = "",
      high_school_block_sched = null,
      high_school_grade_scale = "",
      high_school_other_grade_scale = "",
      high_school_num_grade_min = 0,
      high_school_num_grade_max = 0,
      high_school_live_on_campus = null,
      high_school_graduate_from_school = null,
      high_school_graduation_month = "",
      high_school_exit_month = "",
      high_school_receive_high_school_equivalency = null,
      high_school_receive_high_school_equivalency_month = "",
      high_school_descr = "",
      high_school_progression = [],
      high_school_progression_descr = "",
      high_school_grade_level = "",
      high_school_gpa_scale_other = "",
      high_school_type_code = "",
      high_school_specialized_curriculums = [],
      high_school_specialized_curriculums_descr = "",
      high_school_grading_systems = [],
      high_school_grading_system_descr = "",
      high_school_degree_code = "",
      high_school_degree_descr = "",
      high_school_acad_years = [],
      counselor_first_name = "",
      counselor_last_name = "",
      counselor_email = "",
      counselor_relationships = [],
      high_school_courses = [],
      other_high_schools_length = 0,
      other_high_schools = [],
      non_senior_complete = false,
      non_senior_explain = "",
      senior_complete = false,
      senior_explain = "",
      why_leave_other_high_school = "",
      has_no_courses = "",
      additional_comments = "",
      has_college_attended = true,
      in_college = false,
      college_credits = 0,
      after_high_school_credits = 0,
      disaster_impacts_transfer = [],
      other_disaster_impact_transfer_desc = "",
      colleges_length = 0,
      colleges = [],
      completed_college_complete = null,
      in_progress_college_complete = null,
      graduating_class_size = "",
      class_rank_reporting = null,
      exact_class_rank = null,
      decile_rank = null,
      quintile_rank = null,
      quartile_rank = null,
      class_rank_weighting = null,
      gpa_scale_reporting = null,
      cumulative_gpa = null,
      gpa_weighting = null,
      courses_scheduling_system = null,
      courses_length = 0,
      courses = [],
      wish_to_report_honors = null,
      honors = [],
      community_based_organizations_length = null,
      community_based_organizations = [],
      career_interest = "",
      career_interest_descr = "",
      highest_degree_to_earn = "",
      academic_interests = [],
      wish_to_report_tests = 0,
      tests_taken = [],
      gave_leaving_exams = null,
      acts_number = null,
      acts_super_score = null,
      act_id_number = 0,
      took_act_plus_writing_test = null,
      acts_highest_composite_score = null,
      acts_composite_date = "",
      acts_highest_english_score = null,
      acts_english_date = "",
      acts_highest_math_score = null,
      acts_math_date = "",
      acts_highest_reading_score = null,
      acts_reading_date = "",
      acts_highest_science_score = null,
      acts_science_date = "",
      acts_highest_writing_score = null,
      acts_writing_date = "",
      future_act_sitting_months_length = 0,
      future_act_sitting_months = [],
      sats_after_march_number = null,
      took_sat_essay = null,
      sats_after_march_highest_reading_and_writing_score = null,
      sats_after_march_reading_and_writing_date = "",
      sats_after_march_highest_math_score = null,
      sats_after_march_math_date = "",
      sats_after_march_highest_essay_score = null,
      sats_after_march_essay_date = "",
      sats_after_march_highest_total_score = null,
      sats_after_march_total_date = "",
      sats_after_march_highest_sub_reading_score = null,
      sats_after_march_highest_sub_writing_score = null,
      sats_after_march_highest_sub_math_score = null,
      future_sat_sitting_months_length = 0,
      future_sat_sitting_months = [],
      sats_number = null,
      sats = [],
      aps_number = null,
      aps = [],
      ibs_number,
      ibs = [],
      a_levels_number,
      a_levels = [],
      toefls_number = null,
      toefls_reg_number = null,
      toefls_is_completed = null,
      toefls_has_received_score = null,
      future_toefls_sitting_months_length = null,
      future_toefls_sitting_months = [],
      toefls_best_score = null,
      toefls_highest_reading_score = null,
      toefls_reading_date = "",
      toefls_highest_speaking_score = null,
      toefls_speaking_date = "",
      toefls_highest_listening_score = null,
      toefls_listening_date = "",
      toefls_highest_writing_score = null,
      toefls_writing_date = "",
      toefls_total_score = null,
      toefls_total_score_date = "",
      toefl_papers_number = null,
      toefl_papers_is_completed = null,
      toefl_papers_reg_number = null,
      toefl_papers_has_received_score = null,
      future_toefl_papers_sitting_months_length = null,
      future_toefl_papers_sitting_months = [],
      toefl_papers_total_score = null,
      toefl_papers_total_score_date = "",
      toefl_papers_highest_reading_score = null,
      toefl_papers_reading_date = "",
      toefl_papers_highest_listening_score = null,
      toefl_papers_listening_date = "",
      toefl_papers_highest_writing_score = null,
      toefl_papers_writing_date = "",
      toefl_papers_highest_twe_score = null,
      toefl_papers_twe_date = "",
      ptes_number = null,
      future_ptes_sitting_months_length = null,
      future_ptes_sitting_months = [],
      ptes_highest_listening_score = null,
      ptes_listening_date = "",
      ptes_highest_reading_score = null,
      ptes_reading_date = "",
      ptes_highest_speaking_score = null,
      ptes_speaking_date = "",
      ptes_highest_writing_score = null,
      ptes_writing_date = "",
      ptes_highest_grammar_score = null,
      ptes_grammar_date = "",
      ptes_highest_oral_fluency_score = null,
      ptes_oral_fluency_date = "",
      ptes_highest_pronunciation_score = null,
      ptes_pronunciation_date = "",
      ptes_highest_spelling_score = null,
      ptes_spelling_date = "",
      ptes_highest_vocabulary_score = null,
      ptes_vocabulary_date = "",
      ptes_highest_discourse_score = null,
      ptes_discourse_date = "",
      ielts_number = null,
      ielts_is_completed = null,
      ielts_form_number = null,
      ielts_has_received_score = null,
      future_ielts_sitting_months_length = null,
      future_ielts_sitting_months = [],
      ielts_highest_listening_score = null,
      ielts_listening_date = "",
      ielts_highest_reading_score = null,
      ielts_reading_date = "",
      ielts_highest_writing_score = null,
      ielts_writing_date = "",
      ielts_highest_speaking_score = null,
      ielts_speaking_date = "",
      ielts_overall_score = null,
      ielts_overall_score_date = "",
      duolingoes_number = null,
      duolingoes = [],
      future_duolingoes_sitting_months_length = null,
      future_duolingoes_sitting_months = [],
      other_proficiency_exams_number = null,
      other_proficiency_exams = [],
      leaving_exams_number = null,
      leaving_exams = [],
      wish_to_report_activities = null,
      activities = [],
      activities_or_awards = [],
      scholarships = [],
      eop_selected = "",
      eop_reason = "",
      understand_to_submit_essay = 0,
      common_app_essay_topic = null,
      common_app_essay_content = "",
      coalition_app_essay_topic = null,
      coalition_app_essay_content = "",
      california_app_essay_topics = [],
      california_app_essay_contents = [],
      received_disciplinary_action = null,
      disciplinary_history_descr = "",
      wish_to_share_about_covid = null,
      covid_descr = "",
      wish_to_share_not_reflected_by_covid = null,
      not_reflected_by_covid_descr = "",
      any_additional_information = null,
      provide_additional_information = "",
      uc_additional_comments = ""
    } = props || {};

    const filled_languages = Array.from(
      Array(languages_length)
    ).map((_value, index) =>
      languages && languages[index] ? new Language(languages[index]) : new Language()
    );

    const filled_us_military_experiences = Array.from(
      Array(COUNSELING_PROFILE_MAX_US_MILITARY_EXPERIENCE_COUNT)
    ).map((_value, index) =>
      us_military_experiences && us_military_experiences[index]
        ? new MilitaryExperience(us_military_experiences[index])
        : new MilitaryExperience()
    );

    const filled_parents = Array.from(
      Array(parents_length)
    ).map((_value, index) =>
      parents && parents[index] ? new Parent(parents[index]) : new Parent()
    );

    const filled_legal_guardians = Array.from(
      Array(legal_guardians_length)
    ).map((_value, index) => 
      legal_guardians && legal_guardians[index]
        ? new LegalGuardian(legal_guardians[index])
        : new LegalGuardian()
    );
    const filled_siblings = Array.from(
      Array(siblings_number)
    ).map((_value, index) =>
      siblings && siblings[index] ? new Sibling(siblings[index]) : new Sibling()
    );

    const filled_high_school_acad_years = Array.from(
      Array(Math.max(COUNSELING_PROFILE_MAX_SCHOOL_ACAD_YEAR_COUNT, high_school_acad_years.length))
    ).map((value, index) =>
      high_school_acad_years && high_school_acad_years[index]
        ? new HighSchoolAcadYear(high_school_acad_years[index])
        : new HighSchoolAcadYear()
    );

    const filled_other_high_schools = Array.from(
      Array(other_high_schools_length)
    ).map((_value, index) =>
      other_high_schools && other_high_schools[index]
        ? new OtherHighSchool(other_high_schools[index])
        : new OtherHighSchool()
    );

    const filled_colleges = Array.from(
      Array(colleges_length)
    ).map((_value, index) =>
      colleges && colleges[index]
        ? new TakenCollege(colleges[index])
        : new TakenCollege()
    );

    const filled_course = Array.from(
      Array(COUNSELING_PROFILE_MAX_RECENT_COURSE_COUNT)
    ).map((_value, index) =>
      courses && courses[index]
        ? new RecentCourse(courses[index])
        : new RecentCourse()
    );

    const filled_honors = Array.from(
      Array(COUNSELING_PROFILE_MAX_HONOR_COUNT)
    ).map((_value, index) =>
      honors && honors[index] ? new Honor(honors[index]) : new Honor()
    );

    const filled_community_based_organizations = Array.from(
      Array(COUNSELING_PROFILE_MAX_ORGANIZATION_COUNT)
    ).map((_value, index) =>
      community_based_organizations && community_based_organizations[index]
        ? new Organization(community_based_organizations[index])
        : new Organization()
    );

    const filled_sats = Array.from(
      Array(COUNSELING_PROFILE_MAX_SAT_SUBJECT_COUNT)
    ).map((_value, index) =>
      sats && sats[index] ? new SatSubject(sats[index]) : new SatSubject()
    );

    const filled_aps = Array.from(
      Array(COUNSELING_PROFILE_MAX_AP_SUBJECT_COUNT)
    ).map((_value, index) =>
      aps && aps[index] ? new ApSubject(aps[index]) : new ApSubject()
    );

    const filled_ibs = Array.from(
      Array(COUNSELING_PROFILE_MAX_IB_SUBJECT_COUNT)
    ).map((_value, index) =>
      ibs && ibs[index] ? new IbSubject(ibs[index]) : new IbSubject()
    );

    const filled_a_levels = Array.from(
      Array(COUNSELING_PROFILE_MAX_A_LEVEL_SUBJECT_COUNT)
    ).map((_value, index) =>
      a_levels && a_levels[index] ? new ALevelSubject(a_levels[index]) : new ALevelSubject()
    );

    const filled_duolingoes = Array.from(
      Array(COUNSELING_PROFILE_MAX_DUOLINGO_COUNT)
    ).map((_value, index) =>
      duolingoes && duolingoes[index] ? new Duolingo(duolingoes[index]) : new Duolingo()
    );

    const filled_other_proficiency_exams = Array.from(
      Array(COUNSELING_PROFILE_MAX_OTHER_PROFICIENCY_EXAMS_COUNT)
    ).map((_value, index) =>
      other_proficiency_exams && other_proficiency_exams[index] ? new OtherProficiencyExam(other_proficiency_exams[index]) : new OtherProficiencyExam()
    );

    const filled_leaving_exams = Array.from(
      Array(COUNSELING_PROFILE_MAX_LEAVE_EXAM_COUNT)
    ).map((_value, index) =>
      leaving_exams && leaving_exams[index]
        ? new LeavingExam(leaving_exams[index])
        : new LeavingExam()
    );

    const filled_activities = Array.from(
      Array(COUNSELING_PROFILE_MAX_ACTIVITY_COUNT)
    ).map((_value, index) =>
      activities && activities[index] ? new Activity(activities[index]) : new Activity()
    );

    const filled_activities_or_awards = (
      activities_or_awards || []
    ).map((_value, index) =>
      activities_or_awards && _value
        ? ActivityOrAwardFactory.createActivityOrAward(activities_or_awards[index])
        : ActivityOrAwardFactory.createActivityOrAward()
    );

    Object.assign(this, {
      first_name,
      middle_name,
      last_name,
      suffix,
      pronouns: (pronouns || []).map((pronoun) => this.jsonStringify(pronoun)),
      other_pronoun_descr,
      preferred_name,
      would_you_like_to_share_different_first_name,
      different_first_name,
      used_any_other_names,
      former_first_name,
      former_middle_name,
      former_last_name,
      former_suffix,
      sex,
      sex_descr,
      gender_identities: (gender_identities || []).map((gender_identity) => this.jsonStringify(gender_identity)),
      sexual_orientation,
      other_sexual_orientation_descr,
      other_gender_identity,
      has_ssn: Boolean(has_ssn),
      birth_date,
      us_armed_forces_status,
      us_military_anticipated_status,
      us_military_experiences: filled_us_military_experiences,
      current_enrollment_status: this.jsonStringify(current_enrollment_status),
      has_graduated_from_high_school: Boolean(has_graduated_from_high_school),
      has_taken_college_course: Boolean(has_taken_college_course),
      plan_to_begin_studying_year,
      apply_for_need_based_financial_aid: Boolean(apply_for_need_based_financial_aid),
      disaster_impacts: (disaster_impacts || []).map((disaster_impact) => this.jsonStringify(disaster_impact)),
      address: new Address(address),
      used_mailing_address,
      mailing_address: new Address(mailing_address),
      is_temporary_mailing_address,
      temporary_mailing_address_from_date,
      temporary_mailing_address_to_date,
      residency,
      preferred_phone,
      preferred_phone_number: new PhoneNumber(preferred_phone_number),
      used_alternate_phone,
      alternate_phone_number: new PhoneNumber(alternate_phone_number),
      is_alt_text_auth,
      can_release_contact_info,
      birth_country,
      birth_city,
      birth_state,
      lived_in_us_yr,
      lived_outside_us_yr,
      religion,
      religion_descr,
      ever_received_discharge,
      discharge_descr,
      is_hispanic_or_latino,
      hispanic_or_latino_identities: (hispanic_or_latino_identities || []),
      hispanic_or_latino_descr,
      other_identities: other_identities || [],
      amerind_background: (amerind_background || []).map((the_amerind_background) => this.jsonStringify(the_amerind_background)),
      ucai_tribe_status,
      is_ucai_want_support_info,
      tribal_identity,
      is_federally_recognized_tribe,
      tribal_enrollment_number,
      amerind_addl,
      asian_backgrounds: asian_backgrounds || [],
      east_asian_descr,
      south_asian_descr,
      southeast_asian_descr,
      african_american_backgrounds: african_american_backgrounds || [],
      african_american_descr,
      native_hawaiian_or_other_pacific_islander_backgrounds: native_hawaiian_or_other_pacific_islander_backgrounds || [],
      native_hawaiian_or_other_pacific_islander_descr,
      white_backgrounds: white_backgrounds || [],
      white_descr,
      southwest_asian_and_north_africans_background: southwest_asian_and_north_africans_background || [],
      other_southwest_asian_descr,
      other_north_asian_descr,
      lang_first_code,
      lang_second_code,
      backgrounds: backgrounds || [],
      military_service,
      has_military_transcript,
      is_military_dependent,
      languages_length,
      languages: filled_languages,
      citizenship_status,
      is_tribal_member,
      tribal_membership_type,
      tribal_affiliation_fr,
      is_agree_tribe_use_verification,
      tribal_affiliation_sr,
      tribal_affiliation_cn,
      green_card_file,
      arn_expiration_date,
      arn_number,
      ssn_number,
      list_citizenship: list_citizenship || [],
      hold_us_visa,
      held_visa,
      visa_number,
      planned_visa,
      hold_planned_visa,
      visa_date_issued,
      daca_approved: Boolean(daca_approved),
      daca_expiry,
      has_graduated_from_cahs,
      has_attended_cahs,
      is_living_in_ca,
      has_lived_in_ca_when,
      has_lived_in_ca_since,
      is_parent_working_at_uc,
      apply_fee_waiver,
      common_app_fee_waiver_indicators: (common_app_fee_waiver_indicators || []),
      coalition_app_fee_waiver_indicators: (coalition_app_fee_waiver_indicators || []),
      fee_waiver_signature,
      want_info_from_strive_for_college,
      household: (household || []).map((the_household) => this.jsonStringify(the_household)),
      parents_marital_status,
      other_parent_marital_descr,
      family_income_curr_year,
      family_size_curr_year,
      household_size,
      highest_parent_ed: this.jsonStringify(highest_parent_ed),
      has_children,
      children_number,
      whom_make_your_permanent_home,
      living_situation_descr,
      divorced_yr,
      want_to_list_step_parents,
      step_parents_number,
      parents_length,
      parents: filled_parents,
      legal_guardians_length,
      legal_guardians: filled_legal_guardians,
      siblings_number,
      siblings: filled_siblings,
      accepted_us_values,
      apply_level,
      entering_level,
      math_flag_78th,
      math_courses_78th: math_courses_78th || [],
      language_flag_78th,
      language_courses_78th: language_courses_78th || [],
      intl_exp_flag_78th,
      intl_exp_lang_code,
      intl_exp_lang_other,
      equiv_exam_yn: Boolean(equiv_exam_yn),
      high_school_state_type,
      high_school_filter_state,
      high_school_filter_country,
      high_school_type,
      high_school_value,
      high_school_name,
      high_school_ceeb_code,
      high_school_school_type_code,
      high_school_city,
      high_school_state,
      high_school_state_value,
      high_school_zip,
      high_school_country_code,
      high_school_country_value,
      high_school_address_line_1,
      high_school_address_line_2,
      high_school_address_line_3,
      high_school_entry_month,
      high_school_is_boarding_high_school,
      high_school_is_exam_based,
      high_school_grade_frequency,
      high_school_block_sched: high_school_block_sched,
      high_school_grade_scale: this.jsonStringify(high_school_grade_scale),
      high_school_other_grade_scale,
      high_school_num_grade_min,
      high_school_num_grade_max,
      high_school_live_on_campus,
      high_school_graduate_from_school,
      high_school_graduation_month,
      high_school_exit_month,
      high_school_receive_high_school_equivalency,
      high_school_receive_high_school_equivalency_month,
      high_school_descr,
      high_school_progression: high_school_progression || [],
      high_school_progression_descr,
      high_school_grade_level: this.jsonStringify(high_school_grade_level),
      high_school_gpa_scale_other,
      high_school_type_code,
      high_school_specialized_curriculums: high_school_specialized_curriculums || [],
      high_school_specialized_curriculums_descr,
      high_school_grading_systems: high_school_grading_systems || [],
      high_school_grading_system_descr,
      high_school_degree_code,
      high_school_degree_descr,
      high_school_acad_years: filled_high_school_acad_years,
      counselor_first_name,
      counselor_last_name,
      counselor_email,
      counselor_relationships: (counselor_relationships || []),
      high_school_courses: (high_school_courses || []).map(high_school_course => new HighSchoolCourse(high_school_course)),
      other_high_schools_length,
      other_high_schools: filled_other_high_schools,
      non_senior_complete,
      non_senior_explain,
      senior_complete,
      senior_explain,
      why_leave_other_high_school,
      has_no_courses,
      additional_comments,
      has_college_attended: Boolean(has_college_attended),
      in_college: Boolean(in_college),
      college_credits,
      after_high_school_credits,
      disaster_impacts_transfer: (disaster_impacts_transfer || []).map((disaster_impact_transfer) => this.jsonStringify(disaster_impact_transfer)),
      other_disaster_impact_transfer_desc,
      colleges_length,
      colleges: filled_colleges,
      completed_college_complete,
      in_progress_college_complete,
      graduating_class_size,
      class_rank_reporting,
      exact_class_rank,
      decile_rank,
      quintile_rank,
      quartile_rank,
      class_rank_weighting,
      gpa_scale_reporting,
      cumulative_gpa,
      gpa_weighting,
      courses_scheduling_system,
      courses_length,
      courses: filled_course,
      wish_to_report_honors,
      honors: filled_honors,
      community_based_organizations_length,
      community_based_organizations: filled_community_based_organizations,
      career_interest,
      career_interest_descr,
      highest_degree_to_earn,
      academic_interests: (academic_interests || []).map((academic_interest) => this.jsonStringify(academic_interest)),
      wish_to_report_tests,
      gave_leaving_exams,
      tests_taken: tests_taken || [],
      acts_number,
      acts_super_score,
      act_id_number,
      took_act_plus_writing_test,
      acts_highest_composite_score,
      acts_composite_date,
      acts_highest_english_score,
      acts_english_date,
      acts_highest_math_score,
      acts_math_date,
      acts_highest_reading_score,
      acts_reading_date,
      acts_highest_science_score,
      acts_science_date,
      acts_highest_writing_score,
      acts_writing_date,
      future_act_sitting_months_length,
      future_act_sitting_months: future_act_sitting_months || [],
      sats_after_march_number,
      took_sat_essay,
      sats_after_march_highest_reading_and_writing_score,
      sats_after_march_reading_and_writing_date,
      sats_after_march_highest_math_score,
      sats_after_march_math_date,
      sats_after_march_highest_essay_score,
      sats_after_march_essay_date,
      sats_after_march_highest_total_score,
      sats_after_march_total_date,
      sats_after_march_highest_sub_reading_score,
      sats_after_march_highest_sub_writing_score,
      sats_after_march_highest_sub_math_score,
      future_sat_sitting_months_length,
      future_sat_sitting_months: future_sat_sitting_months || [],
      sats_number,
      sats: filled_sats,
      aps_number,
      aps: filled_aps,
      ibs_number,
      ibs: filled_ibs,
      a_levels_number,
      a_levels: filled_a_levels,
      toefls_number,
      toefls_reg_number,
      toefls_is_completed,
      toefls_has_received_score,
      future_toefls_sitting_months_length,
      future_toefls_sitting_months: future_toefls_sitting_months || [],
      toefls_best_score,
      toefls_highest_reading_score,
      toefls_reading_date,
      toefls_highest_speaking_score,
      toefls_speaking_date,
      toefls_highest_listening_score,
      toefls_listening_date,
      toefls_highest_writing_score,
      toefls_writing_date,
      toefls_total_score,
      toefls_total_score_date,
      toefl_papers_number,
      toefl_papers_is_completed,
      toefl_papers_reg_number,
      toefl_papers_has_received_score,
      future_toefl_papers_sitting_months_length,
      future_toefl_papers_sitting_months: future_toefl_papers_sitting_months || [],
      toefl_papers_highest_listening_score,
      toefl_papers_listening_date,
      toefl_papers_highest_reading_score,
      toefl_papers_reading_date,
      toefl_papers_highest_writing_score,
      toefl_papers_writing_date,
      toefl_papers_highest_twe_score,
      toefl_papers_twe_date,
      toefl_papers_total_score,
      toefl_papers_total_score_date,
      ptes_number,
      future_ptes_sitting_months_length,
      future_ptes_sitting_months: future_ptes_sitting_months || [],
      ptes_highest_listening_score,
      ptes_listening_date,
      ptes_highest_reading_score,
      ptes_reading_date,
      ptes_highest_speaking_score,
      ptes_speaking_date,
      ptes_highest_writing_score,
      ptes_writing_date,
      ptes_highest_grammar_score,
      ptes_grammar_date,
      ptes_highest_oral_fluency_score,
      ptes_oral_fluency_date,
      ptes_highest_pronunciation_score,
      ptes_pronunciation_date,
      ptes_highest_spelling_score,
      ptes_spelling_date,
      ptes_highest_vocabulary_score,
      ptes_vocabulary_date,
      ptes_highest_discourse_score,
      ptes_discourse_date,
      ielts_number,
      ielts_is_completed,
      ielts_form_number,
      ielts_has_received_score,
      future_ielts_sitting_months_length,
      future_ielts_sitting_months: future_ielts_sitting_months || [],
      ielts_highest_listening_score,
      ielts_listening_date,
      ielts_highest_reading_score,
      ielts_reading_date,
      ielts_highest_writing_score,
      ielts_writing_date,
      ielts_highest_speaking_score,
      ielts_speaking_date,
      ielts_overall_score,
      ielts_overall_score_date,
      duolingoes_number,
      duolingoes: filled_duolingoes,
      future_duolingoes_sitting_months_length,
      future_duolingoes_sitting_months: future_duolingoes_sitting_months || [],
      other_proficiency_exams_number,
      other_proficiency_exams: filled_other_proficiency_exams,
      leaving_exams_number,
      leaving_exams: filled_leaving_exams,
      wish_to_report_activities,
      activities: filled_activities,
      activities_or_awards: filled_activities_or_awards,
      scholarships: scholarships || [],
      eop_selected,
      eop_reason,
      understand_to_submit_essay,
      common_app_essay_topic,
      common_app_essay_content,
      coalition_app_essay_topic,
      coalition_app_essay_content,
      california_app_essay_topics: california_app_essay_topics || [],
      california_app_essay_contents: california_app_essay_contents || [],
      received_disciplinary_action,
      disciplinary_history_descr,
      wish_to_share_about_covid,
      covid_descr,
      wish_to_share_not_reflected_by_covid,
      not_reflected_by_covid_descr,
      any_additional_information,
      provide_additional_information,
      uc_additional_comments
    });
  }

  get valid_military_experience_count() {
    return this.us_military_experiences.filter(({ branch }) => branch !== null).length;
  }

  get valid_honor_count() {
    return this.honors.filter(({ title }) => title).length;
  }

  get valid_activity_count() {
    return this.activities.filter(({ type }) => type).length;
  }

  get valid_activity_or_award_count() {
    return this.activities_or_awards.filter(({ category }) => category).length;
  }

  get profile() {
    return {
      first_name: this.first_name,
      middle_name: this.middle_name,
      last_name: this.last_name,
      suffix: this.suffix,
      pronouns: this.pronouns.map((pronoun) => this.jsonParse(pronoun)),
      other_pronoun_descr: this.other_pronoun_descr,
      preferred_name: this.preferred_name,
      would_you_like_to_share_different_first_name: this.would_you_like_to_share_different_first_name,
      different_first_name: this.different_first_name,
      used_any_other_names: this.used_any_other_names,
      former_first_name: this.former_first_name,
      former_middle_name: this.former_middle_name,
      former_last_name: this.former_last_name,
      former_suffix: this.former_suffix,
      sex: this.sex,
      sex_descr: this.sex_descr,
      gender_identities: this.gender_identities.map((gender_identity) => this.jsonParse(gender_identity)),
      sexual_orientation: this.sexual_orientation,
      other_sexual_orientation_descr: this.other_sexual_orientation_descr,
      other_gender_identity: this.other_gender_identity,
      has_ssn: this.has_ssn,
      birth_date: this.birth_date,
      us_armed_forces_status: this.us_armed_forces_status,
      us_military_anticipated_status: this.us_military_anticipated_status,
      us_military_experiences: this.us_military_experiences,
      current_enrollment_status: this.jsonParse(this.current_enrollment_status),
      has_graduated_from_high_school: this.has_graduated_from_high_school,
      has_taken_college_course: this.has_taken_college_course,
      plan_to_begin_studying_year: this.plan_to_begin_studying_year,
      apply_for_need_based_financial_aid: this.apply_for_need_based_financial_aid,
      disaster_impacts: this.disaster_impacts.map((disaster_impact) => this.jsonParse(disaster_impact)),
      covid_descr: this.covid_descr,
      address: this.address,
      used_mailing_address: this.used_mailing_address,
      mailing_address: this.mailing_address,
      is_temporary_mailing_address: this.is_temporary_mailing_address,
      temporary_mailing_address_from_date: this.temporary_mailing_address_from_date,
      temporary_mailing_address_to_date: this.temporary_mailing_address_to_date,
      residency: this.residency,
      preferred_phone: this.preferred_phone,
      preferred_phone_number: this.preferred_phone_number,
      used_alternate_phone: this.used_alternate_phone,
      alternate_phone_number: this.alternate_phone_number,
      is_alt_text_auth: this.is_alt_text_auth,
      can_release_contact_info: this.can_release_contact_info,
      birth_country: this.birth_country,
      birth_city: this.birth_city,
      birth_state: this.birth_state,
      lived_in_us_yr: this.lived_in_us_yr,
      lived_outside_us_yr: this.lived_outside_us_yr,
      religion: this.religion,
      religion_descr: this.religion_descr,
      ever_received_discharge: this.ever_received_discharge,
      discharge_descr: this.discharge_descr,
      is_hispanic_or_latino: this.is_hispanic_or_latino,
      hispanic_or_latino_identities: this.hispanic_or_latino_identities,
      hispanic_or_latino_descr: this.hispanic_or_latino_descr,
      other_identities: this.other_identities,
      amerind_background: this.amerind_background.map((the_amerind_background) => this.jsonParse(the_amerind_background)),
      ucai_tribe_status: this.ucai_tribe_status,
      is_ucai_want_support_info: this.is_ucai_want_support_info,
      tribal_identity: this.tribal_identity,
      is_federally_recognized_tribe: this.is_federally_recognized_tribe,
      tribal_enrollment_number: this.tribal_enrollment_number,
      amerind_addl: this.amerind_addl,
      asian_backgrounds: this.asian_backgrounds,
      east_asian_descr: this.east_asian_descr,
      south_asian_descr: this.south_asian_descr,
      southeast_asian_descr: this.southeast_asian_descr,
      african_american_backgrounds:  this.african_american_backgrounds,
      african_american_descr: this.african_american_descr,
      native_hawaiian_or_other_pacific_islander_backgrounds: this.native_hawaiian_or_other_pacific_islander_backgrounds,
      native_hawaiian_or_other_pacific_islander_descr: this.native_hawaiian_or_other_pacific_islander_descr,
      white_backgrounds: this.white_backgrounds,
      white_descr: this.white_descr,
      southwest_asian_and_north_africans_background: this.southwest_asian_and_north_africans_background,
      other_southwest_asian_descr: this.other_southwest_asian_descr,
      other_north_asian_descr: this.other_north_asian_descr,
      lang_first_code: this.lang_first_code,
      lang_second_code: this.lang_second_code,
      backgrounds: this.backgrounds,
      military_service: this.military_service,
      has_military_transcript: this.has_military_transcript,
      is_military_dependent: this.is_military_dependent,
      languages_length: this.languages_length,
      languages: this.languages,
      citizenship_status: this.citizenship_status,
      is_tribal_member: this.is_tribal_member,
      tribal_membership_type: this.tribal_membership_type,
      tribal_affiliation_fr: this.tribal_affiliation_fr,
      is_agree_tribe_use_verification: this.is_agree_tribe_use_verification,
      tribal_affiliation_sr: this.tribal_affiliation_sr,
      tribal_affiliation_cn: this.tribal_affiliation_cn,
      green_card_file: this.green_card_file,
      arn_expiration_date: this.arn_expiration_date,
      arn_number: this.arn_number,
      ssn_number: this.ssn_number,
      list_citizenship: this.list_citizenship,
      hold_us_visa: this.hold_us_visa,
      held_visa: this.held_visa,
      planned_visa: this.planned_visa,
      planned_visa_number: this.planned_visa_number,
      hold_planned_visa: this.hold_planned_visa,
      visa_date_issued: this.visa_date_issued,
      daca_approved: this.daca_approved,
      daca_expiry: this.daca_expiry,
      has_graduated_from_cahs: this.has_graduated_from_cahs,
      has_attended_cahs: this.has_attended_cahs,
      is_living_in_ca: this.is_living_in_ca,
      has_lived_in_ca_when: this.has_lived_in_ca_when,
      has_lived_in_ca_since: this.has_lived_in_ca_since,
      is_parent_working_at_uc: this.is_parent_working_at_uc,
      apply_fee_waiver: this.apply_fee_waiver,
      common_app_fee_waiver_indicators: this.common_app_fee_waiver_indicators,
      coalition_app_fee_waiver_indicators: this.coalition_app_fee_waiver_indicators,
      fee_waiver_signature: this.fee_waiver_signature,
      want_info_from_strive_for_college: this.want_info_from_strive_for_college,
      high_school_graduation_month: this.high_school_graduation_month
    };
  }

  get family() {
    return {
      household: this.household.map((the_household) => this.jsonParse(the_household)),
      parents_marital_status: this.parents_marital_status,
      other_parent_marital_descr: this.other_parent_marital_descr,
      family_income_curr_year: this.family_income_curr_year,
      family_size_curr_year: this.family_size_curr_year,
      household_size: this.household_size,
      highest_parent_ed: this.jsonParse(this.highest_parent_ed),
      has_children: this.has_children,
      children_number: this.children_number,
      whom_make_your_permanent_home: this.whom_make_your_permanent_home,
      living_situation_descr: this.living_situation_descr,
      divorced_yr: this.divorced_yr,
      want_to_list_step_parents: this.want_to_list_step_parents,
      step_parents_number: this.step_parents_number,
      parents_length: this.parents_length,
      parents: this.parents,
      legal_guardians_length: this.legal_guardians_length,
      legal_guardians: this.legal_guardians,
      siblings_number: this.siblings_number,
      siblings: this.siblings
    };
  }

  get hasInProgressColleges() {
    return this.colleges.some(college => college.isInProgress);
  }

  get campusesMajors() {
    return {
      accepted_us_values: this.accepted_us_values,
      apply_level: this.apply_level,
      entering_level: this.entering_level
    };
  }

  get education() {
    return {
      math_flag_78th: this.math_flag_78th,
      math_courses_78th: this.math_courses_78th,
      language_flag_78th: this.language_flag_78th,
      language_courses_78th: this.language_courses_78th,
      intl_exp_flag_78th: this.intl_exp_flag_78th,
      intl_exp_lang_code: this.intl_exp_lang_code,
      intl_exp_lang_other: this.intl_exp_lang_other,
      equiv_exam_yn: this.equiv_exam_yn,
      high_school_state_type: this.high_school_state_type,
      high_school_filter_state: this.high_school_filter_state,
      high_school_filter_country: this.high_school_filter_country,
      high_school_type: this.high_school_type,
      high_school_value: this.high_school_value,
      high_school_name: this.high_school_name,
      high_school_ceeb_code: this.high_school_ceeb_code,
      high_school_school_type_code: this.high_school_school_type_code,
      high_school_city: this.high_school_city,
      high_school_state: this.high_school_state,
      high_school_state_value: this.high_school_state_value,
      high_school_zip: this.high_school_zip,
      high_school_country_code: this.high_school_country_code,
      high_school_country_value: this.high_school_country_value,
      high_school_address_line_1: this.high_school_address_line_1,
      high_school_address_line_2: this.high_school_address_line_2,
      high_school_address_line_3: this.high_school_address_line_3,
      high_school_entry_month: this.high_school_entry_month,
      high_school_is_boarding_high_school: this.high_school_is_boarding_high_school,
      high_school_is_exam_based: this.high_school_is_exam_based,
      high_school_grade_frequency: this.high_school_grade_frequency,
      high_school_block_sched: this.high_school_block_sched,
      high_school_grade_scale: this.jsonParse(this.high_school_grade_scale),
      high_school_other_grade_scale: this.high_school_other_grade_scale,
      high_school_num_grade_min: this.high_school_num_grade_min,
      high_school_num_grade_max: this.high_school_num_grade_max,
      high_school_live_on_campus: this.high_school_live_on_campus,
      high_school_graduate_from_school: this.high_school_graduate_from_school,
      high_school_graduation_month: this.high_school_graduation_month,
      high_school_exit_month: this.high_school_exit_month,
      high_school_receive_high_school_equivalency: this.high_school_receive_high_school_equivalency,
      high_school_receive_high_school_equivalency_month: this.high_school_receive_high_school_equivalency_month,
      high_school_descr: this.high_school_descr,
      high_school_progression: this.high_school_progression,
      high_school_progression_descr: this.high_school_progression_descr,
      high_school_grade_level: this.jsonParse(this.high_school_grade_level),
      high_school_gpa_scale_other: this.high_school_gpa_scale_other,
      high_school_type_code: this.high_school_type_code,
      high_school_specialized_curriculums: this.high_school_specialized_curriculums,
      high_school_specialized_curriculums_descr: this.high_school_specialized_curriculums_descr,
      high_school_grading_systems: this.high_school_grading_systems,
      high_school_grading_system_descr: this.high_school_grading_system_descr,
      high_school_degree_code: this.high_school_degree_code,
      high_school_degree_descr: this.high_school_degree_descr,
      counselor_first_name: this.counselor_first_name,
      counselor_last_name: this.counselor_last_name,
      counselor_email: this.counselor_email,
      counselor_relationships: this.counselor_relationships,
      high_school_courses: this.high_school_courses.map((course) => ({
        ...course,
        grade_ct: this.jsonParse(course.grade_ct),
        sced: this.jsonParse(course.sced),
        subject: this.jsonParse(course.subject),
        type: this.jsonParse(course.type),
        year: this.jsonParse(course.year),
        terms: course.terms.map((term) => this.jsonParse(term))
      })),
      other_high_schools_length: this.other_high_schools_length,
      other_high_schools: this.other_high_schools.map((other_high_school) => ({
        ...other_high_school,
        grade_frequency: other_high_school.grade_frequency,
        grade_scale: this.jsonParse(other_high_school.grade_scale),
        courses: other_high_school.courses.map((course) => ({
          ...course,
          grade_ct: this.jsonParse(course.grade_ct),
          sced: this.jsonParse(course.sced),
          subject: this.jsonParse(course.subject),
          type: this.jsonParse(course.type),
          year: this.jsonParse(course.year),
          terms: course.terms.map((term) => this.jsonParse(term))
        }))
      })),
      non_senior_complete: this.non_senior_complete,
      non_senior_explain: this.non_senior_explain,
      senior_complete: this.senior_complete,
      senior_explain: this.senior_explain,
      why_leave_other_high_school: this.why_leave_other_high_school,
      has_no_courses: this.has_no_courses,
      additional_comments: this.additional_comments,
      has_college_attended: this.has_college_attended,
      in_college: this.in_college,
      college_credits: this.college_credits,
      after_high_school_credits: this.after_high_school_credits,
      disaster_impacts_transfer: this.disaster_impacts_transfer.map((disaster_impact_transfer) => this.jsonParse(disaster_impact_transfer)),
      other_disaster_impact_transfer_desc: this.other_disaster_impact_transfer_desc,
      colleges_length: this.colleges_length,
      colleges: this.colleges.map(college => ({
        ...college,
        completed_courses: college.completed_courses.map(course => ({
          ...course,
          term: this.jsonParse(course.term),
          term_system: this.jsonParse(course.term_system)
        })),
        in_progress_courses: college.in_progress_courses.map(course => ({
          ...course,
          term: this.jsonParse(course.term),
          term_system: this.jsonParse(course.term_system),
          taking_courses: this.jsonParse(course.taking_courses)
        }))
      })),
      completed_college_complete: this.completed_college_complete,
      in_progress_college_complete: this.in_progress_college_complete,
      graduating_class_size: this.graduating_class_size,
      class_rank_reporting: this.class_rank_reporting,
      exact_class_rank: this.exact_class_rank,
      decile_rank: this.decile_rank,
      quintile_rank: this.quintile_rank,
      quartile_rank: this.quartile_rank,
      class_rank_weighting: this.class_rank_weighting,
      gpa_scale_reporting: this.gpa_scale_reporting,
      cumulative_gpa: this.cumulative_gpa,
      gpa_weighting: this.gpa_weighting,
      courses_scheduling_system: this.courses_scheduling_system,
      courses_length: this.courses_length,
      courses: this.courses,
      wish_to_report_honors: this.wish_to_report_honors,
      honors: this.honors,
      community_based_organizations_length: this.community_based_organizations_length,
      community_based_organizations: this.community_based_organizations,
      career_interest: this.career_interest,
      career_interest_descr: this.career_interest_descr,
      highest_degree_to_earn: this.highest_degree_to_earn,
      academic_interests: this.academic_interests.map((academic_interest) => this.jsonParse(academic_interest))
    };
  }

  get high_schools() {
    return [
      new OtherHighSchool({
        value: this.high_school_type,
        name: this.high_school_name,
        courses: this.high_school_courses
      }),
      ...this.other_high_schools.slice(0, this.other_high_schools_length)
    ];
  }

  get testing() {
    return {
      wish_to_report_tests: this.wish_to_report_tests,
      tests_taken: this.tests_taken,
      gave_leaving_exams: this.gave_leaving_exams,
      acts_number: this.acts_number,
      acts_super_score: this.acts_super_score,
      act_id_number: this.act_id_number,
      took_act_plus_writing_test: this.took_act_plus_writing_test,
      acts_highest_composite_score: this.acts_highest_composite_score,
      acts_composite_date: this.acts_composite_date,
      acts_highest_english_score: this.acts_highest_english_score,
      acts_english_date: this.acts_english_date,
      acts_highest_math_score: this.acts_highest_math_score,
      acts_math_date: this.acts_math_date,
      acts_highest_reading_score: this.acts_highest_reading_score,
      acts_reading_date: this.acts_reading_date,
      acts_highest_science_score: this.acts_highest_science_score,
      acts_science_date: this.acts_science_date,
      acts_highest_writing_score: this.acts_highest_writing_score,
      acts_writing_date: this.acts_writing_date,
      future_act_sitting_months_length: this.future_act_sitting_months_length,
      future_act_sitting_months: this.future_act_sitting_months,
      sats_after_march_number: this.sats_after_march_number,
      took_sat_essay: this.took_sat_essay,
      sats_after_march_highest_reading_and_writing_score: this.sats_after_march_highest_reading_and_writing_score,
      sats_after_march_reading_and_writing_date: this.sats_after_march_reading_and_writing_date,
      sats_after_march_highest_math_score: this.sats_after_march_highest_math_score,
      sats_after_march_math_date: this.sats_after_march_math_date,
      sats_after_march_highest_essay_score: this.sats_after_march_highest_essay_score,
      sats_after_march_essay_date: this.sats_after_march_essay_date,
      sats_after_march_highest_total_score: this.sats_after_march_highest_total_score,
      sats_after_march_total_date: this.sats_after_march_total_date,
      sats_after_march_highest_sub_reading_score:this.sats_after_march_highest_sub_reading_score,
      sats_after_march_highest_sub_writing_score: this.sats_after_march_highest_sub_writing_score,
      sats_after_march_highest_sub_math_score: this.sats_after_march_highest_sub_writing_score,
      future_sat_sitting_months_length: this.future_sat_sitting_months_length,
      future_sat_sitting_months: this.future_sat_sitting_months,
      sats_number: this.sats_number,
      sats: this.sats,
      aps_number: this.aps_number,
      aps: this.aps,
      ibs_number: this.ibs_number,
      ibs: this.ibs,
      a_levels_number: this.a_levels_number,
      a_levels: this.a_levels.map((a_level) => ({
        ...a_level,
        score: this.jsonParse(a_level.score)
      })),
      toefls_number: this.toefls_number,
      toefls_reg_number: this.toefls_reg_number,
      toefls_is_completed: this.toefls_is_completed,
      toefls_has_received_score: this.toefls_has_received_score,
      future_toefls_sitting_months_length: this.future_toefls_sitting_months_length,
      future_toefls_sitting_months: this.future_toefls_sitting_months,
      toefls_best_score: this.toefls_best_score,
      toefls_highest_reading_score: this.toefls_highest_reading_score,
      toefls_reading_date: this.toefls_reading_date,
      toefls_highest_speaking_score: this.toefls_highest_speaking_score,
      toefls_speaking_date: this.toefls_speaking_date,
      toefls_highest_listening_score: this.toefls_highest_listening_score,
      toefls_listening_date: this.toefls_listening_date,
      toefls_highest_writing_score: this.toefls_highest_writing_score,
      toefls_writing_date: this.toefls_writing_date,
      toefls_total_score: this.toefls_total_score,
      toefls_total_score_date: this.toefls_total_score_date,
      toefl_papers_number: this.toefl_papers_number,
      toefl_papers_is_completed: this.toefl_papers_is_completed,
      toefl_papers_reg_number: this.toefl_papers_reg_number,
      toefl_papers_has_received_score: this.toefl_papers_has_received_score,
      future_toefl_papers_sitting_months_length: this.future_toefl_papers_sitting_months_length,
      future_toefl_papers_sitting_months: this.future_toefl_papers_sitting_months,
      toefl_papers_total_score: this.toefl_papers_total_score,
      toefl_papers_total_score_date: this.toefl_papers_total_score_date,
      toefl_papers_highest_reading_score: this.toefl_papers_highest_reading_score,
      toefl_papers_reading_date: this.toefl_papers_reading_date,
      toefl_papers_highest_listening_score: this.toefl_papers_highest_listening_score,
      toefl_papers_listening_date: this.toefl_papers_listening_date,
      toefl_papers_highest_writing_score: this.toefl_papers_highest_writing_score,
      toefl_papers_writing_date: this.toefl_papers_writing_date,
      toefl_papers_highest_twe_score: this.toefl_papers_highest_twe_score,
      toefl_papers_twe_date: this.toefl_papers_twe_date,
      ptes_number: this.ptes_number,
      future_ptes_sitting_months_length: this.future_ptes_sitting_months_length,
      future_ptes_sitting_months: this.future_ptes_sitting_months,
      ptes_highest_listening_score: this.ptes_highest_listening_score,
      ptes_listening_date: this.ptes_listening_date,
      ptes_highest_reading_score: this.ptes_highest_reading_score,
      ptes_reading_date: this.ptes_reading_date,
      ptes_highest_speaking_score: this.ptes_highest_speaking_score,
      ptes_speaking_date: this.ptes_speaking_date,
      ptes_highest_writing_score: this.ptes_highest_writing_score,
      ptes_writing_date: this.ptes_writing_date,
      ptes_highest_grammar_score: this.ptes_highest_grammar_score,
      ptes_grammar_date: this.ptes_grammar_date,
      ptes_highest_oral_fluency_score: this.ptes_highest_oral_fluency_score,
      ptes_oral_fluency_date: this.ptes_oral_fluency_date,
      ptes_highest_pronunciation_score: this.ptes_highest_pronunciation_score,
      ptes_pronunciation_date: this.ptes_pronunciation_date,
      ptes_highest_spelling_score: this.ptes_highest_spelling_score,
      ptes_spelling_date: this.ptes_spelling_date,
      ptes_highest_vocabulary_score: this.ptes_highest_vocabulary_score,
      ptes_vocabulary_date: this.ptes_vocabulary_date,
      ptes_highest_discourse_score: this.ptes_highest_discourse_score,
      ptes_discourse_date: this.ptes_discourse_date,
      ielts_number: this.ielts_number,
      ielts_is_completed: this.ielts_is_completed,
      ielts_form_number: this.ielts_form_number,
      ielts_has_received_score: this.ielts_has_received_score,
      future_ielts_sitting_months_length: this.future_ielts_sitting_months_length,
      future_ielts_sitting_months: this.future_ielts_sitting_months,
      ielts_highest_listening_score: this.ielts_highest_listening_score,
      ielts_listening_date: this.ielts_listening_date,
      ielts_highest_reading_score: this.ielts_highest_reading_score,
      ielts_reading_date: this.ielts_reading_date,
      ielts_highest_writing_score: this.ielts_highest_writing_score,
      ielts_writing_date: this.ielts_writing_date,
      ielts_highest_speaking_score: this.ielts_highest_speaking_score,
      ielts_speaking_date: this.ielts_speaking_date,
      ielts_overall_score: this.ielts_overall_score,
      ielts_overall_score_date: this.ielts_overall_score_date,
      duolingoes_number: this.duolingoes_number,
      duolingoes: this.duolingoes,
      future_duolingoes_sitting_months_length: this.future_duolingoes_sitting_months_length,
      future_duolingoes_sitting_months: this.future_duolingoes_sitting_months,
      other_proficiency_exams_number: this.other_proficiency_exams_number,
      other_proficiency_exams: this.other_proficiency_exams,
      leaving_exams_number: this.leaving_exams_number,
      leaving_exams: this.leaving_exams
    };
  }

  get scholarshipsPrograms() {
    return {
      scholarships: this.scholarships,
      eop_selected: this.eop_selected,
      eop_reason: this.eop_reason
    };
  }

  get writing() {
    return {
      understand_to_submit_essay: this.understand_to_submit_essay,
      common_app_essay_topic: this.common_app_essay_topic,
      common_app_essay_content: this.common_app_essay_content,
      coalition_app_essay_topic: this.coalition_app_essay_topic,
      coalition_app_essay_content: this.coalition_app_essay_content,
      california_app_essay_topics: this.california_app_essay_topics,
      california_app_essay_contents: this.california_app_essay_contents,
      received_disciplinary_action: this.received_disciplinary_action,
      disciplinary_history_descr: this.disciplinary_history_descr,
      wish_to_share_about_covid: this.wish_to_share_about_covid,
      covid_descr: this.covid_descr,
      wish_to_share_not_reflected_by_covid: this.wish_to_share_not_reflected_by_covid,
      not_reflected_by_covid_descr: this.not_reflected_by_covid_descr,
      any_additional_information: this.any_additional_information,
      provide_additional_information: this.provide_additional_information,
      uc_additional_comments: this.uc_additional_comments
    };
  }

  jsonStringify(item) {
    if (typeof item !== "string" && item !== null) {
      return JSON.stringify(item);
    } else {
      return item;
    }
  }

  jsonParse(item) {
    return item && item !== "" && !Number.isNaN(Number(item)) ? JSON.parse(item) : item;
  }
}

export class MilitaryExperience {
  constructor(props) {
    const { branch = null, from_month = "", to_month = "" } = props || {};

    Object.assign(this, {
      branch,
      from_month,
      to_month
    });
  }
}

export class LegalGuardian {
  constructor(props) {
    const {
      prefix = null,
      first_name = "",
      middle_name = "",
      last_name = "",
      former_last_name = "",
      suffix = null,
      relationship = "",
      email = "",
      preferred_phone = null,
      preferred_phone_number = null,
      address_type = null,
      address = null,
      occupation = null,
      occupation_descr = "",
      employment_status = null,
      position = "",
      occupation_place = "",
      is_employed_or_retired_college = null,
      college_employer = null,
      education_level = "",
      highest_level_of_education = "",
      attended_institutions_number = 0,
      colleges = []
    } = props || {};

    const filled_colleges = Array.from(
      Array(COUNSELING_PROFILE_MAX_PARENT_SCHOOL_COUNT)
    ).map((_value, index) =>
      colleges && colleges[index]
        ? new LegalGuardianCollege(colleges[index])
        : new LegalGuardianCollege()
    );

    Object.assign(this, {
      prefix,
      first_name,
      middle_name,
      last_name,
      former_last_name,
      suffix,
      relationship,
      email,
      preferred_phone,
      preferred_phone_number: new PhoneNumber(preferred_phone_number),
      address_type,
      address: new Address(address),
      occupation,
      occupation_descr,
      employment_status,
      position,
      occupation_place,
      is_employed_or_retired_college,
      education_level,
      highest_level_of_education,
      attended_institutions_number,
      college_employer: new Address(college_employer),
      colleges: filled_colleges
    });
  }
}

export class LegalGuardianCollege {
  constructor(props) {
    const {
      value = "",
      name = "",
      ceeb_code = "",
      school_type_code = "",
      city = "",
      state = "",
      state_value = "",
      zip = "",
      country_code = "",
      country_value = "",
      address_line_1 = "",
      address_line_2 = "",
      address_line_3 = "",
      degrees_length = 0,
      degrees = []
    } = props || {};

    const filled_degrees = Array.from(
      Array(COUNSELING_PROFILE_MAX_PARENT_SCHOOL_DEGREE_COUNT)
    ).map((_value, index) =>
      degrees && degrees[index] ? new Degree(degrees[index]) : new Degree()
    );

    Object.assign(this, {
      value,
      name,
      ceeb_code,
      school_type_code,
      city,
      state,
      state_value,
      zip,
      country_code,
      country_value,
      address_line_1,
      address_line_2,
      address_line_3,
      degrees_length,
      degrees: filled_degrees
    });
  }
}

export class Address {
  constructor(props) {
    const {
      county = null,
      country_code = "",
      country_value = "",
      address_line_1 = "",
      address_line_2 = "",
      address_line_3 = "",
      city = "",
      state = "",
      state_value = "",
      zip = ""
    } = props || {};

    Object.assign(this, {
      county,
      country_code,
      country_value,
      address_line_1,
      address_line_2,
      address_line_3,
      city,
      state,
      state_value,
      zip
    });
  }
}

export class PhoneNumber {
  constructor(props) {
    const { country_code = null, phone_number = "", extension = "" } =
      props || {};

    Object.assign(this, {
      country_code,
      phone_number,
      extension
    });
  }
}

export class Language {
  constructor(props) {
    const { lang = null, proficiency = [] } = props || {};

    Object.assign(this, {
      lang,
      proficiency: proficiency || []
    });
  }
}

export class MathCourse78th {
  constructor(props) {
    const { course_name_code = "", semester_count = "" } = props || {};
    Object.assign(this, {
      course_name_code,
      semester_count
    });
  }
}

export class LanguageCourse78th {
  constructor(props) {
    const {
      class_name = "",
      semester_count = "",
      discipline_code = "",
      lote_code = ""
    } = props || {};
    Object.assign(this, {
      class_name,
      semester_count,
      discipline_code,
      lote_code
    });
  }
}

export class HighSchoolAcadYear {
  constructor(props) {
    const { grade_level = "", acad_year = "", has_summer_courses = "N" } = props || {};

    this.grade_level = grade_level;
    this.acad_year = acad_year;
    this.has_summer_courses = has_summer_courses;
  };
}

export class OtherHighSchool {
  constructor(props) {
    const {
      state_type = "",
      filter_state = "",
      filter_country = "",
      type = "",
      value = "",
      name = "",
      ceeb_code = "",
      school_type_code = "",
      city = "",
      state = "",
      state_value = "",
      zip = "",
      country_code = "",
      country_value = "",
      address_line_1 = "",
      address_line_2 = "",
      address_line_3 = "",
      attend_dates = [{ from_month: "", to_month: "" }],
      is_exam_based = null,
      block_sched = null,
      grading_systems = [],
      grading_system_descr = "",
      grade_frequency = "",
      grade_scale = "",
      num_grade_min = 0,
      num_grade_max = 0,
      other_grade_scale = "",
      courses = [],
      acad_years = []
    } = props || {};


    const filled_high_school_acad_years = Array.from(
      Array(Math.max(COUNSELING_PROFILE_MAX_SCHOOL_ACAD_YEAR_COUNT, acad_years.length))
    ).map((_value, index) =>
      acad_years && acad_years[index]
        ? new HighSchoolAcadYear(acad_years[index])
        : new HighSchoolAcadYear()
    );

    Object.assign(this, {
      state_type,
      filter_state,
      filter_country,
      type,
      value,
      name,
      ceeb_code,
      school_type_code,
      city,
      state,
      state_value,
      zip,
      country_code,
      country_value,
      address_line_1,
      address_line_2,
      address_line_3,
      attend_dates,
      is_exam_based,
      block_sched,
      grading_systems: grading_systems || [],
      grading_system_descr,
      grade_frequency: grade_frequency,
      grade_scale: this.jsonStringify(grade_scale),
      num_grade_min,
      num_grade_max,
      other_grade_scale,
      courses: (courses || []).map(course => new HighSchoolCourse(course)),
      acad_years: filled_high_school_acad_years
    });
  }

  get ninethCourses() {
    return this.courses.filter((course) => {
      return course.year === "{\"code\":\"9TH\",\"value\":\"9th or earlier\"}";
    });
  }

  get tenthCourses() {
    return this.courses.filter((course) => {
      return course.year === "{\"code\":\"10TH\",\"value\":\"10th or earlier\"}";
    });
  }

  get eleventhCourses() {
    return this.courses.filter((course) => {
      return course.year === "{\"code\":\"11TH\",\"value\":\"11th or earlier\"}";
    });
  }

  get twelvethCourses() {
    return this.courses.filter((course) => {
      return course.year === "{\"code\":\"12TH\",\"value\":\"12th or earlier\"}";
    });
  }

  jsonStringify(item) {
    if (typeof item !== "string" && item !== null) {
      return JSON.stringify(item);
    } else {
      return item;
    }
  }
}

export class HighSchoolCourse {
  constructor(props) {
    const {
      year = "",
      type = "",
      subject_area = "",
      subject = "",
      sced = "",
      transcript_name = "",
      grade_scale = null,
      grade_frequency = "",
      grade_ct = "",
      terms = [],
      grades = [],
      // REG, SUM
      session = "REG",
      block_sched = false,
      college_yn = false,
      num_grade_max = 0,
      num_grade_min = 0,
      other_grade_scale = "",
      honor_status_code
    } = props || {};

    Object.assign(this, {
      year: this.jsonStringify(year),
      type: this.jsonStringify(type),
      subject_area,
      subject: this.jsonStringify(subject),
      sced: this.jsonStringify(sced),
      transcript_name,
      grade_scale,
      grade_frequency,
      grade_ct: this.jsonStringify(grade_ct),
      terms: (terms || []).map(term => this.jsonStringify(term)),
      grades: (grades || []).map(grade => this.jsonStringify(grade)),
      session,
      block_sched,
      college_yn,
      num_grade_max,
      num_grade_min,
      other_grade_scale,
      honor_status_code
    });
  }

  get className() {
    const scedIsOther = !this.sced || this.sced.includes("-Other");
    if (scedIsOther) {
      return this.transcript_name;
    } else {
      if (this.sced !== "") {
        return JSON.parse(this.sced).name;
      } else {
        return this.transcript_name;
      }
    }
  }

  get subjectName() {
    if (this.subject !== "" && this.subject) {
      return JSON.parse(this.subject).value;
    } else {
      return "";
    }
  }

  jsonStringify(item) {
    if (typeof item !== "string" && item !== null && !Number.isNaN(Number(item))) {
      return JSON.stringify(item);
    } else {
      return item;
    }
  }
}

export class TakenCollegeGrade {
  constructor(props) {
    const {
      year_code = "",
      fall_term_year = "",
      winter_term_year = "",
      spring_term_year = "",
      summer_term_year = ""
    } = props || {};

    Object.assign(this, {
      year_code,
      fall_term_year,
      winter_term_year,
      spring_term_year,
      summer_term_year
    });
  }
}

export class TakenCollege {
  constructor(props) {
    const {
      value = "",
      name = "",
      ceeb_code = "",
      school_type_code = "",
      city = "",
      state = "",
      state_value = "",
      zip = "",
      country_code = "",
      country_value = "",
      address_line_1 = "",
      address_line_2 = "",
      address_line_3 = "",
      course_details = [],
      attend_dates = [{ from_month: "", to_month: "" }],
      grades = [new TakenCollegeGrade()],
      grading_systems = [],
      grading_system_descr = "",
      degree = "",
      completed_courses = [],
      in_progress_courses = []
    } = props || {};

    Object.assign(this, {
      value,
      name,
      ceeb_code,
      school_type_code,
      city,
      state,
      state_value,
      zip,
      country_code,
      country_value,
      address_line_1,
      address_line_2,
      address_line_3,
      attend_dates,
      grades: (grades || [new TakenCollegeGrade()]).map((grade) => new TakenCollegeGrade(grade)),
      grading_systems: (grading_systems || []),
      grading_system_descr,
      course_details: course_details || [],
      degree,
      completed_courses: (completed_courses || []).map(course => new CompletedCollegeCourse(course)),
      in_progress_courses: (in_progress_courses || []).map(course => new InProgressCollegeCourse(course)),
      courses: [...(in_progress_courses || []), ...(completed_courses || [])]
    });
  }

  get attended_years() {
    const { from_month, to_month } = this.attend_dates[0];
    if (!from_month || !to_month) {
      return [];
    } else {
      const [fromYear, toYear] = [
        Number(from_month.split("/")[1]),
        Number(to_month.split("/")[1])
      ];
      let attendedYears = [];
      for (let i = fromYear; i <= toYear; i += 1) {
        attendedYears.push(i);
      }
      return attendedYears;
    }
  }

  get isInProgress() {
    const convertToYYYYMM = date => {
      if (date) {
        const [mm, yyyy] = date.split("/");
        return `${yyyy}-${mm}-01`;
      } else {
        return date;
      }
    };
    return this.attend_dates.some(({ from_month, to_month }) => {
      return moment(moment().format("YYYY-MM-01")).isBetween(
        convertToYYYYMM(from_month), moment(convertToYYYYMM(to_month)).add(1, "days")
      );
    });
  }

  get takenCompletedCourseYears() {
    const years = new Set();
    this.completed_courses.forEach(({ year }) => {
      years.add(year);
    });

    const sortedYears = Array.from(years);
    sortedYears.sort((prev, next) => prev - next);
    return sortedYears;
  }

  get takenInProgressCourseYears() {
    const years = new Set();
    this.in_progress_courses.forEach(({ year }) => {
      years.add(year);
    });

    const sortedYears = Array.from(years);
    sortedYears.sort((prev, next) => prev - next);
    return sortedYears;
  }

  getCompletedCoursesByYear(year) {
    return this.completed_courses.filter((course) => course.year === year);
  }

  getInprogressCoursesByYear(year) {
    return this.in_progress_courses.filter((course) => course.year === year);
  }
}

export class CompletedCollegeCourse {
  constructor(props) {
    const {
      year = null,
      term = "",
      term_system = "",
      subject_area = "",
      subject = "",
      name = "",
      number = "",
      honor_status_code = "",
      dual_grade = "",
      credits = null
    } = props || {};

    Object.assign(this, {
      year,
      term: this.jsonStringify(term),
      term_system: this.jsonStringify(term_system),
      subject_area,
      subject,
      name,
      number,
      honor_status_code,
      dual_grade,
      credits
    });
  }

  jsonStringify(item) {
    if (typeof item !== "string" && item !== null) {
      return JSON.stringify(item);
    } else {
      return item;
    }
  }
}

export class InProgressCollegeCourse {
  constructor(props) {
    const {
      year = null,
      term = "",
      term_system = "",
      subject_area = "",
      subject = "",
      name = "",
      number = "",
      honor_status_code = "",
      taking_courses = "",
      credits = null
    } = props || {};

    Object.assign(this, {
      year,
      term: this.jsonStringify(term),
      term_system: this.jsonStringify(term_system),
      subject_area,
      subject,
      name,
      number,
      honor_status_code,
      taking_courses: this.jsonStringify(taking_courses),
      credits
    });
  }

  jsonStringify(item) {
    if (typeof item !== "string" && item !== null) {
      return JSON.stringify(item);
    } else {
      return item;
    }
  }
}

export class RecentCourse {
  constructor(props) {
    const { title = "", level = null, schedule = [] } = props || {};

    Object.assign(this, {
      title,
      level,
      schedule
    });
  }
}

export class Honor {
  constructor(props) {
    const { title = "", level = [], level_recognition = [] } = props || {};

    Object.assign(this, {
      title,
      level,
      level_recognition
    });
  }
}

export class Organization {
  constructor(props) {
    const {
      value = null,
      city = null,
      state = null,
      organization_name = null,
      mentor_prefix = null,
      mentor_first_name = "",
      mentor_last_name = "",
      mentor_email = "",
      mentor_phone_number = null
    } = props || {};

    Object.assign(this, {
      value,
      city,
      state,
      organization_name,
      mentor_prefix,
      mentor_first_name,
      mentor_last_name,
      mentor_email,
      mentor_phone_number: new PhoneNumber(mentor_phone_number)
    });
  }
}

export class SatSubject {
  constructor(props) {
    const { taken_month = "", subject = null, score = null } = props || {};

    Object.assign(this, {
      taken_month,
      subject,
      score
    });
  }
}

export class ApSubject {
  constructor(props) {
    const {
      taken_date = "",
      subject = null,
      score = null,
      is_completed = "",
      has_received_score = ""
    } = props || {};

    Object.assign(this, {
      taken_date,
      subject,
      score,
      is_completed,
      has_received_score
    });
  }
}

export class LeavingExam {
  constructor(props) {
    const {
      taken_date = "",
      examination_board = null,
      examination_board_descr = null,
      subject = null,
      score = null,
      score_type = null
    } = props || {};

    Object.assign(this, {
      taken_date,
      examination_board,
      examination_board_descr,
      subject,
      score,
      score_type
    });
  }
}

// without UC application
export class Activity {
  constructor(props) {
    const {
      type = null,
      name = "",
      experience_descr = "",
      current = null,
      awards = "",
      leader = null,
      leadership_title = "",
      sport = null,
      position_descr = "",
      organization_name = "",
      descr = "",
      grade_levels = [],
      participation_time = [],
      hours_spent_per_week = null,
      high_hours_spent_per_week = null,
      low_hours_spent_per_week = null,
      weeks_spent_per_yr = null,
      intend_to_participate_similar_in_college = null
    } = props || {};

    Object.assign(this, {
      type,
      name,
      experience_descr,
      current,
      awards,
      leader,
      leadership_title,
      sport,
      position_descr,
      organization_name,
      descr,
      grade_levels: grade_levels || [],
      participation_time: participation_time || [],
      hours_spent_per_week,
      high_hours_spent_per_week,
      low_hours_spent_per_week,
      weeks_spent_per_yr,
      intend_to_participate_similar_in_college
    });
  }
}

export class ActivityOrAwardFactory {
  static createActivityOrAward(activityOrAward) {
    switch (activityOrAward.category) {
      case "Award":
        return new Award(activityOrAward);
      case "EdPrep":
        return new EducationalPrepProgram(activityOrAward);
      case "ExtraCurr":
        return new ExtracurricularActivity(activityOrAward);
      case "OtherCourse":
        return new OtherCoursework(activityOrAward);
      case "Volunteer":
        return new Volunteer(activityOrAward);
      case "WorkExp":
        return new WorkExperience(activityOrAward);
      default:
        console.warn("Have no the category");
        return {};
    }
  }
}

export class Award {
  constructor(props) {
    const {
      category = "",
      award_type = "",
      award_name = "",
      award_requirements = "",
      award_achievement = "",
      is_award_levels = [],
      award_grades = []
    } = props || {};
    (is_award_levels || []).sort();
    (award_grades || []).sort();
    Object.assign(this, {
      category,
      award_type,
      award_name,
      award_requirements,
      award_achievement,
      is_award_levels,
      award_grades
    });
  }
}

export class EducationalPrepProgram {
  constructor(props) {
    const {
      category = "",
      ed_prep_id = "",
      ed_prep_other_name = "",
      ed_prep_description = "",
      ed_prep_grades = [],
      ed_prep_years_after12 = "",
      ed_prep_months_after12 = "",
      ed_prep_hours_per_week = "",
      ed_prep_weeks_per_year = ""
    } = props || {};
    (ed_prep_grades || []).sort();
    Object.assign(this, {
      category,
      ed_prep_id,
      ed_prep_other_name,
      ed_prep_description,
      ed_prep_grades,
      ed_prep_years_after12,
      ed_prep_months_after12,
      ed_prep_hours_per_week,
      ed_prep_weeks_per_year
    });
  }
}

export class ExtracurricularActivity {
  constructor(props) {
    const {
      category = "",
      extra_curr_name = "",
      extra_curr_description = "",
      extra_curr_grades = [],
      extra_curr_years_after12 = "",
      extra_curr_months_after12 = "",
      extra_curr_hours_per_week = "",
      extra_curr_weeks_per_year = ""
    } = props || {};
    (extra_curr_grades || []).sort();
    Object.assign(this, {
      category,
      extra_curr_name,
      extra_curr_description,
      extra_curr_grades,
      extra_curr_years_after12,
      extra_curr_months_after12,
      extra_curr_hours_per_week,
      extra_curr_weeks_per_year
    });
  }
}

export class OtherCoursework {
  constructor(props) {
    const {
      category = "",
      other_course_name = "",
      other_course_description = "",
      other_course_grades = [],
      other_course_years_after12 = "",
      other_course_months_after12 = "",
      other_course_hours_per_week = "",
      other_course_weeks_per_year = ""
    } = props || {};
    other_course_grades.sort();
    Object.assign(this, {
      category,
      other_course_name,
      other_course_description,
      other_course_grades,
      other_course_years_after12,
      other_course_months_after12,
      other_course_hours_per_week,
      other_course_weeks_per_year
    });
  }
}

export class Volunteer {
  constructor(props) {
    const {
      category = "",
      volunteer_org = "",
      volunteer_org_description = "",
      volunteer_description = "",
      volunteer_grades = [],
      volunteer_years_after12 = "",
      volunteer_months_after12 = "",
      volunteer_hours_per_week = "",
      volunteer_weeks_per_year = ""
    } = props || {};
    (volunteer_grades || []).sort();
    Object.assign(this, {
      category,
      volunteer_org,
      volunteer_org_description,
      volunteer_description,
      volunteer_grades,
      volunteer_years_after12,
      volunteer_months_after12,
      volunteer_hours_per_week,
      volunteer_weeks_per_year
    });
  }
}

export class WorkExperience {
  constructor(props) {
    const {
      category = "",
      work_exp_name = "",
      work_exp_desc = "",
      work_exp_pos_title = "",
      work_exp_pos_desc = "",
      work_exp_grades = [],
      is_work_exp_school9 = "",
      work_exp9_school_hours_per_week = "",
      is_work_exp_summer9 = "",
      work_exp9_summer_hours_per_week = "",
      is_work_exp_school10 = "",
      work_exp10_school_hours_per_week = "",
      is_work_exp_summer10 = "",
      work_exp10_summer_hours_per_week = "",
      is_work_exp_school11 = "",
      work_exp11_school_hours_per_week = "",
      is_work_exp_summer11 = "",
      work_exp11_summer_hours_per_week = "",
      is_work_exp_school12 = "",
      work_exp12_school_hours_per_week = "",
      is_work_exp_summer12 = "",
      work_exp12_summer_hours_per_week = "",
      work_exp_years_after12 = "",
      work_exp_months_after12 = "",
      is_work_exp_school_after12 = "",
      work_exp_after12_school_hours_per_week = "",
      is_work_exp_summer_after12 = "",
      work_exp_after12_summer_hours_per_week = "",
      is_work_exp_curr_working = "",
      work_exp_start = "",
      work_exp_end = ""
    } = props || {};
    (work_exp_grades || []).sort();
    Object.assign(this, {
      category,
      work_exp_name,
      work_exp_desc,
      work_exp_pos_title,
      work_exp_pos_desc,
      work_exp_grades,
      is_work_exp_school9,
      work_exp9_school_hours_per_week,
      is_work_exp_summer9,
      work_exp9_summer_hours_per_week,
      is_work_exp_school10,
      work_exp10_school_hours_per_week,
      is_work_exp_summer10,
      work_exp10_summer_hours_per_week,
      is_work_exp_school11,
      work_exp11_school_hours_per_week,
      is_work_exp_summer11,
      work_exp11_summer_hours_per_week,
      is_work_exp_school12,
      work_exp12_school_hours_per_week,
      is_work_exp_summer12,
      work_exp12_summer_hours_per_week,
      work_exp_years_after12,
      work_exp_months_after12,
      is_work_exp_school_after12,
      work_exp_after12_school_hours_per_week,
      is_work_exp_summer_after12,
      work_exp_after12_summer_hours_per_week,
      is_work_exp_curr_working,
      work_exp_start,
      work_exp_end
    });
  }
}

export class IbSubject {
  constructor(props) {
    const {
      taken_date = "",
      subject_area = "",
      subject = null,
      level = null,
      score = null,
      is_completed = "",
      has_received_score = ""
    } = props || {};

    Object.assign(this, {
      taken_date,
      subject_area,
      subject,
      level,
      score,
      is_completed,
      has_received_score
    });
  }
}

export class ALevelSubject {
  constructor(props) {
    const { taken_date = "", title = "", score = "" } = props || {};

    Object.assign(this, {
      taken_date,
      title,
      score: this.jsonStringify(score)
    });
  }

  jsonStringify(item) {
    if (typeof item !== "string" && item !== null) {
      return JSON.stringify(item);
    } else {
      return item;
    }
  }
}

export class Duolingo {
  constructor(props) {
    const {
      taken_date = "",
      score = "",
      is_completed = null,
      has_received_score = null,
      literacy_score = null,
      literacy_date = "",
      conversation_score = null,
      conversation_date = "",
      comprehension_score = null,
      comprehension_date = "",
      production_score = null,
      production_date = ""
    } = props || {};

    Object.assign(this, {
      taken_date,
      score,
      is_completed,
      has_received_score,
      literacy_score,
      literacy_date,
      conversation_score,
      conversation_date,
      comprehension_score,
      comprehension_date,
      production_score,
      production_date
    });
  }
}

export class OtherProficiencyExam {
  constructor(props) {
    const { taken_date = "", title = "", score = "" } = props || {};

    Object.assign(this, {
      taken_date,
      title,
      score
    });
  }
}

export class Parent {
  constructor(props) {
    const {
      type = null,
      is_living = null,
      deceased_date = "",
      prefix = null,
      first_name = "",
      middle_name = "",
      last_name = "",
      former_last_name = "",
      suffix = null,
      preferred_email = "",
      preferred_phone = null,
      preferred_phone_number = null,
      birth_country = null,
      address_is = null,
      address = null,
      occupation = "",
      occupation_descr = "",
      position = "",
      occupation_place = "",
      education_level = "",
      highest_level_of_education = "",
      attended_institutions_number = 0,
      colleges = []
    } = props || {};

    const filled_colleges = Array.from(
      Array(COUNSELING_PROFILE_MAX_PARENT_SCHOOL_COUNT)
    ).map((_value, index) =>
      colleges && colleges[index]
        ? new ParentCollege(colleges[index])
        : new ParentCollege()
    );

    Object.assign(this, {
      type,
      is_living,
      deceased_date,
      prefix,
      first_name,
      middle_name,
      last_name,
      former_last_name,
      suffix,
      preferred_email,
      preferred_phone,
      preferred_phone_number: new PhoneNumber(preferred_phone_number),
      birth_country,
      address_is,
      address: new Address(address),
      occupation,
      occupation_descr,
      position,
      occupation_place,
      education_level,
      highest_level_of_education,
      attended_institutions_number,
      colleges: filled_colleges
    });
  }
}

export class ParentCollege {
  constructor(props) {
    const {
      value = "",
      name = "",
      ceeb_code = null,
      school_type_code = "",
      city = "",
      state = "",
      state_value = "",
      zip = "",
      country_code = null,
      country_value = "",
      address_line_1 = "",
      address_line_2 = "",
      address_line_3 = "",
      degrees_length = 0,
      degrees = []
    } = props || {};

    const filled_degrees = Array.from(
      Array(COUNSELING_PROFILE_MAX_PARENT_SCHOOL_DEGREE_COUNT)
    ).map((_value, index) =>
      degrees && degrees[index] ? new Degree(degrees[index]) : new Degree()
    );

    Object.assign(this, {
      value,
      name,
      ceeb_code,
      school_type_code,
      city,
      state,
      state_value,
      zip,
      country_code,
      country_value,
      address_line_1,
      address_line_2,
      address_line_3,
      degrees_length,
      degrees: filled_degrees
    });
  }
}

export class Degree {
  constructor(props) {
    const { name = "", received_yr = "" } = props || {};

    Object.assign(this, {
      name,
      received_yr
    });
  }
}

export class Sibling {
  constructor(props) {
    const {
      first_name = "",
      middle_name = "",
      last_name = "",
      age = null,
      relationship = "",
      education_level = "",
      college = null
    } = props || {};

    Object.assign(this, {
      first_name,
      middle_name,
      last_name,
      age,
      relationship,
      education_level,
      college: new SiblingCollege(college)
    });
  }
}

export class SiblingCollege {
  constructor(props) {
    const {
      value = "",
      name = "",
      ceeb_code = null,
      school_type_code = "",
      city = "",
      state = "",
      state_value = "",
      zip = "",
      country_code = null,
      country_value = "",
      address_line_1 = "",
      address_line_2 = "",
      address_line_3 = "",
      degree = null
    } = props || {};

    Object.assign(this, {
      value,
      name,
      ceeb_code,
      school_type_code,
      city,
      state,
      state_value,
      zip,
      country_code,
      country_value,
      address_line_1,
      address_line_2,
      address_line_3,
      degree: new SiblingCollegeDegree(degree)
    });
  }
}

export class SiblingCollegeDegree {
  constructor(props) {
    const { name = "", from_month = "", to_month = "" } = props || {};

    Object.assign(this, {
      name,
      from_month,
      to_month
    });
  }
}
