import ApiService from "@/common/api.service";

export default {
  getThirdAccounts(studentUserId) {
    return ApiService.query("counseling-third-accounts", {
      student_user_id: studentUserId
    });
  },
  getThirdAccount(thirdAppType, studentUserId) {
    return ApiService.query("counseling-third-account", {
      third_service_type: thirdAppType,
      student_user_id: studentUserId
    });
  },
  storeThirdAccount(thirdAccount) {
    return ApiService.post("counseling-third-account", thirdAccount);
  },
  loginThirdAccounts(thirdAccount) {
    return ApiService.post("counseling-third-account/login", {
      third_service_type: thirdAccount.third_service_type,
      student_user_id: thirdAccount.student_user_id
    });
  },
  batchThirdAppColleges(thirdAppColleges) {
    return ApiService.post("counseling-school/batch", thirdAppColleges);
  },
  asyncColleges(thirdApp) {
    return ApiService.post("counseling-third-account/sync/colleges", thirdApp);
  },
  getThirdAppColleges(thirdAppType, studentUserId) {
    switch (thirdAppType) {
      case "common_app":
        return ApiService.query("common-app/colleges", {
          student_user_id: studentUserId
        });
      default:
        throw `getThirdAppColleges: Have no the third app id: ${thirdAppType}`;
    }
  },
  getUsingApplication(studentUserId) {
    return ApiService.query("counseling-third-applications", {
      student_user_id: studentUserId
    });
  },
  updateUsingApplications(studentUserId, usingApplications) {
    return ApiService.patch("counseling-third-applications", {
      student_user_id: studentUserId,
      user_counseling_third_applications: usingApplications
    });
  },
  sendCounselorInvitation(studentUserId, thirdServiceType) {
    return ApiService.post(`counseling-profile/send-counselor-invitation/${studentUserId}`, {
      third_service_type: thirdServiceType
    });
  }
};
