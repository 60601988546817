/* eslint-disable no-empty-pattern */
import thirdAppApi from "@/apis/thirdApp";
import counselingProfileApi from "@/apis/counselingProfile";
import ThirdApp from "@/class/ThirdApp";
import { Message } from "element-ui";
import i18n from "@/i18n.js";

const state = {
  usingApplications: [],
  thirdAppAccounts: [],
  schoolsOfThird: [],
  thirdApp: new ThirdApp(),
  thirdAppsDetail: []
};

const getters = {
  usingApplications(state) {
    return state.usingApplications;
  },
  thirdApp(state) {
    return state.thirdApp;
  },
  thirdAppsDetail(state) {
    return state.thirdAppsDetail;
  },
  thirdAppAccountsMap(state) {
    const thirdAppAccountsMap = {};
    state.thirdAppAccounts.forEach((thirdAppAccount) => {
      thirdAppAccountsMap[thirdAppAccount.thirdServiceType] = {
        account: thirdAppAccount.account,
        password: thirdAppAccount.password
      };
    });
    return thirdAppAccountsMap;
  },
  schoolsOfThird(state) {
    return state.schoolsOfThird;
  }
};

const mutations = {
  setUsingApplications(state, usingApplications) {
    state.usingApplications = usingApplications.map(
      usingApplication => usingApplication
    );
  },
  setThirdAppAccounts(state, thirdAppAccounts) {
    state.thirdAppAccounts = thirdAppAccounts.map(
      thirdApp => new ThirdApp(thirdApp)
    );
  },
  setThirdApp(state, thirdApp) {
    state.thirdApp = new ThirdApp({
      ...state.thirdApp,
      third_service_type: state.thirdApp.thirdServiceType,
      ...thirdApp
    });
  },
  setThirdAppsDetail(state, thirdAppsDetail) {
    state.thirdAppsDetail = thirdAppsDetail
      .map((thirdAppDetail) => (new ThirdApp(thirdAppDetail)));
  },
  setSchoolsOfThird(state, schools) {
    state.schoolsOfThird = [...schools];
  }
};

const actions = {
  async getUsingApplications({ commit }, studentUserId) {
    const { user_counseling_third_applications } = await thirdAppApi.getUsingApplication(studentUserId);
    commit("setUsingApplications", user_counseling_third_applications);
  },
  async updateUsingApplications({ commit }, {studentUserId, usingApplications}) {
    await thirdAppApi.updateUsingApplications(studentUserId, usingApplications);
  },
  async getThirdAccounts({ commit }, studentUserId) {
    const {
      counseling_third_accounts: thirdAccounts
    } = await thirdAppApi.getThirdAccounts(studentUserId);
    commit("setThirdAppAccounts", thirdAccounts);
  },
  async getThirdAccount({ commit }, payload) {
    const { studentUserId, thirdAppType } = payload;
    const {
      counseling_third_accounts: thirdAccounts
    } = await thirdAppApi.getThirdAccount(thirdAppType, studentUserId);
    commit("setThirdApp", thirdAccounts[0]);
  },
  async getThirdAccountsDetail({ commit }, payload) {
    const { studentUserId, thirdAppType } = payload;
    const {
      counseling_third_accounts: thirdAccounts
    } = await thirdAppApi.getThirdAccount(thirdAppType, studentUserId);
    commit("setThirdAppsDetail", thirdAccounts);
  },
  async storeThirdAccount({}, { studentUserId, thirdApp, success, error }) {
    try {
      await thirdAppApi.storeThirdAccount({
        student_user_id: studentUserId,
        account: thirdApp.account,
        password: thirdApp.password,
        third_service_type: thirdApp.thirdServiceType
      });
      success();
      Message.success(i18n.t("message.update_success"));
    } catch (e) {
      error();
    }
  },
  async loginThirdAccounts({}, payload) {
    const { thirdAccount, success, error } = payload;
    try {
      await thirdAppApi.loginThirdAccounts(thirdAccount);
      success();
    } catch (e) {
      error();
    }
  },
  async getThirdColleges({ dispatch, commit }, payload) {
    const {
      studentUserId,
      thirdAppType,
      before,
      running,
      after,
      error
    } = payload;
    if (before) {
      before();
    }
    const fetchThirdAppColleges = async () => {
      const { colleges } = await thirdAppApi.getThirdAppColleges(
        thirdAppType, studentUserId
      );
      return colleges;
    };

    try {
      const colleges = await fetchThirdAppColleges();
      if (after) {
        after();
      }
      commit("setSchoolsOfThird", colleges);
    } catch (e) {
      await dispatch("loginThirdAccounts", {
        thirdAccount: {
          third_service_type: thirdAppType,
          student_user_id: studentUserId
        },
        success: async () => {
          if (running) {
            running();
          }
          const colleges = await fetchThirdAppColleges();
          commit("setSchoolsOfThird", colleges);
          if (after) {
            after();
          }
        },
        error
      });
    }
  },
  async syncCollegesToThirdApp({ dispatch }, payload) {
    const {
      thirdServiceType,
      studentUserId,
      before,
      running,
      after,
      error
    } = payload;
    if (before) {
      before();
    }
    const thirdAccount = {
      third_service_type: thirdServiceType,
      student_user_id: studentUserId
    };
    try {
      await thirdAppApi.asyncColleges(thirdAccount);
      if (after) {
        after();
      }
      await dispatch("getThirdColleges", {
        thirdAppType: thirdServiceType,
        studentUserId: studentUserId
      });
      Message.success(i18n.t("message.update_success"));
    } catch (e) {
      await dispatch("loginThirdAccounts", {
        thirdAccount: {
          third_service_type: thirdServiceType,
          student_user_id: studentUserId
        },
        success: async () => {
          if (running) {
            running();
          }
          await thirdAppApi.asyncColleges(thirdAccount);
          Message.success(i18n.t("message.update_success"));
          await dispatch("getThirdColleges", {
            thirdAppType: thirdServiceType,
            studentUserId: studentUserId
          });
          if (after) {
            after();
          }
        },
        error
      });
    }
  },
  async syncCounselingProfileToThirdApp({ dispatch }, payload) {
    const {
      thirdServiceType,
      studentUserId,
      before,
      running,
      after,
      error
    } = payload;
    if (before) {
      before();
    }
    try {
      await counselingProfileApi.syncCounselingProfile(
        studentUserId,
        thirdServiceType
      );
      Message.success("申請表資料上傳成功！");
      if (after) {
        after();
      }
    } catch (e) {
      await dispatch("loginThirdAccounts", {
        thirdAccount: {
          third_service_type: thirdServiceType,
          student_user_id: studentUserId
        },
        success: async () => {
          if (running) {
            running();
          }
          await counselingProfileApi.syncCounselingProfile(
            studentUserId,
            thirdServiceType
          );
          Message.success("申請表資料上傳成功！");
          if (after) {
            after();
          }
        },
        error
      });
    }
  },
  async sendCounselorInvitationToThirdApp({ dispatch }, payload) {
    const { thirdServiceType, studentUserId, before, error } = payload;
    if (before) {
      before();
    }
    try {
      await thirdAppApi.sendCounselorInvitation(studentUserId, thirdServiceType);
    } catch (e) {
      await dispatch("loginThirdAccounts", {
        thirdAccount: {
          third_service_type: thirdServiceType,
          student_user_id: studentUserId
        },
        success: async () => {
          await thirdAppApi.sendCounselorInvitation(studentUserId, thirdServiceType);
        },
        error
      });
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
