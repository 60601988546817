import Vue from "vue";
import VueI18n from "vue-i18n";
import locale from "element-ui/lib/locale";
import elEn from "element-ui/lib/locale/lang/en";
import elCn from "element-ui/lib/locale/lang/zh-CN";
import elTw from "element-ui/lib/locale/lang/zh-TW";
import satEn from "@ivy-way/sat-material/dist/@ivy-way/locales/en.json";
import satTw from "@ivy-way/sat-material/dist/@ivy-way/locales/tw.json";
import satCn from "@ivy-way/sat-material/dist/@ivy-way/locales/cn.json";

Vue.use(VueI18n);

function loadLocaleMessages() {
  const locales = require.context(
    "./locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return {
    cn: { ...messages.cn, ...elCn, ...satCn },
    en: { ...messages.en, ...elEn, ...satEn },
    tw: { ...messages.tw, ...elTw, ...satTw }
  };
}

const i18n = new VueI18n({
  locale: process.env.VUE_APP_I18N_LOCALE || "en",
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || "en",
  messages: loadLocaleMessages()
});

locale.i18n((key, value) => i18n.t(key, value));

export default i18n;
