import ApiService from "@/common/api.service";

export default {
  createCustomPrivateLesson(data) {
    return ApiService.post("add-custom-lesson", { ...data, type: 2 });
  },
  updateCustomPrivateLesson(data) {
    return ApiService.post("update-custom-lesson", { ...data, type: 2 });
  },
  createGreetMeeting(greetMeeting) {
    return ApiService.post("greet-meeting", greetMeeting);
  },
  updateGreetMeeting(greetMeetingId, greetMeeting) {
    return ApiService.patch(`greet-meeting/${greetMeetingId}`, greetMeeting);
  },
  deleteGreetMeeting(greetMeetingId) {
    return ApiService.delete(`greet-meeting/${greetMeetingId}`);
  },
  setNeedToGreetMeeting(classTeacherInvitation, need_greet_meeting) {
    return ApiService.patch(
      `teacher-invitation/${classTeacherInvitation}/set-need-greet-meeting`,
      { need_greet_meeting }
    );
  },
  getCounselingOrders(sessionClassId){
    return ApiService.query(`counseling/${sessionClassId}`);
  },
  getGreetMeetings(data) {
    return ApiService.query("greet-meetings",data);
  },
  sendGreetMeetingMail(classId, mailTeachersId, mailToPeople, mailContent) {
    return ApiService.post("greet-meeting/send-mail", {
      session_class_id: classId,
      selected_mailing_teachers: mailTeachersId,
      mail_to_teachers: mailToPeople.mailToTeachers,
      mail_to_students: mailToPeople.mailToStudents,
      mail_to_parents: mailToPeople.mailToParents,
      mail_content: mailContent
    });
  },
  getCustomLesson(data) {
    return ApiService.query("get-custom-lesson", data);
  },
  fetchLessonRequestsByTeacher(data) {
    return ApiService.query("class-teacher-invitations/mine",data);
  },
  changeToCounseling(sessionClassId) {
    return ApiService.patch(
      `change-private-lesson-to-counseling/${sessionClassId}`
    );
  }
};
