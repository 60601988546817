import store from "../store";

// Must execute initialExchangeRate frist.
export default {
  methods: {
    async initialExchangeRate() {
      await store.dispatch("currency/fetchExchangeRate");
    },
    convertPriceByCurrency(price, fromCurrency, toCurrency) {
      if (price === 0 || !fromCurrency || !toCurrency) return 0;
      const exchangeRate = store.getters["currency/exchangeRate"];
      return this.handleDecimal(
        (price * exchangeRate[fromCurrency].TWD) / exchangeRate[toCurrency].TWD,
        toCurrency
      );
    },
    addThousandsComma(num) {
      let parts = String(num).split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
    addCurrencySymbol(price, currency) {
      console.log(price, currency);
      if (price !== null && currency !== null) {
        if (isNaN(Number(price))) return 0;
        const currencySymbol = {
          TWD: "$",
          USD: "$",
          CNY: "¥"
        };
        const formattedPrice = this.addThousandsComma(
          this.handleDecimal(Number(price), currency)
        );
        return `${currencySymbol[currency]}${formattedPrice} ${currency}`;
      } else {
        return 0;
      }
    },
    handleDecimal(price, currency) {
      if (currency !== "USD") return Math.round(price);
      if (!String(price).includes(".")) return price;
      return (Math.round(price * 100) / 100).toFixed(2);
    },
    calculationTotal(accumulator, currentValue) {
      return accumulator + currentValue;
    },
    isCurrencyMultiple(currencys) {
      const removeRepeat = new Set(currencys);
      return removeRepeat.size > 1;
    },
    getMainCurrency(currencys) {
      if (currencys.includes("TWD")) return "TWD";
      if (currencys.includes("USD")) return "USD";
      return "CNY";
    },
    // @priceWithCurrencys: [{ price: number, currency: string }]
    calculationPriceWithCurrency(priceWithCurrencys, defaultMainCurrency) {
      if (priceWithCurrencys.length === 0) return 0;
      //获取这份账单中最多的币值
      const mainCurrency =
        defaultMainCurrency ||
        this.getMainCurrency(
          priceWithCurrencys.map(
            priceWithCurrencys => priceWithCurrencys.currency
          )
        );
      const prices = priceWithCurrencys.map(({ price, currency }) =>
        Number(this.convertPriceByCurrency(price, currency, mainCurrency))
      );
      const total = prices.reduce(this.calculationTotal);
      return total;
    }
  }
};
