import applicationApi from "@/apis/application";

const state = {
  pageSize: 30,
  currentPage: 1,
  applicationsTotal: 0,
  applications: []
};

const getters = {
  applications(state) {
    return state.applications;
  },
  pageSize(state) {
    return state.pageSize;
  },
  currentPage(state) {
    return state.currentPage;
  },
  applicationsTotal(state) {
    return state.applicationsTotal;
  }
};

const mutations = {
  setApplications(state, applications) {
    state.applications = applications;
  },
  setApplicationsTotal(state, applicationsTotal) {
    state.applicationsTotal = applicationsTotal;
  },
  setCurrentPage(state, currentPage) {
    state.currentPage = currentPage;
  }
};

const actions = {
  async fetchApplications({ commit, state }, payload) {
    const { page } = payload;
    const {
      student_users: { data, total, current_page }
    } = await applicationApi.fetchCounselingProfiles({
      page_size: state.pageSize,
      page
    });
    commit("setApplications", data);
    commit("setApplicationsTotal", total);
    commit("setCurrentPage", current_page);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
