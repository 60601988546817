<template>
  <el-container>
    <div id="todo" v-if="isAdmin">
      <Todo></Todo>
    </div>
    <div id="feedback">
      <el-tooltip class="item" effect="dark" content="Feedback" placement="top">
        <span class="contact-help text-warning" @click="getFeedback">
          <i class="fas fa-bug"></i>
        </span>
      </el-tooltip>
      <el-dialog
        :title="$t('feedback.Feedback')"
        :visible.sync="showFeedback"
        :width="widthIsPhone ? '90%' : '50%'"
        center
      >
        <div>
          <el-form
            ref="form"
            :model="feedback"
            :label-position="widthIsPhone ? 'left' : 'right'"
          >
            <el-form-item :label="$t('feedback.Current Page')">
              <el-input v-model="feedback.url" disabled></el-input>
            </el-form-item>
            <el-form-item :label="$t('feedback.Type')" required>
              <el-radio-group v-model="feedback.type">
                <el-radio label="Bug" style="line-height:40px">{{
                  $t("feedback.Report a bug")
                }}</el-radio>
                <el-radio label="Advice" style="line-height:40px">{{
                  $t("feedback.Have a feedback or recommendation")
                }}</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item :label="$t('feedback.Screenshot')">
              <el-upload
                v-loading="loading"
                v-show="feedback.file === null"
                class="upload-demo"
                drag
                name="document"
                :action="postUrl"
                :headers="token"
                :show-file-list="false"
                :on-success="handleAvatarSuccess"
                :before-upload="beforeAvatarUpload"
              >
                <i class="el-icon-upload"></i>
                <div
                  class="el-upload__text"
                  v-html="
                    $t(
                      'feedback.Please drag an image here, or click here to upload an image'
                    )
                  "
                ></div>
              </el-upload>
              <el-alert
                v-show="feedback.file !== null"
                :closable="false"
                type="success"
              >
                <span style="margin-right:10px"
                  ><i class="el-icon-success"></i>
                  {{ $t("feedback.Uploaded successfully!") }}</span
                >
                <el-button
                  type="warning"
                  size="mini"
                  @click="feedback.file = null"
                  >{{ $t("feedback.Reselect") }}</el-button
                >
              </el-alert>
            </el-form-item>
            <el-form-item :label="$t('feedback.Details')">
              <el-input
                type="textarea"
                v-model="feedback.description"
                :rows="5"
                :placeholder="$t('feedback.Details')"
              ></el-input>
            </el-form-item>
            <el-form-item>
              <el-button @click="showFeedback = false">
                {{ $t("feedback.Cancel") }}
              </el-button>
              <el-button type="primary" @click="postFeedback">
                {{ $t("feedback.Submit") }}
              </el-button>
            </el-form-item>
          </el-form>
        </div>
      </el-dialog>
    </div>
    <el-header v-if="showHeader">
      <Header />
    </el-header>
    <el-main>
      <router-view :key="$route.fullPath" />
    </el-main>
    <Footer v-if="showFooter" />
    <el-backtop :bottom="80" :right="15"></el-backtop>
  </el-container>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import JwtService from "@/common/jwt.service";
import Feedback from "@/apis/feedback.js";
import Todo from "@/components/todo/Index";
import role from "@/mixins/role";

export default {
  metaInfo() {
    return {
      title: "Ivy-Way Academy"
    };
  },

  components: {
    Header,
    Footer,
    Todo
  },

  mixins: [role],

  props: [],
  data() {
    return {
      showFeedback: false,
      feedback: {
        type: "Bug",
        description: "",
        url: "",
        file: null,
        created_by: 0
      },
      showTodo: false,
      todo: null,
      token: { Authorization: `Bearer ${JwtService.getToken()}` },
      loading: false
    };
  },
  computed: {
    ...mapState("user", ["profile"]),
    isAdmin() {
      let isAdmin = this.isRoleAdmin();
      return isAdmin;
    },
    widthIsPhone() {
      return document.body.clientWidth <= 768;
    },
    showHeader() {
      if (this.$route.meta.showHeader === undefined) {
        return true;
      } else {
        return false;
      }
    },
    showFooter() {
      if (this.$route.meta.showFooter === undefined) {
        return true;
      } else {
        return false;
      }
    },
    postUrl() {
      return process.env.VUE_APP_BACKEND_URL + "issue/file";
    }
  },
  watch: {},
  mounted() {},
  methods: {
    getFeedback() {
      this.showFeedback = true;
      this.feedback = {
        type: "Bug",
        description: "",
        url: "",
        file: null,
        created_by: 0
      };
      this.feedback.url = document.title + " (" + window.location.href + ")";
      if (JwtService.hasToken()) {
        this.feedback.created_by = this.profile.id;
      } else {
        this.feedback.created_by = 0;
      }
    },
    getTodo() {
      this.showTodo = true;
      this.todo = {
        dateTime: {
          data: null,
          time: null,
          zone: null,
          all_day: false
        },
        reminder: {
          on_reminder: false,
          value: null,
          time: null
        },
        url: "",
        users: [],
        staffs: [],
        location: "",
        text: ""
      };
    },
    async postFeedback() {
      await Feedback.postFeedback(this.feedback);
      this.showFeedback = false;
      this.$notify({
        title: "成功",
        message: "謝謝您的反饋！Thanks♪(･ω･)ﾉ",
        type: "success"
      });
    },
    //文件上传成功
    handleAvatarSuccess(res, file) {
      if (res.document.url) {
        this.feedback.file = res.document.url;
        this.loading = false;
      }
    },
    //文件上传之前做检查
    beforeAvatarUpload(file) {
      this.feedback.file = null;
      this.loading = true;
      const isLt5M = file.size / 1024 / 1024 < 5;
      if (!isLt5M) {
        this.$message.error("文件大小不能超過 5MB!");
      }
      return isLt5M;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/style";
@import "~bootstrap/dist/css/bootstrap.css";
.contact-help {
  right: 15px;
  bottom: 140px;
  position: fixed;
  background-color: #ffffff;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: #42a16a;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 0 6px rgb(0 0 0 / 12%);
  cursor: pointer;
  z-index: 100;
}

.el-header {
  box-shadow: 0 5px 5px #aaa;
  background-color: white;
  position: fixed;
  width: 100%;
  z-index: 1001;
  padding: 0;
  margin: 0;
}

header.el-header {
  padding: 0;
  margin: 0;
}

main.el-main {
  min-height: calc(100vh - 60px);
  padding: 0;
  padding-top: 100px;
}
@media screen and (max-width: 992px) {
  main.el-main {
    padding-top: 60px;
  }
}
.wraper {
  height: 100vh;
  overflow-x: hidden;
}
</style>
<style scoped>
::v-deep .el-dialog__body {
  padding: 5px 40px;
}


::v-deep .el-alert .el-alert__description {
  margin-top: 0;
}
::v-deep .el-upload-dragger {
  width: 450px;
  height: 200px;
}

@media screen and (max-width: 736px) {
  ::v-deep .el-dialog--center .el-dialog__body {
    padding: 5px 20px;
  }
  ::v-deep .el-form--label-left .el-form-item__label {
    display: block;
    width: 100%;
    line-height: normal;
  }
  ::v-deep .el-upload-dragger {
    width: 100%;
    height: 150px;
  }
  ::v-deep .el-upload-dragger .el-icon-upload {
    margin: 20px 0 16px;
  }
  ::v-deep .el-upload {
    width: 100%;
  }
  ::v-deep .el-button {
    margin-top: 10px;
  }
  ::v-deep .el-form-item {
    margin-bottom: 12px;
  }
}
</style>
