import axios from "axios";
import router from "@/router";
import store from "@/store";
import i18n from "@/i18n";
import JwtService from "@/common/jwt.service";
import { Message, Loading } from "element-ui";

const interceptor = axios.create();

let loadingInstance;

interceptor.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    if (localStorage.id_token) {
      config.headers.Authorization = "Bearer " + localStorage.id_token;
    }

    config.headers["Accept-Language"] = i18n.locale;

    loadingInstance = Loading.service({
      lock: true,
      background: "transparent"
    });

    return config;
  },
  function(error) {
    // Do something with request error
    loadingInstance.close();
    return Promise.reject(error);
  }
);

interceptor.interceptors.response.use(
  function(response) {
    loadingInstance.close();

    return response.data;
  },
  function(error) {
    if (error.response.status === 400) {
      switch (error.response.data.message) {
        case "Not found user":
        case "Unauthorized":
          // do nothing.
          break;
        case "Blocked":
          router.push({ name: "InactivePage" });
          break;
        default:
          Message.error("Oop, something went wrong. Please contact us.");
      }
    }

    if (error.response.status === 401) {
      JwtService.destroyToken();

      store.dispatch("user/resetToken");

      router.push({ name: "Login" });
    }

    if (error.response.status === 403) {
      console.log(error);
      Message.error(error.response.data.message);
    }

    if (error.response.status === 404) {
      // router.push({
      //   name: "PageNotFound"
      // });
    }

    if (error.response.status === 422) {
      if (0 < Object.keys(error.response.data.errors).length) {
        const firstError = Object.keys(error.response.data.errors)[0];
        Message.error(error.response.data.errors[firstError][0]);
      } else {
        Message.error(error.response.data.message);
      }
    }

    loadingInstance.close();

    return Promise.reject(error);
  }
);

export default interceptor;
