import coursesApi from "@/apis/classesandcounseling";

const state = {
  courses: {}
};

const getters = {
  getCourses(state) {
    return state.courses;
  }
};

const mutations = {
  setCourses(state, data) {
    state.courses = data;
  }
};

const actions = {
  async getCourses({ commit }, { is_visible, show_index }) {
    const data = await coursesApi.getCourses(is_visible, show_index);
    commit("setCourses", data);
  },
  /*eslint-disable no-unused-vars */
  async getCoursesById({ commit }, id) {
    /*eslint-enable */
    await coursesApi.getCoursesById(id);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
