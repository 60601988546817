import ipGeo from "@/apis/ipGeo";

const state = {
  geo: {
    iso_code: "US",
    country: "United States",
    city: "Salt Lake City",
    timezone: "America/Denver"
  }
};

const getters = {
  getGeoAfterCall(state) {
    return state.geo;
  }
};

const mutations = {
  setGeo(state, data) {
    state.geo = data;
  }
};

const actions = {
  async getGeoLocation({ commit }, ip) {
    const data = await ipGeo.getGeoLocation(ip);
    commit("setGeo", data);
    return data;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
