let isMathjaxConfig = false;
const initMathjaxConfig = () => {
  if (!window.MathJax) {
    return;
  }
  window.MathJax.Hub.Config({
    showProcessingMessages: false,
    messageStyle: "none",
    jax: ["input/TeX", "output/HTML-CSS"],
    tex2jax: {
      inlineMath: [["$", "$"], ["\\(", "\\)"]],
      displayMath: [["$$", "$$"], ["\\[", "\\]"]],
      skipTags: ["script", "noscript", "style", "textarea", "pre"]
    },
    "HTML-CSS": {
      availableFonts: ["STIX", "TeX"],
      showMathMenu: false
    }
  });
  isMathjaxConfig = true;
};
const MathQueue = function(elements) {
  if (!window.MathJax) {
    return;
  }
  elements.forEach(element => {
    window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub, element]);
  });
};
export default {
  isMathjaxConfig,
  initMathjaxConfig,
  MathQueue
};
