import contactApi from "@/apis/contact";

const state = {};

const mutations = {};

const actions = {
  /*eslint-disable no-unused-vars */
  async contactUs({ commit }, payload) {
    /*eslint-enable */
    const { name, email, message, captcha, captchaKey: captcha_key, phone } = payload;
    const data = { name, email, message, captcha, captcha_key, phone };
    await contactApi.contactUs(data);
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations
};
