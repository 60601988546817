import ApiService from "@/common/api.service";
import role from "@/mixins/role";

export default {
  createLog(data) {
    if (role.methods.isRoleAdmin()) {
      return ApiService.post("admin/lesson-log", data);
    } else {
      return ApiService.post("lesson-log", data);
    }
  },
  createGroupLessonLog(data) {
    return ApiService.post("group-lesson-log", data);
  },
  updateLessonLog(id, data) {
    if (role.methods.isRoleAdmin()) {
      return ApiService.patch(`admin/lesson-log/${id}`, data);
    } else {
      return ApiService.patch(`lesson-log/${id}`, data);
    }
    
  },
  updateGroupLessonLog(id, data) {
    return ApiService.patch(`group-lesson-log/${id}`, data);
  },
  fetchLessonLog(id) {
    return ApiService.query(`lesson-log/${id}`);
  },
  fetchTeachingLog(id) {
    return ApiService.query(`teaching-log/${id}`);
  },
  fetchNextTeachingLog(query) {
    return ApiService.query("next-teaching-log", query);
  },
  fetchLessonLogByQuery(query) {
    return ApiService.query("teaching-logs", query);
  },
  deleteTeachingLog(id) {
    return ApiService.delete(`teaching-log/${id}`);
  },
  deleteLessonLog(id) {
    return ApiService.delete(`lesson-log/${id}`);
  },
  batchUpdateStudentAttendanceStatus(data) {
    return ApiService.patch("lesson-log/batch/attendance-status", data);
  },
  fetchStudentAttendances(query) {
    return ApiService.query("student-attendances", query);
  }
};
