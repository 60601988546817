import ApiService from "@/common/api.service";

export default {
  getTodo(data) {
    return ApiService.query("todos", data);
  },
  createTodo(data) {
    return ApiService.post("todos", data);
  },
  editTodo(id, data) {
    return ApiService.patch(`todos/${id}`, data);
  },
  deleteTodo(id) {
    return ApiService.delete(`todos/${id}`);
  },
  showDetailTodo(id) {
    return ApiService.query(`todos/${id}`);
  },
  getAdmins() {
    return ApiService.query(
      "users/search?&page=1&page_size=50&is_active=1&role=super-admin&order_by=name&order_direction=asc"
    );
  }
};
