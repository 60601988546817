export default class CollectSchool {
  constructor(props) {
    const { 
      admissions_result = null,
      application_status = null,
      application_submission_date = null,
      application_portal = null,
      application_type = null,
      financial_submission_date = null,
      has_ea1 = null,
      has_ea2 = null,
      has_ed1 = null,
      has_ed2 = null,
      has_rea = null,
      has_tr = null,
      id = null,
      is_applying = 0,
      is_enrolled = 0,
      login_field_password = null,
      login_field_username = null,
      school_id = null,
      session_class_id = null,
      student_user_id = null,
      type = "DEFAULT",
      school = {
        id: null,
        name: "{\"en\": \"\", \"cn\": \"\", \"tw\": \"\"}",
        overview: "{\"en\": \"\", \"cn\": \"\", \"tw\": \"\"}",
        schoolyard: "",
        slug: "",
        third_service_schools: []
      }
    } = props || {};

    this.admissions_result = admissions_result;
    this.application_status = application_status;
    this.application_submission_date = application_submission_date;
    this.application_portal = application_portal;
    this.application_type = application_type;
    this.has_ea1 = has_ea1;
    this.has_ea2 = has_ea2;
    this.has_ed1 = has_ed1;
    this.has_ed2 = has_ed2;
    this.has_rea = has_rea;
    this.has_tr = has_tr;
    this.financial_submission_date = financial_submission_date;
    this.id = id;
    this.is_applying = is_applying;
    this.is_enrolled = is_enrolled;
    this.login_field_password = login_field_password;
    this.login_field_username = login_field_username;
    this.school_id = school_id;
    this.session_class_id = session_class_id;
    this.student_user_id = student_user_id;
    this.type = type;
    this.school = school;
  }
}
