import ApiService from "@/common/api.service";

export default {
  fetchTeacherPaids(query) {
    return ApiService.query("payroll/paid", query);
  },
  fetchTeacherPayrolls() {
    return ApiService.query("payroll/unpaid");
  },
  fetchTeacherUnpaids() {
    return ApiService.query("payroll/users/unpaid");
  },
  fetchTeacherUnpaid(teacherId) {
    return ApiService.query(`payroll/unpaid/${teacherId}`);
  },
  fetchPayroll(payrollId) {
    return ApiService.query(`payroll/${payrollId}`);
  },
  createTeacherPayroll(teacherPayroll) {
    return ApiService.post("payroll", teacherPayroll);
  },
  updatePayroll(payrollId, payroll) {
    return ApiService.patch(`payroll/${payrollId}`, payroll);
  },
  markCheckoedPayroll(payrollId) {
    return ApiService.post(`payroll/${payrollId}/checked`);
  },
  sendSalaryMail(payrollId) {
    return ApiService.post(`payroll/${payrollId}/send`);
  },
  submitPayrollProblem(payrollId, problem) {
    return ApiService.post(`payroll/${payrollId}/problem`, problem);
  },
  createSalaryTransaction(payrollId, transaction) {
    return ApiService.post(`payroll/${payrollId}/transaction`, transaction);
  },
  markSalaryPaid(payrollId) {
    return ApiService.patch(`payroll/${payrollId}/paid`);
  },
  resetTeacherPayroll(payrollId, teacherPayroll) {
    return ApiService.post(`payroll/${payrollId}/reset`, teacherPayroll);
  },
  deleteTeacherPayroll(payrollId) {
    return ApiService.delete(`payroll/${payrollId}`);
  }
};
