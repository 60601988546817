import ApiService from "@/common/api.service";

export default {
  getCourses(is_visible, show_index) {
    return ApiService.get(`courses?show_index=${show_index}&is_visible=${is_visible}`);
  },
  getCoursesById(id) {
    return ApiService.get("courses/" + id);
  }
};
