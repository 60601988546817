/* eslint-disable no-empty-pattern */
import profileApi from "@/apis/profile";
import billApi from "@/apis/bill";
import usersApi from "@/apis/users";
import money from "@/mixins/money";

const state = {
  userRoleProfile: {
    basic_info: {},
    parent: {},
    student: {},
    instructor: {}
  },
  dataStudentAfterPatch: {},
  dataTeacherAfterPatch: {},
  userDataByAdmin: {
    basic_info: {},
    parent: {},
    student: {},
    instructor: {}
  },
  displayBalance: 0,
  parents: []
};

const getters = {
  getUserData(state) {
    return state.userRoleProfile;
  },
  getStudentDataAfterPatch(state) {
    return state.dataStudentAfterPatch;
  },
  getTeacherDataAfterPatch(state) {
    return state.dataTeacherAfterPatch;
  },
  getUserDataByAdmin(state) {
    return state.userDataByAdmin;
  },
  getDisplayBalance(state) {
    return state.displayBalance;
  },
  getParents() {
    return state.parents;
  }
};

const mutations = {
  setUserRoleProfile(state, data) {
    state.userRoleProfile = data;
  },
  setStudentDataAfterPatch(state, data) {
    state.dataStudentAfterPatch = data;
  },
  setTeacherDataAfterPatch(state, data) {
    state.dataTeacherAfterPatch = data;
  },
  setUserDataByAdmin(state, data) {
    state.userDataByAdmin = data;
  },
  setDisplayBalance(state, displayBalance) {
    state.displayBalance = displayBalance;
  },
  setParents(state, parents) {
    state.parents = parents;
  }
};

const actions = {
  async userRoleProfile({ commit }) {
    const data = await profileApi.getUserRoleProfile();
    commit("setUserRoleProfile", data);
  },
  async updateBasicProfile({ }, payload) {
    await profileApi.patchBasicProfile({
      ...payload,
      first_name: payload.first_name.trim(),
      last_name: payload.last_name.trim()
    });
  },
  async updateStudentProfile({ }, payload) {
    await profileApi.patchStudentProfile(payload);
  },
  async updateTeacherProfile({ }, payload) {
    await profileApi.patchTeacherProfile(payload);
  },
  /*eslint-disable no-unused-vars */
  async changePassword({ commit }, userId) {
    /*eslint-enable */
    return await profileApi.changePassword(userId);
  },
  // Get user data by Admin
  async getUserDataByAdmin({ commit }, userId) {
    const data = await profileApi.getUserData(userId);
    commit("setUserDataByAdmin", data);
  },
  // Get user data by Admin
  async getUserDataForAdminByUserName({ commit }, userName) {
    const data = await profileApi.getUserByName(userName);
    commit("setUserDataByAdmin", data);
  },
  // Admin edit basic_info
  // eslint-disable-next-line no-empty-pattern
  async updateBasicProfileByAdmin({ }, payload) {
    const { id, newProfile } = payload;
    await profileApi.patchBasicProfileByAdmin(id, {
      ...newProfile,
      first_name: newProfile.first_name.trim(),
      last_name: newProfile.last_name.trim()
    });
  },
  // Admin edit student
  // eslint-disable-next-line no-empty-pattern
  async updateStudentProfileByAdmin({ }, payload) {
    const { id, newProfile } = payload;
    await profileApi.patchStudentProfileByAdmin(id, newProfile);
  },
  // Admin edit instructor
  // eslint-disable-next-line no-empty-pattern
  async updateTeacherProfileByAdmin({ }, payload) {
    const { id, newProfile } = payload;
    await profileApi.patchTeacherProfileByAdmin(id, newProfile);
  },
  async calculateDisplayBalance({ commit }, payload) {
    const { children_users } = await billApi.getUnpaidChildrens(payload.parentId);
    const childrenUnpaidPromises = children_users.map(({ id }) => (
      billApi.getUnpaidListByStudentId(id)
    ));

    const childrenUnpaids = await Promise.all(childrenUnpaidPromises);
    const childrenUnpaidsTotal = childrenUnpaids
      .reduce((accumulator, { lesson_logs }) => (
        accumulator += lesson_logs.reduce((lessonLogAccumulator, lessonLog) => (
          lessonLogAccumulator += money.methods.convertPriceByCurrency(
            Number(lessonLog.fee) * lessonLog.teaching_log.full_hour,
            lessonLog.fee_currency,
            payload.balanceCurrency
          )
        ), 0)
      ), 0);
    commit("setDisplayBalance", payload.balance - childrenUnpaidsTotal);
  },
  async fetchParents({ commit }) {
    const isNameEmpty = (name) => Boolean(name.replaceAll(" ", ""));
    let { parents } = await usersApi.getParents();
    parents = parents.map(parent => {
      return {
        ...parent,
        name: isNameEmpty(parent.name) ? parent.name : parent.username,
        pivot: {
          parent_user_id: parent.id
        }
      };
    });
    commit("setParents", parents);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
