import shortUrlsApi from "@/apis/shortUrls";
import { Message } from "element-ui";
import i18n from "@/i18n.js";

const initState = {
  short_urls: [],
  short_url: {
    short_url_is_random_generate: true,
    full_url: "",
    short_url_key: "",
    default_short_url: ""
  }
};

const state = {
  ...initState
};

const getters = {
  shortUrls(state) {
    return state.short_urls;
  },
  shortUrl(state) {
    return state.short_url;
  }
};

const mutations = {
  resetShortUrl(state) {
    state.short_url = {
      ...initState.short_url
    };
  },
  setShortUrls(state, data) {
    state.short_urls = data;
  },
  setShortUrl(state, data) {
    state.short_url = {
      ...state.short_url,
      ...data
    };
  }
};

const actions = {
  async getShortUrls({ commit }) {
    const { short_urls } = await shortUrlsApi.getShortUrls();
    commit("setShortUrls", short_urls);
  },
  async generateShortUrl({ state, commit }) {
    let short_url_res = null;
    if (state.short_url.short_url_is_random_generate) {
      short_url_res = await shortUrlsApi.generateShortUrls({
        full_url: state.short_url.full_url
      });
    } else {
      short_url_res = await shortUrlsApi.generateShortUrls({
        full_url: state.short_url.full_url,
        short_url_key: state.short_url.short_url_key
      });
    }
    commit("setShortUrl", {
      default_short_url: short_url_res.short_url.default_short_url
    });
  },
  async revertShortUrl({ state, commit }, shortUrlKey) {
    const { short_url } = await shortUrlsApi.revertShortUrl(shortUrlKey);
    commit("setShortUrl", {
      full_url: short_url.full_url,
      default_short_url: short_url.default_short_url
    });
  },
  async deleteShortUrls({ _ }, shortUrlId) {
    await shortUrlsApi.deleteShortUrl(shortUrlId);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
