import store from "@/store";

export default {
  methods: {
    isRole(role) {
      return store.getters["user/roles"].includes(role);
    },
    isRoleAdmin() {
      return store.getters["user/roles"].includes("super-admin");
    },
    isRoleParent() {
      return store.getters["user/roles"].includes("parent");
    },
    isRoleStudent() {
      return store.getters["user/roles"].includes("student");
    },
    isRoleTeacher() {
      return store.getters["user/roles"].includes("teacher");
    },
    isRoleOnlyStudent() {
      return store.getters["user/roles"].length === 1 && this.isRoleStudent();
    },
    parentIsStudentSelf() {
      return store.getters["user/getProfile"].student_ids.includes(
        store.getters["user/getProfile"].id
      );
    }
  }
};
