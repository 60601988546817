import Vue from "vue";
import Vuex from "vuex";

import user from "./user.module";
import registerDetails from "./registerDetails.module";
import profile from "./profile.module";
import captcha from "./captcha.module";
import password from "./password.module";
import enrollment from "./enrollment.module";
import contact from "./contact.module";
import ipGeo from "./ipGeo.module";
import classesandcounseling from "./classesandcounseling.module";
import stories from "./stories.module";
import instructors from "./instructors.module";
import faq from "./faq.module";
import flashcards from "./flashcards.module";
import bill from "./bill.module";
import salary from "./salary.module";
import currency from "./currency.module";
import collectSchool from "./collectSchool.module.js";
import privateLesson from "./privateLesson.module.js";
import studentAttendance from "./studentAttendance.module.js";
import lessonLog from "./lessonLog.module.js";
import shortUrls from "./shortUrls.module.js";
import thirdApp from "./thirdApp.module.js";
import counselingProfile from "./counselingProfile.module.js";
import collegeAlias from "./collegeAlias.module.js";
import applications from "./applications.module.js";
import session from "./session.module.js";
import router from "./router.module.js";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    registerDetails,
    profile,
    captcha,
    password,
    enrollment,
    contact,
    ipGeo,
    classesandcounseling,
    stories,
    instructors,
    faq,
    flashcards,
    bill,
    salary,
    currency,
    privateLesson,
    studentAttendance,
    lessonLog,
    shortUrls,
    collectSchool,
    thirdApp,
    counselingProfile,
    collegeAlias,
    applications,
    session,
    router
  }
});
