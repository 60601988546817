/* eslint-disable no-empty-pattern */
import collectSchoolApi from "@/apis/collectSchool";
import CollectSchool from "@/class/CollectSchool";
import { Message } from "element-ui";
import i18n from "@/i18n.js";

const state = {
  collectedSchool: new CollectSchool()
};

const getters = {
  collectedSchool(state) {
    return state.collectedSchool;
  }
};

const mutations = {
  setCollectedSchool(state, collectedSchool) {
    state.collectedSchool = new CollectSchool({
      ...state.collectedSchool,
      ...collectedSchool
    });
  }
};

const actions = {
  async fetchCollectedSchool({ commit }, collectedSchoolId) {
    const { counseling_school } = await collectSchoolApi.fetchCollectedSchool(
      collectedSchoolId
    );
    commit("setCollectedSchool", counseling_school);
  },
  async updateCollectedSchool({ state, dispatch }, callback) {
    try {
      await collectSchoolApi.updateCollectedSchool(state.collectedSchool);
      dispatch("fetchCollectedSchool", state.collectedSchool.id);
      callback();
      Message.success(i18n.t("message.update_success"));
    } catch (e) {
      Message.error(i18n.t("message.something_went_wrong"));
    }
  },
  async removeRelatedSchool({}, relatedSchoolId) {
    try {
      await collectSchoolApi.removeSchoolFromCounseling(relatedSchoolId);
      Message.success(i18n.t("message.delete_success"));
    } catch (e) {
      Message.error(i18n.t("message.something_went_wrong"));
    }
  },
  async syncCollegesToIvyWay({}, payload) {
    const {
      thirdServiceType,
      studentUserId,
      storeSchoolIds,
      removeSchoolsIds
    } = payload;
    try {
      await collectSchoolApi.batchRemoveSchoolFromCounseling(
        studentUserId,
        removeSchoolsIds
      );

      await collectSchoolApi.batchStoreSchoolToCounseling({
        session_class_id: 0,
        student_user_id: studentUserId,
        school_ids: storeSchoolIds,
        application_portal: thirdServiceType
      });
      Message.success(i18n.t("message.update_success"));
    } catch (e) {
      Message.error(i18n.t("message.something_went_wrong"));
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
